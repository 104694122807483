import { Button, Col, Form, Input, Row } from 'antd';

import { PasswordInput, Tip } from 'components/system';
import { useModal } from 'hook/useModal';
import { emailRule, mobilePhoneRule, requireRule } from 'lib/validate';

interface ManuSuperUserFormProps {
  isVisible?: boolean;
  isEdit?: boolean;
  form: any;
}

const ManuSuperUserForm = ({ isVisible = true, isEdit, form }: ManuSuperUserFormProps) => {
  const { openChangePasswordModal } = useModal();

  const handleClickChangePassword = () => {
    openChangePasswordModal({});
  };

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <Form.Item label="아이디 (사업자등록번호)" name="username" required style={{ marginTop: 32 }}>
        <Input disabled />
      </Form.Item>
      {isEdit ? (
        <Form.Item label="비밀번호">
          <Button onClick={handleClickChangePassword}>비밀번호 변경</Button>
        </Form.Item>
      ) : (
        <Row gutter={8} align="bottom">
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item label="비밀번호" name="password" rules={[requireRule]}>
              <PasswordInput
                type="password"
                placeholder="비밀번호"
                autoComplete="new-password"
                onChange={() => form.validateFields(['passwordConfirm'])}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 12 }}>
            <Form.Item
              name="passwordConfirm"
              rules={[
                requireRule,
                ({ getFieldValue }) => ({
                  validator: (_, value) => {
                    const password = getFieldValue('password');
                    if (!value || !password) {
                      return Promise.resolve();
                    }
                    if (value !== getFieldValue('password')) {
                      return Promise.reject(new Error('비밀번호가 일치하지 않습니다.'));
                    }
                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <PasswordInput
                type="password"
                placeholder="비밀번호 확인"
                autoComplete="new-password"
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="이름" name="name" required rules={[requireRule]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="부서명" name="deptName" required rules={[requireRule]}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="직급" name="positionName" required rules={[requireRule]}>
            <Input />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item
            label="휴대폰번호"
            name="mobile"
            rules={[requireRule, mobilePhoneRule]}
            required
          >
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="이메일" name="email" rules={[requireRule, emailRule]} required>
        <Input placeholder="가입 승인이 전달되므로, 실제 사용 중인 메일주소를 입력" />
      </Form.Item>
    </div>
  );
};

export default ManuSuperUserForm;
