import { Button, Spin } from 'antd';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { useCountryCodeToNameMap } from 'service/country';
import {
  AnalysisItem,
  Coordinate,
  CountryRules,
  PostIngredient,
  PostIngredientListItem,
  RuleCode,
  RuleType,
} from 'types/brand/artworkScreening/artworkScreening';
import InquiryButton from './InquiryButton';
import path from 'lib/path';
import StickyButtonWrapper from '../StickyButtonWrapper';
import CountryList from './CountryList';
import {
  ArtworkStep4TabType,
  useArtworkMandatory,
  useFormulaArtworkScreeningResult,
} from 'service/brand/artworkScreening/artworkScreening';
import FormulaScreeningResult from './FormulaScreeningResult';
import FormulaScreeningMandatory from './mandatory/FormulaScreeningMandatory';

export interface AllError {
  ruleType: RuleType;
  analysisItems: AnalysisItem[];
  errors: {
    countryRules: CountryRules;
    ruleCode: RuleCode;
    postIngredient: PostIngredientListItem | PostIngredient;
  }[];
  isUpdate: boolean;
}

export interface UpdateInfo {
  [key: string]: {
    initialIngredientName: string;
    ingredientName: string;
    directName: string;
    color: keyof typeof palette;
    initialColor: keyof typeof palette;
    additionalIngredientName?: string;
    additionalIngredientNameSeparator?: 'SLASH' | 'PARENTHESIS' | null;
    formulaScreeningArtworkImageIngredientId: number | undefined;
    formulaScreeningArtworkIngredientId: number | undefined;
    allErrors: AllError[];
    order: number;
    initialOrder: number;
  };
}

export interface UpdatedIngredient extends UpdatedIngredientItem {
  relatedIngredients: UpdatedIngredientItem[];
}

export interface UpdatedIngredientItem {
  formulaScreeningAnalyzeItemId: number;
  color: keyof typeof palette;
  initialColor: keyof typeof palette;
  initialIngredientName: string;
  ingredientName: string;
  additionalIngredientName: string;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  directName: string;
  name: string;
  isNotMatch: boolean;
  isMiss: boolean;
  formulaScreeningArtworkImageIngredientId: number | undefined;
  coordinates: Coordinate[];
  allErrors: AllError[];
  order: number;
  initialOrder: number;
}

const ArtworkStep4 = ({
  setStep,
  formulaScreeningId,
  isAdmin = false,
  adminRefreshToken,
  adminToken,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formulaScreeningId: number;
  isAdmin?: boolean;
  adminRefreshToken?: string | null;
  adminToken?: string | null;
}) => {
  const navigate = useNavigate();
  const { openAlertModal } = useModal();
  const countryCodeToNameMap = useCountryCodeToNameMap();
  const {
    availableCountryCodes,
    selectedCountryCodes,
    setSelectedCountryCodes,
    artworkIngredients,
    setArtworkIngredients,
    updatedArtworkIngredients,
    isPerformanceMeasurement,
    tab,
    setTab,
    isShowResult,
    setIsShowResult,
    getArtworkScreeningResultLoading,
    artworkScreeningResult,
    handleReport,
  } = useFormulaArtworkScreeningResult({
    formulaScreeningId,
  });
  const { artworkScreeningMandatory, artworkMandatoryResult } = useArtworkMandatory({
    formulaScreeningId,
  });

  const tabs = ['formula', 'requiredMsg'] as ArtworkStep4TabType[];

  const handleSelectCountry = () => {
    if (isShowResult) {
      openAlertModal({
        width: 400,
        content:
          '선택한 국가를 변경하면 변경한 국가 구성에 따라\n진단 항목이 변경되고 초기화됩니다.',
        okText: '확인',
        onOk: () => {
          setIsShowResult(!isShowResult);
          setSelectedCountryCodes([]);
        },
      });
      return;
    }

    artworkScreeningMandatory({ countryCodes: selectedCountryCodes });
    setIsShowResult(!isShowResult);
  };

  return (
    <Spin spinning={getArtworkScreeningResultLoading}>
      <Container>
        <InnerContainer>
          <Title justify="space-between">
            <Flex gap={8}>
              <Typography.Text type="TITLE_1">스크리닝 분석 결과</Typography.Text>
            </Flex>
            {/* 문의 보내기 */}
            <InquiryButton formulaScreeningId={formulaScreeningId} isMandatoryScreening={true} />
          </Title>
          <DescriptionContainer
            gap={4}
            dir="column"
            style={{ maxHeight: tab === 'formula' ? '68px' : '112px' }}
          >
            <Typography.Text style={{ fontSize: 14 }}>
              · 다양한 국가의 규정을 바탕으로 제품 Artwork의 전성분을 스크리닝해 오류 성분을 확인할
              수 있습니다.
            </Typography.Text>
            {tab === 'formula' ? (
              <Typography.Text style={{ fontSize: 14, fontWeight: 500 }} inline>
                ·
                <span style={{ textDecoration: 'underline', marginLeft: 4 }}>
                  입력해주신 전성분표 기준으로 비교해 검수
                </span>
                되며, certicos에서 제공하는 가이드로 수정이 필요한 성분을 수정할 수 있습니다.
              </Typography.Text>
            ) : (
              <>
                <Typography.Text style={{ fontSize: 14 }}>
                  · 영문 외 한국어로 기재 시 유럽연합과 영국은 제품명, 제품 정체성, 사용방법, 사용
                  시 주의사항, 미국은 제품명, 제품 정체성, 사용방법, 사용 시 주의사항, Batch No.,
                  전성분 리스트를 한국어로 추가 기재하거나 삭제해야 합니다.
                </Typography.Text>
                <Typography.Text style={{ fontSize: 14, fontWeight: 500 }} inline>
                  ·
                  <span style={{ textDecoration: 'underline', marginLeft: 4 }}>
                    심볼마크와 QR코드 기재 여부는 지원되지 않으니 참고해 주세요.
                  </span>
                </Typography.Text>
              </>
            )}
          </DescriptionContainer>
          <TabsContainer>
            {tabs.map((key) => {
              return (
                <Tab key={key} selected={key === tab} onClick={() => setTab(key)}>
                  <Typography.Text style={{ fontWeight: key === tab ? 500 : 400 }}>
                    {key === 'formula' ? '전성분 표기 오류' : '필수 기재 사항'}
                  </Typography.Text>
                </Tab>
              );
            })}
          </TabsContainer>
          {/* 국가 목록 */}
          <CountryList
            isMandatoryScreening={true}
            isPerformanceMeasurement={isPerformanceMeasurement}
            availableCountryCodes={availableCountryCodes}
            countryCodeToNameMap={countryCodeToNameMap}
            artworkIngredients={artworkIngredients}
            selectedCountryCodes={selectedCountryCodes}
            setSelectedCountryCodes={setSelectedCountryCodes}
            isAdmin={isAdmin}
            isShowResult={isShowResult}
          />
          <Flex justify="center" gutter={{ top: 16, bottom: 32 }}>
            <CountrySelectBtn
              onClick={() => {
                handleSelectCountry();
              }}
              icon={
                <Icon
                  name={isShowResult ? 'refresh' : 'check'}
                  size={18}
                  color={
                    selectedCountryCodes.length > 0
                      ? isShowResult
                        ? 'PRIMARY50'
                        : 'ETC_WHITE'
                      : 'SLATE_GRAY60'
                  }
                />
              }
              type="primary"
              htmlType="button"
              disabled={selectedCountryCodes.length === 0}
              style={{
                background:
                  selectedCountryCodes.length > 0
                    ? isShowResult
                      ? palette.ETC_WHITE
                      : palette.PRIMARY50
                    : palette.SLATE_GRAY30,
                color:
                  selectedCountryCodes.length > 0
                    ? isShowResult
                      ? palette.GRAY90
                      : palette.ETC_WHITE
                    : palette.SLATE_GRAY60,
                borderColor:
                  selectedCountryCodes.length > 0 ? palette.PRIMARY50 : palette.SLATE_GRAY30,
              }}
            >
              <Typography.Text>
                {isShowResult && selectedCountryCodes.length > 0 ? '다시 선택' : '선택 완료'}
              </Typography.Text>
            </CountrySelectBtn>
          </Flex>
          {isShowResult &&
            (tab === 'formula' ? (
              <FormulaScreeningResult
                artworkIngredients={artworkIngredients}
                selectedCountryCodes={selectedCountryCodes}
                artworkScreeningResult={artworkScreeningResult}
                setArtworkIngredients={setArtworkIngredients}
                updatedArtworkIngredients={updatedArtworkIngredients}
                handleReport={handleReport}
                formulaScreeningId={formulaScreeningId}
                isAdmin={isAdmin}
              />
            ) : (
              <FormulaScreeningMandatory
                setStep={setStep}
                artworkMandatoryResult={artworkMandatoryResult}
                selectedCountryCodes={selectedCountryCodes}
              />
            ))}
        </InnerContainer>
        <StickyButtonWrapper>
          <Flex
            align="center"
            justify={isAdmin && isPerformanceMeasurement ? 'space-between' : 'end'}
            style={{ width: '100%' }}
          >
            <Flex align="center" gap={16}>
              {!isPerformanceMeasurement && (
                <Button style={{ width: 200, height: 56, fontSize: 18 }} onClick={() => setStep(3)}>
                  이전
                </Button>
              )}
              <RestartButton
                justify="center"
                onClick={() => {
                  openAlertModal({
                    content: '처음부터 다시 하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
                    onOk: () => {
                      navigate(
                        `${
                          path.artworkScreening
                        }${`?source=admin&adminToken=${adminToken}&adminRefreshToken=${adminRefreshToken}&isMandatoryScreening=true`}`,
                        { replace: true },
                      );
                      window.location.reload();
                    },
                  });
                }}
              >
                처음부터 다시 하기
              </RestartButton>
            </Flex>
          </Flex>
        </StickyButtonWrapper>
      </Container>
    </Spin>
  );
};

const Container = styled.div`
  margin-top: 58px;

  ${Flex} {
    align-items: center;
  }
`;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;

const Title = styled(Flex)`
  padding-bottom: 8px;
  border-bottom: 2px solid ${palette.PRIMARY50};
  margin-bottom: 16px;
`;

const RestartButton = styled(Flex)`
  width: 200px;
  height: 56px;
  font-size: 18px;
  color: ${palette.GRAY80};
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  cursor: pointer;
`;

const DescriptionContainer = styled(Flex)`
  align-items: start !important;
  margin-top: 16px;
  margin-bottom: 32px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const TabsContainer = styled(Flex)`
  background-color: ${palette.SLATE_GRAY20};
  border-radius: 4px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  width: 496px;
  margin-bottom: 24px;
`;

const Tab = styled(Flex)<{ selected: boolean }>`
  cursor: pointer;
  width: calc(50%);
  justify-content: center;
  border-radius: 4px;
  padding: 16px 12px;
  font-size: 18px;
  font-weight: ${({ selected }) => (selected ? 400 : 500)};
  color: ${({ selected }) => (selected ? palette.GRAY90 : palette.GRAY60)};
  background-color: ${({ selected }) => (selected ? palette.ETC_WHITE : 'transparent')};
`;

const CountrySelectBtn = styled(Button)`
  width: 156px;
  height: 44px;
  padding: 12px 16px;
  color: white;
`;

export default ArtworkStep4;
