import { Button } from 'antd';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Table from 'components/Table';
import { Typography } from 'components/system';
import { StatusIcon } from 'components/system/general/icon';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { FormulaArtworkScreeningItem } from 'types/brand/artworkScreening/artworkScreening';
import StickyButtonWrapper from '../StickyButtonWrapper';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import path from 'lib/path';

const ArtworkStep2 = ({
  formulaScreeningResult,
  setStep,
  isAdmin = false,
  isPerformanceMeasurement = false,
  adminToken,
  adminRefreshToken,
}: {
  formulaScreeningResult?: FormulaArtworkScreeningItem | null;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  isAdmin?: boolean;
  isPerformanceMeasurement?: boolean;
  adminToken?: string | null;
  adminRefreshToken?: string | null;
}) => {
  const { openAlertModal } = useModal();
  const navigate = useNavigate();
  const showWtTooltip = formulaScreeningResult && !formulaScreeningResult.isWtMax;
  const list = formulaScreeningResult?.list || [];
  const allergenList = formulaScreeningResult?.allergenList || [];
  const isValid =
    list.every(({ isNameValid, isAllergen }) => isNameValid && !isAllergen) && !showWtTooltip;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <InnerContainer>
        <Typography.Text
          type="TITLE_1"
          gutter={{ top: 56 }}
          style={{
            paddingBottom: 8,
            borderBottom: `2px solid ${palette.PRIMARY50}`,
            width: '100%',
          }}
        >
          전성분표 분석
        </Typography.Text>
        <DescriptionContainer gap={4} dir="column">
          <Typography.Text type="BODY_2">
            · certicos 성분사전과 비교 분석한 내용이 표시됩니다.
          </Typography.Text>
          <Typography.Text type="BODY_2">
            · 성분사전에 등재된 원료들이 화장품에 반드시 사용 가능한 것은 아니며, 국가별 성분의 사용
            여부는 '성분 스크리닝' 페이지에서 확인하실 수 있습니다.
          </Typography.Text>
        </DescriptionContainer>
        <ResultContainer>
          <Flex gap={4} align="center">
            <StatusIcon status={isValid ? 'success' : 'warning'} />
            <Typography.Text color="GRAY90" type="BODY_2">
              {isValid ? '정상적으로 입력되었습니다.' : '표시된 내용을 확인해 주세요.'}
            </Typography.Text>
          </Flex>
          <Table style={{ marginTop: 8 }} bordered tableStyle={{ tableLayout: 'fixed' }}>
            <Table.Thead>
              <Table.Tr>
                <Table.Th style={{ textAlign: 'center', width: 40 }}>No.</Table.Th>
                <Table.Th style={{ textAlign: 'center', width: 760 }}>성분명</Table.Th>
                <Table.Th
                  style={{
                    textAlign: 'center',
                    width: 240,
                    position: 'relative',
                    background: showWtTooltip ? palette.PRIMARY30 : palette.PRIMARY20,
                  }}
                >
                  WT %
                  {showWtTooltip && (
                    <Tooltip>
                      <Flex gap={4}>
                        <Typography.Text type="SMALL">
                          <Typography.Text type="SMALL" color="YELLOW_40" inline>
                            총 합이 100%
                          </Typography.Text>
                          {`가\n되도록 입력해 주세요.`}
                        </Typography.Text>
                      </Flex>
                      <Icon
                        name="tooltipArrow2"
                        style={{
                          position: 'absolute',
                          top: `calc(100% - 22px)`,
                          left: '50%',
                          transform: 'translate(-50%, 0)',
                        }}
                        size={48}
                      />
                    </Tooltip>
                  )}
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {list.map(
                (
                  { ingredientId, ingredientName, ingredientWt, isNameValid, isAllergen },
                  index,
                ) => {
                  const additionalInfo = !isNameValid
                    ? '성분명 확인 필요'
                    : isAllergen
                    ? '알레르기 유발 성분: 제품의 전성분 구성 성분인지 확인 필요'
                    : null;

                  return (
                    <Table.Tr key={`${ingredientId}-${index}`} className="tb_row">
                      <Table.Td width={36} style={{ textAlign: 'center' }}>
                        {index + 1}
                      </Table.Td>
                      <Table.Td width={758} style={{ textAlign: 'left' }}>
                        <Typography.Text type="SMALL" color="GRAY70">
                          {ingredientName || ''}
                        </Typography.Text>
                        {additionalInfo && (
                          <Typography.Text type="SMALL" color="YELLOW_70" gutter={{ top: 4 }}>
                            {additionalInfo}
                          </Typography.Text>
                        )}
                      </Table.Td>
                      <Table.Td width={120} className="wt" style={{ textAlign: 'left' }}>
                        <Typography.Text
                          type="SMALL"
                          color={'GRAY70'}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {ingredientWt || ''}
                        </Typography.Text>
                      </Table.Td>
                    </Table.Tr>
                  );
                },
              )}
            </Table.Tbody>
          </Table>
        </ResultContainer>
        {allergenList.length > 0 && (
          <ResultContainer>
            <Typography.Text type="TITLE_2">알레르기 유발성분 확인</Typography.Text>
            <Table style={{ marginTop: 16 }} bordered tableStyle={{ tableLayout: 'fixed' }}>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th style={{ textAlign: 'center', width: 40 }}>No.</Table.Th>
                  <Table.Th style={{ textAlign: 'center', width: 760 }}>성분명</Table.Th>
                  <Table.Th style={{ textAlign: 'center', width: 240 }}>
                    완제품에서의 함량 비율
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {allergenList.map(
                  ({ ingredientAllergenId, ingredientInciName, allergenProductTotalWt }, index) => {
                    const [int, decimalPart = ''] = allergenProductTotalWt.split('.');
                    const decimal = decimalPart.slice(0, 25);
                    const wt = decimal ? `${int}.${decimal}` : int;
                    const decimalNum = Number(`${int}.${decimal.slice(0, 3)}`);
                    const additionalInfo =
                      decimalNum >= 0.01
                        ? 'Artwork 전성분에 기입 필요'
                        : decimalNum >= 0.001
                        ? 'Leave on 제품일때 Artwork 전성분에 기입 필요'
                        : null;

                    return (
                      <Table.Tr key={ingredientAllergenId} className="tb_row">
                        <Table.Td width={40} style={{ textAlign: 'center' }}>
                          {index + 1}
                        </Table.Td>
                        <Table.Td width={760} style={{ textAlign: 'left' }}>
                          <Typography.Text type="SMALL" color="GRAY70">
                            {ingredientInciName || ''}
                          </Typography.Text>
                          {additionalInfo && (
                            <Typography.Text type="SMALL" color="MESSAGE_CHECK" gutter={{ top: 4 }}>
                              {additionalInfo}
                            </Typography.Text>
                          )}
                        </Table.Td>
                        <Table.Td width={240} className="wt" style={{ textAlign: 'left' }}>
                          <Typography.Text
                            type="SMALL"
                            color="GRAY70"
                            style={{ wordBreak: 'break-all' }}
                          >
                            {wt || ''}
                          </Typography.Text>
                        </Table.Td>
                      </Table.Tr>
                    );
                  },
                )}
              </Table.Tbody>
            </Table>
          </ResultContainer>
        )}
      </InnerContainer>
      <StickyButtonWrapper>
        <Flex
          align="center"
          justify={isAdmin && isPerformanceMeasurement ? 'space-between' : 'end'}
          style={{ width: '100%' }}
        >
          {isAdmin && isPerformanceMeasurement && (
            <RestartButton
              align="center"
              justify="center"
              onClick={() => {
                openAlertModal({
                  content: '처음부터 다시 하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
                  onOk: () => {
                    navigate(
                      `${path.artworkScreening}${
                        adminToken
                          ? `?source=admin&adminToken=${adminToken}&adminRefreshToken=${adminRefreshToken}`
                          : ''
                      }`,
                      { replace: true },
                    );
                    window.location.reload();
                  },
                });
              }}
            >
              처음부터 다시 하기
            </RestartButton>
          )}
          <Flex align="center" gap={16}>
            <StyledButton htmlType="button" type="default" onClick={() => setStep(1)}>
              이전
            </StyledButton>
            <StyledButton htmlType="button" type="primary" onClick={() => setStep(3)}>
              다음
            </StyledButton>
          </Flex>
        </Flex>
      </StickyButtonWrapper>
    </Container>
  );
};

const MessageTip = styled(Flex)`
  background-color: white;
  border: 1px solid ${palette.PRIMARY50};
  border-radius: 4px;
  visibility: hidden;
  position: absolute;
  align-items: center;
  left: 56px;
  bottom: -14px;
  left: 16px;
  z-index: 10;
  font-size: 12px;
  color: ${palette.GRAY90};
  height: 32px;
  justify-content: center;
  box-shadow: 0px 2px 22px 0px rgba(162, 162, 162, 0.23);
`;

const Container = styled.div``;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;

  .ant-table {
    font-size: 12px;
    color: ${palette.GRAY70};
    border-color: ${palette.GRAY30};
  }

  .ant-table-wrapper,
  .ant-table-wrapper .ant-table {
    overflow: visible;
  }

  .ant-table-container {
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
  }

  .ant-table-thead > tr > th {
    line-height: 16px;
    padding: 9px 12px;
    height: 35px;
    border-bottom: 1px solid ${palette.GRAY30};
    &:not(:last-of-type) {
      border-right: none !important;
    }
  }
  .ant-table-tbody > tr > td {
    border-bottom: 1px solid ${palette.GRAY30};
    padding: 7px 16px;
    height: 51px;
    position: relative;
    &:not(:last-of-type) {
      border-right: none !important;
    }
  }
  .tb_row {
    transition: background-color 150ms ease;
  }
  .tb_row:hover {
    background-color: ${palette.GRAY10};
    ${MessageTip} {
      visibility: visible;
    }
  }
`;

const ResultContainer = styled.div`
  position: relative;
  margin-top: 24px;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  color: ${palette.SLATE_GRAY70};
`;

const Tooltip = styled.div`
  position: absolute;
  top: -14px;
  right: 57px;
  width: max-content;
  transform: translate(0, -100%);
  background-color: ${palette.SLATE_GRAY70};
  color: #fff;
  padding: 12px;
  border-radius: 8px;
  white-space: pre-wrap;
`;

const StyledButton = styled(Button)`
  width: 200px;
  height: 56px;
  font-size: 18px;
`;

const RestartButton = styled(Flex)`
  width: 200px;
  height: 56px;
  font-size: 18px;
  color: ${palette.GRAY80};
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  cursor: pointer;
`;

export default ArtworkStep2;
