import styled from 'styled-components';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { TextAreaRef } from 'antd/es/input/TextArea';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import palette from 'lib/styles/palette';
import { usePerformanceMeasurement } from 'service/brand/artworkScreening/artworkScreening';
import StickyButtonWrapper from '../StickyButtonWrapper';
import path from 'lib/path';
import { useModal } from 'hook/useModal';

const ArtworkStep5 = ({
  setStep,
  formulaScreeningId,
  adminToken,
  selectedPerformanceCountryCodes,
  measurementType,
}: {
  setStep: React.Dispatch<React.SetStateAction<number>>;
  formulaScreeningId?: number;
  adminToken?: string | null;
  selectedPerformanceCountryCodes: string[];
  measurementType?: string | null;
}) => {
  const navigate = useNavigate();
  const textareaRef = useRef<TextAreaRef>();
  const [answerText, setAnswerText] = useState('');
  const { endPerformanceMeasurement } = usePerformanceMeasurement();
  const { openAlertModal } = useModal();

  useEffect(() => {
    if (textareaRef) {
      textareaRef.current?.focus();
    }
  }, []);

  const handleCompleteMeasurement = () => {
    if (!formulaScreeningId || !measurementType) return;
    endPerformanceMeasurement(
      {
        formulaScreeningId,
        measurementMethod: measurementType,
        countryCodes: selectedPerformanceCountryCodes,
        finalIngredientList: answerText,
      },
      {
        onSuccess: (res) => {
          if (res) {
            navigate(
              `${path.artworkScreening}${
                adminToken
                  ? `?source=admin&isPerformanceMeasurement=true&measurementType=${measurementType}&adminToken=${adminToken}`
                  : ''
              }`,
              { replace: true },
            );
            window.location.reload();
          }
        },
      },
    );
  };

  return (
    <Container>
      <InnerContainer>
        <Title justify="space-between">
          <Flex gap={8}>
            <Typography.Text type="TITLE_1">최종 전성분 정보</Typography.Text>
          </Flex>
        </Title>
        <DescriptionContainer align="start" gap={4} dir="column" style={{ height: 68 }}>
          <Typography.Text style={{ fontSize: 14 }}>
            ·{' '}
            {measurementType === 'A'
              ? '엑셀 파일이나 PDF 파일에 있는 전성분 리스트를 복사하여 아래 칸에 붙여넣기 해주세요.'
              : '복사한 전성분 적용 리스트를 아래 칸에 붙여넣기 해주세요.'}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>
            · [완료] 버튼을 눌러 완료 처리를 해주세요.
          </Typography.Text>
        </DescriptionContainer>
        <Flex dir="column">
          <AnswerTitleDiv style={{ fontWeight: 500 }}>최종 전성분 리스트</AnswerTitleDiv>
          <StyleTextarea
            value={answerText}
            onPaste={(e: React.ClipboardEvent<HTMLTextAreaElement>) => {
              e.preventDefault();
              const pastedText = e.clipboardData.getData('text');

              const formattedText = pastedText
                .trim()
                .split('\n')
                .map((item) => item.trim())
                .filter((item) => item)
                .join(', ');

              const currentText = e.currentTarget.value;
              const newText = currentText ? `${currentText}, ${formattedText}` : formattedText;

              e.currentTarget.value = newText;
              setAnswerText(newText);
            }}
            onChange={(e) => {
              const text = e.target.value;
              setAnswerText(text);
            }}
            ref={(el) => {
              if (el) {
                textareaRef.current = el;
              }
            }}
            placeholder="복사한 전성분을 붙여넣기 해주세요."
          />
        </Flex>
      </InnerContainer>
      <StickyButtonWrapper>
        <Flex align="center" justify={'space-between'} style={{ width: '100%' }}>
          <RestartButton
            align="center"
            justify="center"
            onClick={() => {
              openAlertModal({
                content:
                  measurementType === 'A'
                    ? '리스트로 돌아가시겠습니까?\n입력하신 내용은 저장되지 않습니다.'
                    : '처음부터 다시 하시겠습니까?\n입력하신 내용은 저장되지 않습니다.',
                onOk: () => {
                  navigate(
                    `${path.artworkScreening}${
                      adminToken
                        ? `?source=admin&isPerformanceMeasurement=true&measurementType=${measurementType}&adminToken=${adminToken}`
                        : ''
                    }`,
                    { replace: true },
                  );
                  window.location.reload();
                },
              });
            }}
          >
            {measurementType === 'A' ? '리스트로 돌아가기' : ' 처음부터 다시 하기'}
          </RestartButton>
          <Flex align="center" gap={16}>
            {measurementType !== 'A' && (
              <Button style={{ width: 200, height: 56, fontSize: 18 }} onClick={() => setStep(4)}>
                이전
              </Button>
            )}
            <CompleteButton
              type="primary"
              disabled={answerText === ''}
              htmlType="button"
              onClick={() => {
                handleCompleteMeasurement();
              }}
            >
              완료
            </CompleteButton>
          </Flex>
        </Flex>
      </StickyButtonWrapper>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 58px;

  ${Flex} {
    align-items: center;
  }
`;

const InnerContainer = styled.div`
  min-height: calc(100vh - 240px);
  max-width: 1040px;
  margin: 0 auto;
  padding-bottom: 80px;
`;
const Title = styled(Flex)`
  padding-bottom: 8px;
  border-bottom: 2px solid ${palette.PRIMARY50};
  margin-bottom: 16px;
`;

const CompleteButton = styled(Button)`
  width: 200px;
  height: 56px;
  font-size: 18px;
  cursor: pointer;
`;
const RestartButton = styled(Flex)`
  width: 200px;
  height: 56px;
  font-size: 18px;
  color: ${palette.GRAY80};
  border: 1px solid ${palette.GRAY40};
  border-radius: 4px;
  cursor: pointer;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  height: 72px;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
  align-items: start !important;
`;
const AnswerTitleDiv = styled.div`
  background-color: ${palette.SLATE_GRAY10};
  color: ${palette.SLATE_GRAY70};
  border: 1px solid ${palette.GRAY30};
  padding: 17px 24px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  margin-top: 24px;
`;

const StyleTextarea = styled.textarea`
  border-top: none;
  border-left: 1px solid ${palette.GRAY30};
  border-right: 1px solid ${palette.GRAY30};
  border-bottom: 1px solid ${palette.GRAY30};
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  outline: none;
  font-size: 14px;
  padding: 24px;
  color: ${palette.SLATE_GRAY70};
  resize: none;
  height: 504px;
  width: 100%;
`;

export default ArtworkStep5;
