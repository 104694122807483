import { Link } from 'react-router-dom';
import { Button, Col, Form, Row } from 'antd';
import styled from 'styled-components';

import Typography from 'components/system/general/Typography';
import CGMPModal from 'components/modal/product/CGMPModal';
import FooterBox from 'components/FooterBox';
import Icon from 'components/ui/Icon/Icon';
import { Tip } from 'components/system';
import { scrollToFirstErrorOption } from 'lib/consts';
import path from 'lib/path';
import ManuCompanyForm from './ManuCompanyForm';
import ManuSuperUserForm from './ManuSuperUserForm';
import useManuRegisterForm from './hooks/useManuRegisterForm';

interface ManuRegisterCreateFormProps {
  currentTab: 'company' | 'user';
  handleChangeTab: (tab: 'company' | 'user') => void;
}

export interface CGMP {
  isCGMP: boolean;
  ISOExpiredDate: string;
  file: File | null;
  uploadedFile: {
    url: string;
    name: string;
  } | null;
}

const ManuRegisterCreateForm = ({ currentTab, handleChangeTab }: ManuRegisterCreateFormProps) => {
  const {
    factoryInfos,
    form,
    previewedCGMP,
    forceUpdate,
    searchValue,
    submitLoading,
    handleSelectSearch,
    handlePreviewedCGMP,
    handleCloseCGMPModal,
    handleCGMPModal,
    handleClickNext,
    handleClickPrev,
    handleCreateManu,
  } = useManuRegisterForm({ handleChangeTab });

  return (
    <Container>
      <CGMPModal cGMP={previewedCGMP} onOk={handleCGMPModal} onClose={handleCloseCGMPModal} />
      <Form
        form={form}
        colon={false}
        layout="vertical"
        onValuesChange={(changedValues) => {
          const shouldUpdate = factoryInfos.some(
            (factoryInfo) => factoryInfo.ko in changedValues || factoryInfo.en in changedValues,
          );

          if (shouldUpdate) {
            forceUpdate();
          }
        }}
        scrollToFirstError={scrollToFirstErrorOption}
        onFinish={handleCreateManu}
      >
        {currentTab === 'company' && (
          <Typography.Text type="TITLE_1" gutter={{ bottom: 16 }}>
            1. 회사 정보
          </Typography.Text>
        )}
        <ManuCompanyForm
          isVisible={currentTab === 'company'}
          form={form}
          factoryInfos={factoryInfos}
          searchValue={searchValue}
          handleSelectSearch={handleSelectSearch}
          handlePreviewedCGMP={handlePreviewedCGMP}
        />
        {currentTab === 'user' && (
          <>
            <Row align="middle" gutter={8}>
              <Col>
                <Typography.Text type="TITLE_1">
                  <Icon
                    name="back"
                    size={16}
                    style={{ display: 'inline-block', marginRight: 16, cursor: 'pointer' }}
                    onClick={handleClickPrev}
                  />
                  2. 슈퍼유저 정보
                </Typography.Text>
              </Col>
              <Col>
                <Tip trigger="click">
                  <Typography.Text type="SMALL">
                    슈퍼유저 계정은 회사 당 1개의 계정만 소유 가능 합니다.
                  </Typography.Text>
                  <Typography.Text type="SMALL">
                    슈퍼유저는 모든 영역에서 최고 권한을 가진 계정입니다.
                  </Typography.Text>
                  <Typography.Text type="SMALL">
                    슈퍼유저는 하위 담당자를 추가하거나 삭제할 수 있습니다.
                  </Typography.Text>
                </Tip>
              </Col>
            </Row>
            <ManuSuperUserForm isVisible={currentTab === 'user'} form={form} />
          </>
        )}
        <FooterBox>
          {currentTab === 'company' && (
            <>
              <Button>
                <Link to={path.login}>취소</Link>
              </Button>
              <Button type="primary" onClick={handleClickNext}>
                다음
              </Button>
            </>
          )}
          {currentTab === 'user' && (
            <>
              <Button onClick={handleClickPrev}>뒤로</Button>
              <Button htmlType="submit" type="primary" loading={submitLoading}>
                회원가입
              </Button>
            </>
          )}
        </FooterBox>
      </Form>
    </Container>
  );
};

export default ManuRegisterCreateForm;

const Container = styled.div`
  max-width: 688px;
  margin: 0 auto;
  padding: 40px 0;
`;
