import { ChangeEventHandler, DragEventHandler, useRef, useState } from 'react';
import { Button, Form, FormInstance, Input, message } from 'antd';
import { FormListFieldData } from 'antd/lib/form/FormList';
import styled from 'styled-components';
import * as XLSX from 'xlsx';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import { generateFormRules } from 'lib/validate';
import { StepModalContentItem } from 'components/modal/StepModal';
import { getOS, multiplyDecimalStrings } from 'lib/common';
import FormulaIngredientSelect from '../../formulaScreening/FormulaIngredientSelect';
import { downloadFile } from 'lib/file';
import AllergenInputModalContent from './AllergenInputModalContent';
import LoadingIndicator from 'components/ui/LoadingIndicator';
import { ArtworkScreeningAllergenWithWt } from 'types/brand/artworkScreening/artworkScreening';

export interface ArtworkScreeningFormData {
  ingredients: {
    name: string;
    wt: string;
    casNo?: string;
    allergens: ArtworkScreeningAllergenWithWt[];
  }[];
}

const ScreeningForm = ({ form }: { form: FormInstance<ArtworkScreeningFormData> }) => {
  const { openConfirmModal, closeConfirmModal } = useModal();
  const os = getOS();

  const handlePaste =
    (addRow: VoidFunction) =>
    (
      e: React.ClipboardEvent<HTMLElement>,
      field: FormListFieldData,
      name: keyof ArtworkScreeningFormData['ingredients'][number],
    ) => {
      e.preventDefault();
      const pasteText = e.clipboardData.getData('text');
      const rows = pasteText
        .split(os === 'MacOS' ? '\n' : '\r\n')
        .map((row: string) => row.split('\t'));
      const { ingredients } = form.getFieldsValue();

      const getTransformedRows = () => {
        switch (name) {
          case 'name':
            return rows.map((row) => ({
              name: row[0],
              ...(row[1] && { wt: row[1].trimStart().trimEnd() }),
            }));
          case 'wt':
            return rows.map((row) => ({
              wt: row[0].trimStart().trimEnd(),
            }));
          default:
            return [];
        }
      };

      const transformedRows = getTransformedRows();
      const validateFieldNames: (string | number)[][] = [];
      const additionalRowCount = transformedRows.length + field.key - ingredients.length;

      if (additionalRowCount) {
        for (let i = 0; i < additionalRowCount; i++) {
          addRow();
        }
      }

      transformedRows.forEach((item, idx) => {
        ingredients[idx + field.key] = {
          ...ingredients[idx + field.key],
          ...item,
        };

        validateFieldNames.push(
          ...['name', 'wt'].map((fieldName) => ['ingredients', idx + field.key, fieldName]),
        );
      });

      form.setFieldsValue({
        ingredients: ingredients.map((item) => ({
          ...item,
          allergens: item.allergens || [],
        })),
      });
      setTimeout(() => {
        form.validateFields(validateFieldNames);
      }, 0);
    };

  const handleShowAllergyInputModal = (currentIdx: number) => {
    const currentIngredients: ArtworkScreeningFormData['ingredients'] =
      form.getFieldValue('ingredients');

    openConfirmModal({
      title: '알레르기 유발성분 함량 입력',
      content: (
        <AllergenInputModalContent
          allergens={currentIngredients[currentIdx].allergens}
          onClose={closeConfirmModal}
          onOk={(formValues) => {
            const filteredAllergens = formValues.allergens.filter((item) => !!item.allergenWt);

            form.setFieldsValue({
              ingredients: currentIngredients.map((item, idx) => {
                return idx === currentIdx ? { ...item, allergens: filteredAllergens } : item;
              }),
            });
          }}
        />
      ),
      width: 720,
      footer: null,
    });
  };

  return (
    <ScreeningContainer>
      <Form.List
        name="ingredients"
        initialValue={Array.from({ length: 5 }, () => ({
          name: null,
          wt: null,
          allergens: [],
        }))}
      >
        {(fields, { add, remove }) => {
          const columns = ['No', '성분명', 'WT %', '알레르기 유발성분'];

          return (
            <>
              <FormHeader>
                {columns.map((name, idx) => (
                  <Flex key={idx} align="center" justify="center" style={{ height: 40 }}>
                    <Typography.Text
                      style={{
                        fontSize: 14,
                        color: palette.GRAY90,
                        width: [40, 592, 280, 128][idx],
                        textAlign: 'center',
                      }}
                    >
                      {name}
                    </Typography.Text>
                  </Flex>
                ))}
              </FormHeader>
              <Form.Item shouldUpdate={(prev, next) => prev.ingredients !== next.ingredients}>
                {({ getFieldsValue }) => {
                  return (
                    <>
                      {fields.map((field) => {
                        const fieldItem = getFieldsValue().ingredients?.[field.name];

                        const hasData = fieldItem?.wt || fieldItem?.name || fieldItem?.name !== '';

                        const getRequired = () => {
                          if (hasData) {
                            return (
                              (fieldItem?.wt && (!fieldItem?.name || fieldItem?.name === '')) ||
                              (!fieldItem?.wt && fieldItem?.name && fieldItem?.name !== '')
                            );
                          } else {
                            return false;
                          }
                        };

                        const isReguiredItem = getRequired();

                        return (
                          <Row key={field.key}>
                            {fields.length > 1 && (
                              <Icon
                                name="controlMinus"
                                size={20}
                                style={{
                                  position: 'absolute',
                                  left: -32,
                                  top: '50%',
                                  transform: 'translateY(-50%)',
                                  cursor: 'pointer',
                                }}
                                onClick={() => remove(field.name)}
                              />
                            )}
                            <InputRow>
                              <Form.Item style={{ width: 40 }}>
                                <Typography.Text
                                  style={{
                                    fontSize: 14,
                                    color: palette.GRAY90,
                                    textAlign: 'center',
                                    minWidth: 18,
                                  }}
                                >
                                  {field.name + 1}
                                </Typography.Text>
                              </Form.Item>
                              <Form.Item
                                required={isReguiredItem}
                                rules={generateFormRules({
                                  required: isReguiredItem,
                                })}
                                name={[field.name, 'name']}
                                style={{ width: 592, display: 'flex', justifyContent: 'center' }}
                              >
                                <FormulaIngredientSelect
                                  width={576}
                                  field={field}
                                  onPaste={handlePaste(() => {
                                    add({ name: null, wt: null, allergens: [] });
                                  })}
                                />
                              </Form.Item>
                              <Form.Item
                                rules={[
                                  ...(generateFormRules({
                                    required: isReguiredItem,
                                  }) || []),
                                  {
                                    validator: async (_, value) => {
                                      if (!value) return Promise.resolve();

                                      if (isNaN(value)) {
                                        return Promise.reject('0~100 숫자만 입력 가능');
                                      }

                                      const numValue = Number(value);
                                      if (numValue < 0 || numValue > 100) {
                                        return Promise.reject('0~100 숫자만 입력 가능');
                                      }

                                      if (!/^-?\d+(\.\d{1,25})?$/g.test(value)) {
                                        return Promise.reject('소수점 최대 25자리까지 가능');
                                      }

                                      return Promise.resolve();
                                    },
                                  },
                                ]}
                                name={[field.name, 'wt']}
                                required={isReguiredItem}
                                style={{ width: 280, display: 'flex', justifyContent: 'center' }}
                              >
                                <Input
                                  style={{ width: 264 }}
                                  placeholder="WT % 입력"
                                  onPaste={(e) =>
                                    handlePaste(() => {
                                      add({ name: null, wt: null, allergens: [] });
                                    })(e, field, 'wt')
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                noStyle
                                shouldUpdate={(prev, next) =>
                                  prev.ingredients[field.name]?.hasAllergen !==
                                  next.ingredients[field.name]?.hasAllergen
                                }
                              >
                                {({ getFieldValue }) => {
                                  const hasAllergen =
                                    getFieldValue('ingredients')[field.name]?.allergens &&
                                    getFieldValue('ingredients')[field.name].allergens.length > 0;

                                  return (
                                    <Flex
                                      gap={4}
                                      align="center"
                                      justify="center"
                                      onClick={() => handleShowAllergyInputModal(field.name)}
                                      style={{ cursor: 'pointer', width: 128 }}
                                    >
                                      <Icon
                                        name={hasAllergen ? 'check' : 'plus'}
                                        size={16}
                                        color={hasAllergen ? 'PRIMARY50' : 'SLATE_GRAY70'}
                                      />
                                      <Typography.Text
                                        type="BODY_2"
                                        color={hasAllergen ? 'PRIMARY50' : 'SLATE_GRAY70'}
                                      >
                                        {hasAllergen ? '함량 수정' : '함량 입력'}
                                      </Typography.Text>
                                    </Flex>
                                  );
                                }}
                              </Form.Item>
                            </InputRow>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prev, next) =>
                                prev.ingredients[field.name]?.allergens !==
                                  next.ingredients[field.name]?.allergens ||
                                prev.ingredients[field.name]?.wt !==
                                  next.ingredients[field.name]?.wt
                              }
                            >
                              {({ getFieldValue }) => {
                                const allergens: ArtworkScreeningAllergenWithWt[] =
                                  getFieldValue('ingredients')[field.name]?.allergens || [];
                                const wt = getFieldValue('ingredients')[field.name]?.wt || '';

                                if (allergens.length === 0) {
                                  return null;
                                }

                                return (
                                  <Typography.Text gutter={{ left: 48, top: 8 }}>
                                    {allergens.map(
                                      ({ ingredientAllergenId, allergenInciName, allergenWt }) => {
                                        const calculatedWt = multiplyDecimalStrings(
                                          '0.01',
                                          multiplyDecimalStrings(wt, allergenWt),
                                        );

                                        return (
                                          <Typography.Text
                                            type="BODY_2"
                                            color="PRIMARY50"
                                            key={ingredientAllergenId}
                                          >
                                            {allergenInciName} 완제품에서의 함량비율 -{' '}
                                            {calculatedWt}%
                                          </Typography.Text>
                                        );
                                      },
                                    )}
                                  </Typography.Text>
                                );
                              }}
                            </Form.Item>
                          </Row>
                        );
                      })}
                    </>
                  );
                }}
              </Form.Item>

              <Form.Item>
                <AddRowButton
                  onClick={() => add({ name: null, wt: null, allergens: [] })}
                  type="dashed"
                  htmlType="button"
                >
                  <Icon name="plus" color="PRIMARY50" size={18} />
                  입력 항목 추가
                </AddRowButton>
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </ScreeningContainer>
  );
};

const ArtworkScreeningTemplete = ({ form }: { form: FormInstance<ArtworkScreeningFormData> }) => {
  const [file, setFile] = useState<File | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const fileRef = useRef<HTMLInputElement>(null);
  const [fileDragState, setFileDragState] = useState<'none' | 'dragging'>('none');
  const [fileLoading, setFileLoading] = useState<{
    type: 'upload' | 'put';
    loading: boolean;
  } | null>(null);
  const [hoverCount, setHoverCount] = useState(0);

  const { openAlertModal, openStepModal } = useModal();

  const handleInit = () => {
    openAlertModal({
      content: '초기화 하시겠습니까?',
      onOk: () => {
        form.resetFields();
        setFile(null);
      },
      okText: '초기화',
    });
  };

  const modalContentArr: StepModalContentItem[] = [
    {
      imgSrc: `https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/artwork-screening_img1.png`,
      title: `certicos에서 제공하는 엑셀 서식에\n입력한 내용 복사하기`,
      imageAlt: 'certicos',
      imgSize: 320,
      description: `엑셀 서식에 성분명, WT %를 입력 후\n해당 부분을 드래그하여 복사해 주세요.`,
    },
    {
      imgSrc:
        'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/artwork-screening_img2.png',
      imageAlt: 'certicos',
      title: `전성분표 입력 페이지에\n복사한 내용 붙여넣기`,
      imgSize: 320,
      description: `첫번째 셀에 있는 성분명 Select Box를 클릭 후\n붙여넣기 해 주세요.`,
    },
    {
      imgSrc:
        'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/image/artwork-screening_img3.png',
      title: `자동으로 입력된\n내용 확인하기`,
      imageAlt: 'certicos',
      imgSize: 320,
      description: `엑셀 서식에 공백이 있는 경우\n원하는 셀에 붙여넣기가 되지 않을 수 있어요.`,
    },
  ];

  const handleShowInputExampleModal = () => {
    openStepModal({
      modalContentArr,
      width: 404,
      bodyStyle: { padding: '57px 32px 32px 32px', height: 469 },
      imgTextGap: 40,
    });
  };

  const commonFileUpload = async (filesList: FileList | File[]) => {
    const files: File[] = [];

    if (filesList.length > 1) {
      return message.warning('1개의 파일만 업로드 가능합니다.');
    }

    for (const file of filesList) {
      const filename = file.name.toLowerCase();

      if (!filename.endsWith('.xlsx')) {
        return message.warning('엑셀 파일만 업로드 가능합니다.');
      }
      files.push(file);
    }

    setFileLoading({
      type: 'upload',
      loading: true,
    });

    const data = await filesList[0]?.arrayBuffer();

    const wb = XLSX.read(data);
    const ws = wb.Sheets[wb.SheetNames[0]];

    const convertExponentialToDecimal = (str: string): string => {
      // 지수 표기법이 사용되지 않았다면 그대로 반환
      if (!str.toLowerCase().includes('e')) return str;

      // 지수 표기법이 사용된 경우, 파싱하여 처리 (ex. 1.23e-10)
      const [baseNumber, exponent] = str.split(/e/i).map(Number);

      const baseNumberArray = baseNumber.toString().split('.');
      const baseNumberLength = baseNumberArray.join('').length;
      const absoluteExponent = Math.abs(exponent);
      const zerosCount = Math.max(absoluteExponent - baseNumberLength + 1, 0);

      let result: string;

      if (exponent >= 0) {
        result = baseNumber.toFixed(absoluteExponent + 1).replace(/\.?0+$/, '');
      } else {
        result = '0.' + '0'.repeat(zerosCount) + baseNumberArray.join('');
      }

      return result;
    };

    if (!ws['A1']?.['v'] || ws['A3']?.['v'] !== 'No.' || ws['B3']?.['v'] !== 'Ingredient Name') {
      message.warning(
        '파일형식이 맞지 않습니다. 엑셀 서식을 다운로드하여 양식에 맞게 입력 후 업로드 해주세요.',
      );
      setFileLoading({
        type: 'upload',
        loading: false,
      });
      return;
    }

    const jsonData = XLSX.utils.sheet_to_json(ws);
    const lastValidIndex = jsonData.reduce((lastIdx: number, item: any, currentIdx: number) => {
      if (item['__EMPTY'] || item['__EMPTY_1']) {
        return currentIdx;
      } else if (item['_1']) {
        return currentIdx;
      }
      return lastIdx;
    }, 0);

    const correctExcel = jsonData.slice(1, lastValidIndex + 1).map((item: any) => {
      return {
        name: item['__EMPTY'],
        wt: convertExponentialToDecimal((item['__EMPTY_1'] || '').toString()),
        allergens: [],
      };
    });

    const fromAdminExcel = jsonData
      .slice(1, lastValidIndex + 1)
      .map((item: any) => {
        return {
          name: item[''],
          wt: convertExponentialToDecimal((item['_1'] || '').toString()),
          allergens: [],
        };
      })
      .filter(({ name }) => name !== 'Ingredient Name');

    form.setFieldsValue({
      ingredients: jsonData.some((item: any) => !!item['__EMPTY']) ? correctExcel : fromAdminExcel,
    });
    setTimeout(() => {
      form.validateFields();
    }, 0);

    setFile(files[0]);
    message.success(`정상적으로 업로드되었습니다.`);

    setFileLoading({
      type: 'upload',
      loading: false,
    });
  };

  const handleFileDragLeave: DragEventHandler<HTMLInputElement> = () => {
    setHoverCount((count) => count - 1);

    if (hoverCount === 1) {
      setFileDragState('none');
    }
  };

  const handleFileDragUpload: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
    handleFileDragLeave(e);
    if (e.dataTransfer.files.length === 0) return;

    if ([...e.dataTransfer.files].filter((file) => file.type === '').length > 0) {
      message.warning('폴더를 제외한 파일 형식만 업로드해주세요.');
      return;
    }

    commonFileUpload(e.dataTransfer.files);
  };

  const handleFileDragEnter: DragEventHandler<HTMLInputElement> = (e) => {
    setHoverCount((count) => count + 1);
    setFileDragState('dragging');
  };

  const handleFileUpload: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files?.length) return;

    commonFileUpload(e.target.files);

    e.target.value = '';
  };

  const preventDefault: DragEventHandler<HTMLInputElement> = (e) => {
    e.preventDefault();
  };

  const referToFile = () => fileRef.current?.click();

  return (
    <Container>
      <Flex
        gap={8}
        style={{
          marginTop: 32,
          paddingBottom: 8,
          borderBottom: `2px solid ${palette.PRIMARY50}`,
          width: '100%',
        }}
        align="center"
        justify="space-between"
      >
        <Flex gap={4} align="center">
          <Typography.Text type="TITLE_1">전성분표 입력</Typography.Text>
          <Icon color="GRAY70" name="refresh" onClick={handleInit} />
        </Flex>
        <SmallGrayButton
          type="button"
          onClick={() =>
            downloadFile(
              'https://30cos.s3.ap-northeast-2.amazonaws.com/service/static/sample/Ingredients_Screening_Form.xlsx',
            )
          }
        >
          <Icon name="download" size={16} color="SLATE_GRAY60" />
          엑셀 서식 다운로드
        </SmallGrayButton>
      </Flex>
      <DescriptionContainer gap={4} dir="column">
        <Flex align="center" gap={8}>
          <Typography.Text type="BODY_2">
            · 엑셀 서식에 입력 내용을 복사한 후 certicos에서 제공하는 템플릿에 붙여넣기하면 자동으로
            입력됩니다.
          </Typography.Text>
          <ExampleButton
            justify="center"
            align="center"
            onClick={handleShowInputExampleModal}
            gap={4}
          >
            <Icon name="find" size={12} color="PRIMARY50" />
            가이드 보기
          </ExampleButton>
        </Flex>
        <Typography.Text style={{ fontSize: 14 }}>
          <Typography.Text type="BODY_2" inline color="MESSAGE_ERROR" style={{ fontWeight: 500 }}>
            · 단일 전성분표
          </Typography.Text>{' '}
          서류를 제조사에 요청하여 미리 준비해 주세요.
        </Typography.Text>
        <Typography.Text style={{ fontSize: 14 }}>
          · 알레르기 유발 성분에 대한 표기 검토를 위해서는{' '}
          <Typography.Text type="BODY_2" inline color="MESSAGE_ERROR" style={{ fontWeight: 500 }}>
            Allergen List
          </Typography.Text>{' '}
          서류를 제조사에 요청하여 준비해 주세요.
        </Typography.Text>
        <Typography.Text type="BODY_2">
          · Artwork와 동일한 언어를 사용하여 전성분표에 작성해 주세요.
        </Typography.Text>
      </DescriptionContainer>
      {!file && (
        <UploadContainer
          ref={containerRef}
          dragging={fileDragState === 'dragging'}
          onDragEnter={handleFileDragEnter}
          onDragLeave={handleFileDragLeave}
          onDragOver={preventDefault}
          onDrop={handleFileDragUpload}
          align="center"
          justify="center"
        >
          <input
            type="file"
            ref={fileRef}
            multiple={true}
            style={{ display: 'none' }}
            onChange={handleFileUpload}
            accept=".xlsx"
          />
          {fileLoading && fileLoading.loading ? (
            <UploadingContainer dir="column" align="center" gap={8}>
              <LoadingIndicator size="md" />
              <Typography.Text
                style={{
                  fontSize: 16,
                  color: palette.SLATE_GRAY70,
                  fontWeight: 500,
                }}
              >
                파일을 업로드 하는 중...
              </Typography.Text>
            </UploadingContainer>
          ) : (
            <Flex dir="column" align="center" justify="center" onClick={referToFile}>
              <Typography.Text
                style={{
                  color: palette.SLATE_GRAY60,
                  fontWeight: 500,
                  marginBottom: 8,
                }}
              >
                직접 입력하거나 서식 파일 업로드
              </Typography.Text>
              <Typography.Text
                style={{
                  color: palette.SLATE_GRAY60,
                  fontSize: 14,
                  fontWeight: 400,
                }}
              >
                업로드 가능한 파일 : xlsx
              </Typography.Text>
              <Button
                loading={fileLoading?.loading}
                icon={<Icon name="upload" size={18} color="GRAY50" />}
                style={{
                  borderColor: palette.GRAY40,
                  color: palette.GRAY70,
                  height: 32,
                  marginTop: 16,
                  padding: '6px 8px',
                }}
              >
                파일 업로드
              </Button>
            </Flex>
          )}
        </UploadContainer>
      )}
      {file && (
        <UploadedFile>
          <Icon name="clip" size={18} color="SLATE_GRAY70" />
          <Typography.Text type="SMALL" color="SLATE_GRAY70" style={{ flex: 1 }}>
            {file.name}
          </Typography.Text>
          <Icon name="close" size={18} color="SLATE_GRAY70" onClick={() => setFile(null)} />
        </UploadedFile>
      )}
      <DataContainer>
        <ScreeningForm form={form} />
      </DataContainer>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .ant-form-item {
    margin-bottom: 0px !important;
  }

  .editable-cell-value-wrap {
    padding: 0 !important;
  }

  .ant-form-item-explain.ant-form-item-explain-error {
    text-align: start !important;
  }
`;

const SmallGrayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.SLATE_GRAY70};
  background-color: ${palette.SLATE_GRAY30};
  padding: 6px 8px;
  font-weight: 500;
  font-size: 14px;
  height: 32px;
  border: none;
  border-radius: 4px;
  gap: 4px;
  cursor: pointer;
`;

const DescriptionContainer = styled(Flex)`
  justify-content: center;
  margin-top: 16px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px;
  padding: 12px 16px;
  width: 100%;
  font-size: 14px;
  color: ${palette.SLATE_GRAY70};
`;

const DataContainer = styled.div`
  margin-top: 24px;
`;

const AddRowButton = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 520px;
  height: 44px;
  color: ${palette.GRAY90};
  font-size: 14px;
  gap: 4px;
  margin: 0 auto;
  margin-top: 16px;
`;

const ScreeningContainer = styled.div`
  .ant-form-item .ant-form-item-explain {
    height: 0;
    min-height: 0;
    transform: translateY(-2px);
  }
`;

const FormHeader = styled(Flex)`
  background-color: ${palette.PRIMARY10};
  color: ${palette.GRAY90};
`;

const Row = styled.div`
  position: relative;
  border-bottom: 1px solid ${palette.GRAY20};
  padding: 12px 0;
`;

const InputRow = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: flex-start;
  align-items: center;
`;

const ExampleButton = styled(Flex)`
  border-radius: 4px;
  border: 1px solid ${palette.PRIMARY50};
  background: white;
  font-size: 12px;
  font-weight: 400;
  color: ${palette.GRAY90};
  height: 24px;
  cursor: pointer;
  padding: 0 8px;
  transition: all 200ms ease-in-out;

  &:hover {
    color: ${palette.PRIMARY50};
  }
`;

const UploadContainer = styled(Flex)<{ dragging: boolean }>`
  width: 100%;
  background-color: white;
  border-radius: 8px;
  padding: 24px;
  margin-top: 16px;
  transition: border 100ms linear;
  border: 1px solid ${palette.GRAY30};

  ${({ dragging }) =>
    dragging &&
    `
    border: 2px dashed ${palette.PRIMARY50};
  `}
`;

const UploadingContainer = styled(Flex)``;

const UploadedFile = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  padding: 10px 16px;

  border-radius: 4px;
  border: 1px dashed ${palette.GRAY40};
  background: ${palette.ETC_WHITE};
`;

export default ArtworkScreeningTemplete;
