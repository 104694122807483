import { Fragment, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Col, Form, Input, InputRef, Row, Select } from 'antd';

import Typography from 'components/system/general/Typography';
import { FileUpload, Tip } from 'components/system';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { exceptKoreanRule, phoneRule, requireRule, urlRule } from 'lib/validate';
import * as authApi from 'lib/api/auth';
import palette from 'lib/styles/palette';
import { messages } from 'lib/consts';
import { CompanyType } from 'types/company';
import type { CGMP } from './ManuRegisterCreateForm';

interface ManuCompanyFormProps {
  isVisible?: boolean;
  isEdit?: boolean;
  form: any;
  factoryInfos: { ko: string; en: string; fileInputName: string; cGMP: CGMP }[];
  searchValue: string;
  handleSelectSearch: (search: string) => void;
  handlePreviewedCGMP: (cGMP: CGMP) => void;
}

const ManuCompanyForm = ({
  isVisible = true,
  isEdit,
  form,
  factoryInfos,
  searchValue,
  handleSelectSearch,
  handlePreviewedCGMP,
}: ManuCompanyFormProps) => {
  const { user } = useSelector(({ auth }) => ({ user: auth.user }), shallowEqual);

  const { data: manufacturers = [] } = useManufacturerCompanies({ isExcludeUserInserted: true });

  const inputRef = useRef<InputRef | null>(null);
  const [isBizNumberCheckSuccess, setIsBizNumberCheckSuccess] = useState(false);

  return (
    <div style={{ display: isVisible ? 'block' : 'none' }}>
      <Form.Item
        shouldUpdate={(prev, next) => prev.companyNameKo !== next.companyNameKo}
        label={
          <Row gutter={8} align="middle">
            <Col>
              <Typography.Text type="TITLE_2">회사명 (국문/영문)</Typography.Text>
            </Col>
            <Col>
              <Tip trigger="click">
                <Typography.Text type="SMALL">
                  사업자등록증의 사명과 동일하게 입력하되, 띄어쓰기 없이 입력해 주세요.
                  <br />
                  중복 데이터 또는 오인 표기를 줄이기 위함이므로,
                </Typography.Text>
                <Typography.Text type="SMALL" color="PRIMARY50">
                  회사명에 띄어쓰기가 입력되지 않아도 무방합니다.
                </Typography.Text>
              </Tip>
            </Col>
          </Row>
        }
        style={{ marginBottom: 0 }}
        required
      >
        {({ getFieldValue, setFieldsValue }) => (
          <Row gutter={8} wrap={false}>
            <Col flex="auto">
              <Form.Item name="companyNameKo" rules={[requireRule]} style={{ marginBottom: 0 }}>
                <Select
                  disabled={isEdit}
                  showSearch
                  searchValue={searchValue}
                  onSearch={handleSelectSearch}
                  placeholder="국문 회사명 검색 또는 선택"
                  notFoundContent={
                    getFieldValue('companyNameKo') !== 'companyNameKoDirect' ? (
                      <Typography.Text
                        style={{ cursor: 'pointer' }}
                        color="GRAY90"
                        type="BODY_2"
                        onClick={() =>
                          setFieldsValue({
                            companyNameKo: 'companyNameKoDirect',
                          })
                        }
                      >
                        직접입력
                      </Typography.Text>
                    ) : null
                  }
                  onSelect={(value) => {
                    if (value === 'companyNameKoDirect') {
                      setTimeout(() => inputRef?.current?.focus());
                    }
                  }}
                >
                  <Select.Option key="companyNameKoDirect" value="companyNameKoDirect">
                    직접입력
                  </Select.Option>
                  {manufacturers.map((manufacturer: any) => (
                    <Select.Option
                      key={manufacturer.manufacturerId}
                      value={manufacturer.companyNameKo}
                    >
                      {manufacturer.companyNameKo}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex="0 0 50%">
              <Form.Item
                name={
                  getFieldValue('companyNameKo') !== 'companyNameKoDirect'
                    ? 'companyNameKo'
                    : 'companyNameKoDirect'
                }
                normalize={(value) => value.replace(/\s/gi, '')}
                rules={[requireRule]}
                style={{ marginBottom: 8 }}
              >
                <Input
                  ref={inputRef}
                  disabled={isEdit || getFieldValue('companyNameKo') !== 'companyNameKoDirect'}
                  placeholder={
                    getFieldValue('companyNameKo') === 'companyNameKoDirect'
                      ? '국문 회사명 여기에 입력'
                      : undefined
                  }
                  style={
                    isEdit
                      ? undefined
                      : { color: palette.GRAY90, backgroundColor: palette.ETC_WHITE }
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form.Item>
      <Form.Item name="companyNameEn" rules={[requireRule, exceptKoreanRule]}>
        <Input disabled={isEdit} placeholder="영문 회사명" />
      </Form.Item>
      <Form.Item
        label="사업자등록번호"
        validateStatus={isBizNumberCheckSuccess ? 'success' : undefined}
        help={isBizNumberCheckSuccess ? '사용 가능한 사업자등록번호' : undefined}
        name="bizNumber"
        normalize={(value) => {
          if (value.length > 12) return value.substring(0, 12);
          if (/[^0-9-]/.test(value)) return value.replace(/[^0-9-]/, '');
          if (value.slice(-1) === '-') return value;
          return value
            .replace(/[-]/g, '')
            .replace(/^(\d{3})(\d{1,})/, '$1-$2')
            .replace(/^(\d{3}-\d{2})(\d{1,})/, '$1-$2');
        }}
        rules={
          user?.company?.isTestCompany
            ? []
            : [
                requireRule,
                { pattern: /^\d{3}-\d{2}-\d{5}$/, message: '올바르지 않은 사업자등록번호 형식' },
                {
                  validator: async (_, bizNumber) => {
                    if (isEdit || !/^\d{3}-\d{2}-\d{5}$/.test(bizNumber)) return;

                    try {
                      await authApi.bizNumberDupCheck({
                        bizNumber,
                        companyType: CompanyType.MANUFACTURE,
                      });
                      setIsBizNumberCheckSuccess(true);
                    } catch (e) {
                      setIsBizNumberCheckSuccess(false);
                      throw new Error('이미 가입되어 있는 사업자등록번호');
                    }
                    return;
                  },
                },
              ]
        }
      >
        <Input
          disabled={isEdit}
          autoComplete="off"
          onChange={() => setIsBizNumberCheckSuccess(false)}
        />
      </Form.Item>
      {factoryInfos.map((factoryInfo, i) => (
        <Fragment key={i}>
          <Form.Item
            style={{ marginBottom: '8px' }}
            label={`제 ${i + 1}공장 소재지 (국문/영문)`}
            name={factoryInfo.ko}
            rules={
              i === 0
                ? [requireRule]
                : [
                    {
                      validator: (_, value) => {
                        if (!value && form.getFieldValue(factoryInfo.en)) {
                          return Promise.reject(
                            new Error(
                              `'현재 항목을 입력하시거나 [제 ${
                                i + 1
                              }공장 소재지 (영문)] 항목을 비워주세요. (일부 항목만 입력 불가능)'`,
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]
            }
          >
            <Input placeholder="국문" />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '8px' }}
            name={factoryInfo.en}
            rules={
              i === 0
                ? [requireRule, exceptKoreanRule]
                : [
                    {
                      validator: (_, value) => {
                        if (!value && form.getFieldValue(factoryInfo.ko)) {
                          return Promise.reject(
                            new Error(
                              `'현재 항목을 입력하시거나 [제 ${
                                i + 1
                              }공장 소재지 (국문)] 항목을 비워주세요. (일부 항목만 입력 불가능)'`,
                            ),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]
            }
          >
            <Input placeholder="영문(보유하신 cGMP(ISO22716) 과 동일한 영문으로 입력)" />
          </Form.Item>
          {form.getFieldValue(factoryInfo.ko) && form.getFieldValue(factoryInfo.en) && (
            <Row gutter={8}>
              <Col>
                <Form.Item
                  required={i === 0}
                  name={factoryInfo.fileInputName}
                  rules={[
                    {
                      validator: () => {
                        if (i === 0) {
                          if (!factoryInfo.cGMP.file && !factoryInfo.cGMP.uploadedFile) {
                            return Promise.reject(new Error(messages.REQUIRED_FIELD));
                          }
                        } else {
                          if (
                            (form.getFieldValue(factoryInfo.ko) ||
                              form.getFieldValue(factoryInfo.en)) &&
                            !factoryInfo.cGMP.file &&
                            !factoryInfo.cGMP.uploadedFile
                          ) {
                            return Promise.reject(
                              new Error(
                                `현재 항목을 입력하시거나 [제 ${i + 1}공장 소재지 (${
                                  form.getFieldValue(factoryInfo.ko) ? '영문' : '국문'
                                })] 항목을 비워주세요. (일부 항목만 입력 불가능)`,
                              ),
                            );
                          }
                        }

                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Button
                    style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                    icon={<Icon size={18} name="upload" color="PRIMARY50" />}
                    onClick={() => {
                      handlePreviewedCGMP(factoryInfo.cGMP);
                    }}
                  >
                    {`제 ${i + 1}공장 cGMP (ISO22716) 첨부하기`}
                  </Button>
                </Form.Item>
              </Col>
              {(factoryInfo.cGMP.file ||
                (factoryInfo.cGMP.uploadedFile && factoryInfo.cGMP.uploadedFile.url)) && (
                <Col style={{ paddingTop: 12 }}>
                  <Typography.Text inline>
                    {factoryInfo.cGMP.file
                      ? factoryInfo.cGMP.file.name
                      : factoryInfo.cGMP.uploadedFile && factoryInfo.cGMP.uploadedFile.name}
                  </Typography.Text>
                </Col>
              )}
            </Row>
          )}
        </Fragment>
      ))}
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="대표자 성명 (국문/영문)" name="ceoNameKo" rules={[requireRule]}>
            <Input placeholder="국문" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item name="ceoNameEn" rules={[requireRule, exceptKoreanRule]}>
            <Input placeholder="영문" />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={8} align="bottom">
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="회사 전화번호" name="tel" rules={[requireRule, phoneRule]}>
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 12 }}>
          <Form.Item label="팩스번호 (FAX)" name="fax" rules={[phoneRule]}>
            <Input placeholder="숫자만 입력 가능" />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label="홈페이지 URL" name="homepageUrl" rules={[urlRule]}>
        <Input placeholder="예시: https://www.certicos.pro" />
      </Form.Item>
      <Flex gap={16} dir={isEdit ? 'row' : 'column'}>
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col style={{ fontSize: '18px' }}>국문 사업자등록증 (PDF)</Col>
              {isEdit && (
                <Col>
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      - 사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이
                      <br />
                      필요합니다.
                    </Typography.Text>
                    <Typography.Text type="SMALL">
                      - 수정을 원하시면{' '}
                      <Typography.Text type="SMALL" color="PRIMARY50" inline>
                        전화나 하단의 ‘이용 문의’로 문의바랍니다.
                      </Typography.Text>
                    </Typography.Text>
                  </Tip>
                </Col>
              )}
            </Row>
          }
          required
          name="bizLicenseFile"
          rules={[requireRule]}
        >
          <FileUpload readOnly={!!isEdit} bodyStyle={{ width: 336, height: 448 }} />
        </Form.Item>
        <Form.Item
          label={
            <Row gutter={8} align="middle">
              <Col style={{ fontSize: '18px' }}>영문 사업자등록증 (PDF)</Col>
              <Col>
                {isEdit ? (
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      - 사업자등록증의 내용이 기존과 달라졌을 경우, 반드시 수정이
                      <br />
                      필요합니다.
                    </Typography.Text>
                    <Typography.Text type="SMALL">
                      - 수정을 원하시면{' '}
                      <Typography.Text type="SMALL" color="PRIMARY50" inline>
                        전화나 하단의 ‘이용 문의’로 문의바랍니다.
                      </Typography.Text>
                    </Typography.Text>
                  </Tip>
                ) : (
                  <Tip trigger="click">
                    <Typography.Text type="SMALL">
                      영문 사업자등록증이 없으신가요?
                      <br />
                      영문 사업자등록증은 인증 진행시 필요한 서류이므로
                      <br />
                      없으신 경우 전화나 하단의 ‘이용 문의’로 문의바랍니다.
                    </Typography.Text>
                  </Tip>
                )}
              </Col>
            </Row>
          }
          required
          name="bizLicenseEnFile"
          rules={[requireRule]}
        >
          <FileUpload readOnly={!!isEdit} bodyStyle={{ width: 336, height: 448 }} />
        </Form.Item>
      </Flex>
    </div>
  );
};

export default ManuCompanyForm;
