import { Form, message } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { useManuMode } from 'hook/company';
import { messages } from 'lib/consts';
import { getUpdatingObject } from 'lib/form';
import {
  PackingAttestation,
  PackingAttestationAddParam,
  PackingAttestationAttache,
} from 'types/brand/product/packingAttestation';
import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import { createFormData } from 'lib/file';
import useGA, { EGAActionType } from 'hook/useGA';
import { useCertificateMode } from 'hook/certificate';
import { useMovePathname } from 'hook/useMovePathname';

const queryKey = '/product-packing-attestations';

export const useProductPackingAttestation = (productId: number, countryId?: number) => {
  const queryClient = useQueryClient();
  const { movePathname } = useMovePathname();
  const manuMode = useManuMode();
  const certificateMode = useCertificateMode();
  const { sendEventToGA } = useGA();
  const navigate = useNavigate();

  const { skipMode, currentProduct } = useSelector(
    ({ product }: any) => ({
      currentProduct: product.currentProduct,
      nextPathname: product.nextPathname,
      skipMode: product.skipMode,
    }),
    shallowEqual,
  );

  const [form] = Form.useForm();
  const [packingAttestationType, setPackingAttestationType] = useState(
    currentProduct.packingAttestationType,
  );
  const [firstPackingFiles, setFirstPackingFiles] = useState<(PackingAttestationAttache | File)[]>(
    [],
  );
  const [secondPackingFiles, setSecondPackingFiles] = useState<
    (PackingAttestationAttache | File)[]
  >([]);
  const [currentFirstFile, setCurrentFirstFile] = useState<PackingAttestationAttache | File>();
  const [currentSecondFile, setCurrentSecondFile] = useState<PackingAttestationAttache | File>();
  const [deletedFiles, setDeletedFiles] = useState<(PackingAttestationAttache | File)[]>([]);

  const { data: packingAttestation, isLoading: getLoading } = useQuery(
    [queryKey, productId, countryId],
    () =>
      client.get<APIResponse<PackingAttestation>>(
        `products/${productId}/product-packing-attestations${
          countryId !== undefined ? `?countryId=${countryId}` : ''
        }`,
      ),
    {
      select: (res) => {
        const result = res.data.result;
        if (result) {
          return {
            ...result,
            productPackingAttestationAttaches: result.productPackingAttestationAttaches.map(
              (attach) => ({
                ...attach,
                url: attach.attachUrl,
                name: attach.filename,
              }),
            ),
          };
        }
      },
      enabled: !manuMode || packingAttestationType !== 'BND',
    },
  );

  const { mutate: addProductPackingAttestation, isLoading: addLoading } = useMutation(
    ({ productId, data }: { productId: number; data: PackingAttestationAddParam }) =>
      client.post(
        `products/${productId}/product-packing-attestations${
          countryId !== undefined ? `?countryId=${countryId}` : ''
        }`,
        createFormData(data),
      ),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: 'Packing Attestation',
          actionType: EGAActionType.REGISTER,
        });
      },
    },
  );

  const { mutate: updateProductPackingAttestation, isLoading: updateLoading } = useMutation(
    ({
      productId,
      countryId,
      data,
    }: {
      productId: number;
      countryId?: number;
      data: PackingAttestationAddParam;
    }) =>
      client.post(
        `products/${productId}/product-packing-attestations/update${
          countryId !== undefined ? `?countryId=${countryId}` : ''
        }`,
        createFormData(data),
      ),
    {
      onSuccess: () => {
        sendEventToGA({
          documentName: 'Packing Attestation',
          actionType: certificateMode ? EGAActionType.MODIFY : EGAActionType.UPDATE,
        });
      },
    },
  );

  const { mutate: savePackingAttestationType, isLoading: saveLoading } = useMutation(
    ({
      productId,
      packingAttestationType,
    }: {
      productId: number;
      packingAttestationType: string;
    }) =>
      client.patch(
        `/manufacturer/pack-attestation/${productId}?packingAttestationType=${packingAttestationType}`,
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKey);
        if (skipMode) {
          movePathname({ productId });
        } else {
          navigate(-1);
        }
      },
    },
  );

  const fetchLoading = addLoading || saveLoading;

  const updateMode = packingAttestation !== undefined;

  const isFile = (file: any): file is File => !file.url;

  const handleUpdateProductPackingAttestation = (params: any) => {
    const [
      { productId, countryId, packingAttestation },
      { firstPackingFiles, secondPackingFiles, deletedFiles },
      packingAttestationType,
    ] = params;

    const deleteFileArr: number[] = [];

    if (deletedFiles.length > 0) {
      deletedFiles.map((item: any) => deleteFileArr.push(item.productPackingAttestationAttachId));
    }

    updateProductPackingAttestation(
      {
        productId,
        countryId,
        data: {
          ...packingAttestation,
          packingContainer1Files: firstPackingFiles,
          packingContainer2Files: secondPackingFiles,
          deletePackingContainerFileIds: deleteFileArr,
        },
      },
      {
        onSuccess: () => {
          if (packingAttestationType) {
            savePackingAttestationType(
              {
                productId,
                packingAttestationType,
              },
              {
                onSuccess: () => {
                  message.success(`${certificateMode ? '보완 완료' : '수정'}되었습니다.`);
                },
              },
            );
          } else {
            message.success(`${certificateMode ? '보완 완료' : '수정'}되었습니다.`);
            navigate(-1);
          }
        },
      },
    );
  };

  const submit = useCallback(
    (formValue: any) => {
      // 등록
      if (!updateMode) {
        const packingAttestation = {
          ...formValue,
          firstPackingFiles,
          ...(formValue.isUsePackingContainer2 && {
            secondPackingFiles,
          }),
        };

        // 브랜드사 등록
        if (!manuMode) {
          addProductPackingAttestation(
            {
              productId: currentProduct.productId,
              data: {
                ...packingAttestation,
                packingContainer1Files: packingAttestation.firstPackingFiles,
                packingContainer2Files: packingAttestation.secondPackingFiles,
              },
            },
            {
              onSuccess: () => {
                if (skipMode) {
                  movePathname({ productId: currentProduct.productId });
                  return;
                }
                message.success('입력되었습니다.');
                navigate(-1);
              },
            },
          );
          // 제조사 등록
        } else {
          addProductPackingAttestation(
            {
              productId: currentProduct.productId,
              data: {
                ...packingAttestation,
                packingContainer1Files: packingAttestation.firstPackingFiles,
                packingContainer2Files: packingAttestation.secondPackingFiles,
              },
            },
            {
              onSuccess: () => {
                savePackingAttestationType(
                  {
                    productId: currentProduct.productId,
                    packingAttestationType,
                  },
                  {
                    onSuccess: () => {
                      message.success('입력되었습니다.');
                    },
                  },
                );
              },
            },
          );
        }

        return;
      }

      // 수정 or 보완
      const {
        packingContainer1,
        isUsePackingContainer2,
        packingContainer2,
        isUseRecyclingContainer,
        isUseColorContainer,
      } = formValue;

      // 브랜드사 수정 or 보완
      if (!manuMode) {
        const updateValues = {
          ...getUpdatingObject(
            {
              packingContainer1,
              isUsePackingContainer2,
              isUseRecyclingContainer,
            },
            packingAttestation,
          ),
          ...(typeof packingContainer2 !== 'undefined' &&
            packingContainer2 !== packingAttestation.packingContainer2 && {
              packingContainer2,
            }),
          ...(typeof isUseColorContainer !== 'undefined' &&
            isUseColorContainer !== packingAttestation.isUseColorContainer && {
              isUseColorContainer: isUseColorContainer || false,
            }),
        };
        const newFirstPackingFiles = firstPackingFiles.filter((file) => isFile(file));
        const newSecondPackingFiles = secondPackingFiles.filter((file) => isFile(file));
        if (
          !Object.keys(updateValues).length &&
          currentProduct.packingAttestationType === packingAttestationType &&
          deletedFiles.length === 0 &&
          newFirstPackingFiles.length === 0 &&
          newSecondPackingFiles.length === 0
        ) {
          return message.warning(messages.NO_NEED_TO_UPDATE);
        }

        handleUpdateProductPackingAttestation([
          {
            productId,
            countryId,
            packingAttestation: {
              productPackingAttestationId: packingAttestation.productPackingAttestationId,
              ...updateValues,
            },
          },
          {
            firstPackingFiles: newFirstPackingFiles,
            secondPackingFiles: newSecondPackingFiles,
            deletedFiles:
              packingAttestation.isUsePackingContainer2 && !isUsePackingContainer2
                ? deletedFiles.concat(
                    packingAttestation.productPackingAttestationAttaches.filter(
                      ({ attachType }) => attachType === '2ST',
                    ),
                  )
                : deletedFiles,
          },
        ]);
        // 제조사 수정 or 보완
        // 턴키 > 턴키 => 수정
      } else if (
        currentProduct.packingAttestationType !== 'BND' &&
        packingAttestationType !== 'BND'
      ) {
        const updateValues = {
          ...getUpdatingObject(
            {
              packingContainer1,
              isUsePackingContainer2,
              isUseRecyclingContainer,
            },
            packingAttestation,
          ),
          ...(typeof packingContainer2 !== 'undefined' &&
            packingContainer2 !== packingAttestation.packingContainer2 && {
              packingContainer2,
            }),
          ...(typeof isUseColorContainer !== 'undefined' &&
            isUseColorContainer !== packingAttestation.isUseColorContainer && {
              isUseColorContainer: isUseColorContainer || false,
            }),
        };
        const newFirstPackingFiles = firstPackingFiles.filter((file) => isFile(file));
        const newSecondPackingFiles = secondPackingFiles.filter((file) => isFile(file));
        if (
          !Object.keys(updateValues).length &&
          currentProduct.packingAttestationType === packingAttestationType &&
          deletedFiles.length === 0 &&
          newFirstPackingFiles.length === 0 &&
          newSecondPackingFiles.length === 0
        ) {
          return message.warning(messages.NO_NEED_TO_UPDATE);
        }

        handleUpdateProductPackingAttestation([
          {
            productId,
            countryId,
            packingAttestation: {
              productPackingAttestationId: packingAttestation.productPackingAttestationId,
              ...updateValues,
            },
          },
          {
            firstPackingFiles: newFirstPackingFiles,
            secondPackingFiles: newSecondPackingFiles,
            deletedFiles,
          },
          packingAttestationType,
        ]);
      }
    },
    [
      currentProduct,
      updateMode,
      manuMode,
      packingAttestation,
      firstPackingFiles,
      secondPackingFiles,
      packingAttestationType,
    ],
  );

  const patchPackingAttestationType = useCallback(() => {
    savePackingAttestationType({
      productId: currentProduct.productId,
      packingAttestationType,
    });
  }, [currentProduct, packingAttestationType]);

  useEffect(() => {
    if (packingAttestation) {
      form.setFieldsValue({
        packingContainer1: packingAttestation.packingContainer1,
        isUsePackingContainer2: packingAttestation.isUsePackingContainer2,
        packingContainer2: packingAttestation.packingContainer2,
        isUseRecyclingContainer: packingAttestation.isUseRecyclingContainer,
        isUseColorContainer: packingAttestation.isUseColorContainer,
      });
      setFirstPackingFiles(
        packingAttestation.productPackingAttestationAttaches.filter(
          ({ attachType }) => attachType === '1ST',
        ),
      );
      if (packingAttestation.isUsePackingContainer2) {
        setSecondPackingFiles(
          packingAttestation.productPackingAttestationAttaches.filter(
            ({ attachType }) => attachType === '2ST',
          ),
        );
      }
      setCurrentFirstFile(
        packingAttestation.productPackingAttestationAttaches.find(
          ({ attachType }) => attachType === '1ST',
        ),
      );

      if (packingAttestation.isUsePackingContainer2) {
        setCurrentSecondFile(
          packingAttestation.productPackingAttestationAttaches.find(
            ({ attachType }) => attachType === '2ST',
          ),
        );
      }
    } else {
      form.resetFields();
      setFirstPackingFiles([]);
      setSecondPackingFiles([]);
      setCurrentFirstFile(undefined);
      setCurrentSecondFile(undefined);
      setDeletedFiles([]);
    }
  }, [packingAttestation]);

  return {
    updateMode,
    form,
    getLoading,
    fetchLoading,
    updateLoading,
    packingAttestationType,
    currentFirstFile,
    setCurrentFirstFile,
    firstPackingFiles,
    setFirstPackingFiles,
    currentSecondFile,
    setCurrentSecondFile,
    secondPackingFiles,
    setSecondPackingFiles,
    submit,
    changePackingAttestationType: setPackingAttestationType,
    patchPackingAttestationType,
    isFile,
    deletedFiles,
    setDeletedFiles,
  };
};
