import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'antd';

import { Typography } from 'components/system';
import { useManufacturerCompanies } from 'service/manufacturer/company';
import { useModal } from 'hook/useModal';
import path from 'lib/path';
import { useForceUpdate } from 'lib/hook';
import { scrollToFirstErrorOption } from 'lib/consts';
import { manuRegisterCompany, manuUpdateCompany } from 'modules/auth';
import { CGMP } from '../ManuRegisterCreateForm';

interface InitialData {
  user: any;
  company: any;
  factories: any[];
}

interface UseManuRegisterFormProps {
  initialData?: InitialData;
  handleChangeTab?: (tab: 'company' | 'user') => void;
}

export interface ManuForm {
  companyNameKo: string;
  companyNameKoDirect: string;
  companyNameEn: string;
  bizNumber: string;
  firstAddressKo: string;
  firstAddressEn: string;
  secondAddressKo: string;
  secondAddressEn: string;
  thirdAddressKo: string;
  thirdAddressEn: string;
  ceoNameKo: string;
  ceoNameEn: string;
  tel: string;
  fax: string;
  homepageUrl: string;
  bizLicenseFile: File | { name: string; url: string };
  bizLicenseEnFile: File | { name: string; url: string };
  username: string;
  password: string;
  passwordConfirm: string;
  name: string;
  deptName: string;
  positionName: string;
  mobile: string;
  email: string;
}

const INIT_CGMP = { isCGMP: true, ISOExpiredDate: '', file: null, uploadedFile: null } as const;

const useManuRegisterForm = ({ initialData, handleChangeTab }: UseManuRegisterFormProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState('');
  const [previewedCGMP, setPreviewedCGMP] = useState<CGMP | null>(null);
  const [firstCGMP, setFirstCGMP] = useState<CGMP>(INIT_CGMP);
  const [secondCGMP, setSecondCGMP] = useState<CGMP>(INIT_CGMP);
  const [thirdCGMP, setThirdCGMP] = useState<CGMP>(INIT_CGMP);

  const [form] = Form.useForm<ManuForm>();

  const { data: manufacturers = [] } = useManufacturerCompanies({ isExcludeUserInserted: true });
  const { submitLoading, registerManuCompanySuccess } = useSelector(
    ({ auth, loading }) => ({
      submitLoading: loading['auth/MANU_REGISTER_COMPANY'] || loading['auth/MANU_UPDATE_COMPANY'],
      registerManuCompanySuccess: auth.registerManuCompanySuccess,
    }),
    shallowEqual,
  );

  const forceUpdate = useForceUpdate();
  const { openAlertModal } = useModal();

  const factoryInfos = [
    {
      ko: 'firstAddressKo' as const,
      en: 'firstAddressEn' as const,
      fileInputName: 'firstCGMP',
      cGMP: firstCGMP,
      setCGMP: setFirstCGMP,
    },
    {
      ko: 'secondAddressKo' as const,
      en: 'secondAddressEn' as const,
      fileInputName: 'secondCGMP',
      cGMP: secondCGMP,
      setCGMP: setSecondCGMP,
    },
    {
      ko: 'thirdAddressKo' as const,
      en: 'thirdAddressEn' as const,
      fileInputName: 'thirdCGMP',
      cGMP: thirdCGMP,
      setCGMP: setThirdCGMP,
    },
  ];

  const handleSelectSearch = (value: string): void => {
    setSearchValue(value.replace(/\s/gi, ''));
  };

  const handlePreviewedCGMP = (cGMP: CGMP): void => {
    setPreviewedCGMP(cGMP);
  };

  const handleCGMPModal = (cGMP: CGMP): void => {
    switch (previewedCGMP) {
      case firstCGMP:
        form.validateFields([factoryInfos[0].fileInputName]);
        setFirstCGMP(cGMP);
        break;

      case secondCGMP:
        form.validateFields([factoryInfos[1].fileInputName]);
        setSecondCGMP(cGMP);
        break;

      case thirdCGMP:
        form.validateFields([factoryInfos[2].fileInputName]);
        setThirdCGMP(cGMP);
        break;

      default:
    }

    handleCloseCGMPModal();
  };

  const handleCloseCGMPModal = (): void => {
    setPreviewedCGMP(null);
  };

  const handleClickNext = (): void => {
    form
      .validateFields()
      .then(() => {
        form.setFieldsValue({
          username: form.getFieldValue('bizNumber').replaceAll('-', ''),
        });
        handleChangeTab && handleChangeTab('user');
      })
      .catch((error: any) => {
        form.scrollToField(error.errorFields[0].name[0], scrollToFirstErrorOption);
      });
  };

  const handleClickPrev = (): void => {
    handleChangeTab && handleChangeTab('company');
  };

  const handleInitialData = (): void => {
    if (!initialData) return;

    form.resetFields();
    form.setFieldsValue({
      companyNameKo: initialData.company.companyNameKo,
      companyNameKoDirect: initialData.company.companyNameKoDirect,
      companyNameEn: initialData.company.companyNameEn,
      bizNumber: initialData.company.bizNumber,
      ceoNameKo: initialData.company.ceoNameKo,
      ceoNameEn: initialData.company.ceoNameEn,
      tel: initialData.company.tel,
      fax: initialData.company.fax,
      homepageUrl: initialData.company.homepageUrl,
      bizLicenseFile: { name: 'Uploaded File', url: initialData.company.bizLicenseUrl },
      bizLicenseEnFile: { name: 'Uploaded File', url: initialData.company.bizLicenseEnUrl },
      username: initialData.user.username,
      name: initialData.user.name,
      deptName: initialData.user.deptName,
      positionName: initialData.user.positionName,
      mobile: initialData.user.mobile,
      email: initialData.user.email,
    });

    factoryInfos.forEach((name, i) => {
      const factory = initialData.factories[i];

      if (!factory) return;

      form.setFieldsValue({ [name.en]: factory.addressEn, [name.ko]: factory.addressKo });

      name.setCGMP((prev) => {
        const newValue = {
          isCGMP: !!factory.cgmpUploadFileUrl,
          ISOExpiredDate: factory.iso22716ExpireDate
            ? factory.iso22716ExpireDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')
            : '',
          file: null,
          uploadedFile: {
            url: factory.cgmpUploadFileUrl || factory.iso22716UploadFileUrl,
            name: 'Uploaded File.pdf',
          },
        };

        if (JSON.stringify(prev) === JSON.stringify(newValue)) {
          return prev;
        }

        return newValue;
      });
    });
  };

  const handleCreateManu = (formData: ManuForm): void => {
    const manufacturer = manufacturers.find(
      ({ companyNameKo }) => companyNameKo === formData.companyNameKo,
    );

    const company = {
      companyNameKo:
        formData.companyNameKo === 'companyNameKoDirect'
          ? formData.companyNameKoDirect
          : formData.companyNameKo,
      companyNameEn: formData.companyNameEn,
      ...(manufacturer?.manufacturerId && {
        manufacturerId: manufacturer.manufacturerId,
      }),
      bizNumber: formData.bizNumber,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      addressKo: formData.firstAddressKo,
      addressEn: formData.firstAddressEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }),
      bizLicenseFile: formData.bizLicenseFile,
      bizLicenseEnFile: formData.bizLicenseEnFile,
      companyType: 'MANUFACTURE',
    };

    const user = {
      isSuperUser: true,
      username: formData.bizNumber.replace(/-/g, ''),
      password: formData.password,
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
      userType: 'MANUFACTURE',
    };

    const factories = factoryInfos
      .filter((name) => name.cGMP.file && formData[name.ko] && formData[name.en])
      .map((name, i) => ({
        seq: i + 1,
        addressKo: formData[name.ko],
        addressEn: formData[name.en],
        ...(name.cGMP.isCGMP
          ? { cgmpUploadFile: name.cGMP.file }
          : {
              iso22716UploadFile: name.cGMP.file,
              iso22716ExpireDate: name.cGMP.ISOExpiredDate.replace(/-/g, ''),
            }),
      }));

    dispatch(manuRegisterCompany({ company: { ...company, ...user }, factories }));
  };

  const handleEditManu = (formData: ManuForm): void => {
    if (!initialData) return;

    const company = {
      companyId: initialData.company.companyId,
      ceoNameKo: formData.ceoNameKo,
      ceoNameEn: formData.ceoNameEn,
      tel: formData.tel,
      fax: formData.fax,
      ...(formData.homepageUrl && { homepageUrl: formData.homepageUrl }), // TODO: homapegeUrl -> 빈 문자열, 키 삭제, null 경우 삭제 불가능 API 확인 필요
    };

    const user = {
      userId: initialData.user.userId,
      username: formData.bizNumber.replace(/-/g, ''),
      name: formData.name,
      deptName: formData.deptName,
      positionName: formData.positionName,
      email: formData.email,
      mobile: formData.mobile,
    };

    const factories = factoryInfos
      .map((factory, i) => {
        const defaultFactory = initialData.factories[i];

        if (i === 0) {
          return {
            type: 'MODIFY',
            companyId: defaultFactory.companyId,
            manufacturerId: initialData.user.manufacturerId,
            companyFactoryId: defaultFactory.companyFactoryId,
            addressKo: formData[factory.ko],
            addressEn: formData[factory.en],
            iso22716ExpireDate: factory.cGMP.ISOExpiredDate
              ? factory.cGMP.ISOExpiredDate.replace(/-/g, '')
              : '',
            cgmpUploadFile: factory.cGMP.isCGMP && factory.cGMP.file ? factory.cGMP.file : null,
            cgmpUploadFileUrl: factory.cGMP.file
              ? null
              : factory.cGMP.isCGMP && factory.cGMP.uploadedFile
              ? factory.cGMP.uploadedFile.url
              : null,
            iso22716UploadFile:
              !factory.cGMP.isCGMP && factory.cGMP.file ? factory.cGMP.file : null,
            iso22716UploadFileUrl: factory.cGMP.file
              ? null
              : !factory.cGMP.isCGMP && factory.cGMP.uploadedFile
              ? factory.cGMP.uploadedFile.url
              : null,
            seq: i + 1,
          };
        } else {
          if (defaultFactory) {
            return {
              type: 'MODIFY',
              companyId: defaultFactory.companyId,
              manufacturerId: initialData.user.manufacturerId,
              companyFactoryId: defaultFactory.companyFactoryId,
              addressKo: formData[factory.ko],
              addressEn: formData[factory.en],
              iso22716ExpireDate: factory.cGMP.ISOExpiredDate
                ? factory.cGMP.ISOExpiredDate.replace(/-/g, '')
                : '',
              cgmpUploadFile: factory.cGMP.isCGMP && factory.cGMP.file ? factory.cGMP.file : null,
              cgmpUploadFileUrl: factory.cGMP.file
                ? null
                : factory.cGMP.isCGMP
                ? factory.cGMP.uploadedFile && factory.cGMP.uploadedFile.url
                : null,
              iso22716UploadFile:
                !factory.cGMP.isCGMP && factory.cGMP.file ? factory.cGMP.file : null,
              iso22716UploadFileUrl: factory.cGMP.file
                ? null
                : !factory.cGMP.isCGMP
                ? factory.cGMP.uploadedFile && factory.cGMP.uploadedFile.url
                : null,
              seq: i + 1,
            };
          } else {
            if (!formData[factory.en] || !formData[factory.ko] || !factory.cGMP.file) return;

            return {
              type: 'ADD',
              companyId: initialData.user.companyId,
              manufacturerId: initialData.user.manufacturerId,
              addressKo: formData[factory.ko],
              addressEn: formData[factory.en],
              cgmpUploadFile: factory.cGMP.isCGMP ? factory.cGMP.file : null,
              iso22716UploadFile: !factory.cGMP.isCGMP ? factory.cGMP.file : null,
              iso22716ExpireDate: factory.cGMP.ISOExpiredDate
                ? factory.cGMP.ISOExpiredDate.replace(/-/g, '')
                : '',
              seq: i + 1,
            };
          }
        }
      })
      .filter(Boolean);

    const updateFormData = { company, user, factories, companyId: company.companyId };

    openAlertModal({
      content: (
        <Typography.Text style={{ fontSize: '14px' }}>정말로 수정하시겠습니까?</Typography.Text>
      ),
      onOk: () => {
        dispatch(manuUpdateCompany(updateFormData));
      },
    });
  };

  useEffect(() => {
    form.validateFields([factoryInfos[0].fileInputName]);
  }, [firstCGMP]);

  useEffect(() => {
    form.validateFields([factoryInfos[1].fileInputName]);
  }, [secondCGMP]);

  useEffect(() => {
    form.validateFields([factoryInfos[2].fileInputName]);
  }, [thirdCGMP]);

  useEffect(() => {
    handleInitialData();
  }, [initialData]);

  useEffect(() => {
    if (registerManuCompanySuccess) {
      navigate(path.register.success);
    }
  }, [registerManuCompanySuccess]);

  return {
    factoryInfos,
    form,
    previewedCGMP,
    forceUpdate,
    searchValue,
    submitLoading,
    handleSelectSearch,
    handlePreviewedCGMP,
    handleCGMPModal,
    handleCloseCGMPModal,
    handleClickNext,
    handleClickPrev,
    handleInitialData,
    handleCreateManu,
    handleEditManu,
  };
};

export default useManuRegisterForm;
