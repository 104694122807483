import { Button, message, Modal, Radio, Form } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import FileUploadContainer from 'containers/file/FileUploadContainer';
import FooterBox from 'components/FooterBox';
import { normalizeDate, requireRule, noPastDateRule, checkIsFutureDate } from 'lib/validate';
import { messages } from 'lib/consts';
import Typography from 'components/system/general/Typography';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import type { CGMP } from 'components/auth/manu/ManuRegisterCreateForm';

interface CGMPModalProps {
  cGMP: CGMP | null;
  onOk: (cGMP: CGMP) => void;
  onClose: () => void;
}

const CGMPModal = ({ cGMP, onOk, onClose }: CGMPModalProps) => {
  const [file, setFile] = useState<any>(null);
  const [form] = Form.useForm();

  const onUpload = (file: any) => {
    setFile(file);
    return true;
  };

  const onDelete = () => {
    setFile(null);
    return true;
  };

  const onSubmit = ({ isCGMP, ISOExpiredDate }: { isCGMP: boolean; ISOExpiredDate: string }) => {
    if (!file) {
      return message.warning(messages.REQUIRED_FILE);
    }

    onOk({ isCGMP, ISOExpiredDate, file, uploadedFile: null });
    setFile(null);
  };

  useEffect(() => {
    if (cGMP) {
      const { isCGMP, ISOExpiredDate, file, uploadedFile } = cGMP;

      if (file || uploadedFile) {
        setFile(file || uploadedFile);
      }

      form.setFieldsValue({ isCGMP, ISOExpiredDate });
    }
  }, [cGMP]);

  return (
    <Modal
      open={cGMP !== null}
      onCancel={onClose}
      footer={null}
      width={880}
      closable={false}
      destroyOnClose
      styles={{ body: { padding: 32 } }}
    >
      <Typography.Text gutter={{ bottom: 28 }}>
        cGMP 혹은 ISO22716 를 선택하여 서류를 첨부해 주세요. (cGMP 또는 ISO22716 선택 시 유효기간을
        입력해 주세요.)
      </Typography.Text>
      <Form
        form={form}
        layout="inline"
        colon={false}
        style={{ justifyContent: 'center', alignItems: 'center' }}
        onFinish={onSubmit}
      >
        <Form.Item name="isCGMP">
          <Radio.Group
            style={{ display: 'flex', justifyContent: 'center' }}
            onChange={(e) => {
              if (e.target.value && form.getFieldValue('ISOExpiredDate')) {
                form.setFieldsValue({ ISOExpiredDate: '' });
              }
            }}
          >
            <Radio value={true}>cGMP</Radio>
            <Radio value={false}>ISO22716</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item noStyle shouldUpdate={(prev, next) => prev.isCGMP !== next.isCGMP}>
          {({ getFieldValue }) => {
            const hasCGMP = !!getFieldValue('isCGMP');

            return (
              <StyledFormItem
                label={<Typography.Text type="BODY_2">유효기간</Typography.Text>}
                name="ISOExpiredDate"
                normalize={normalizeDate}
                required={false}
                rules={[noPastDateRule, ...(!hasCGMP ? [requireRule] : [])]}
              >
                <DatePicker disabled={hasCGMP} checkIsValidDate={checkIsFutureDate} />
              </StyledFormItem>
            );
          }}
        </Form.Item>
      </Form>
      <FileUploadContainer
        files={file}
        onUpload={onUpload}
        onDelete={onDelete}
        viewerVisible
        style={{ marginTop: '24px' }}
      />
      <FooterBox style={{ marginTop: 32 }} modal>
        <Button
          onClick={() => {
            setFile(null);
            onClose();
          }}
        >
          취소
        </Button>
        <Button type="primary" onClick={form.submit}>
          첨부하기
        </Button>
      </FooterBox>
    </Modal>
  );
};

export default CGMPModal;

const StyledFormItem = styled(Form.Item)`
  height: 44px;

  .ant-form-item-control-input {
    min-height: 0;
  }

  .ant-form-item-label > label {
    height: 44px;
  }
`;
