import { useEffect, useState } from 'react';
import SockJS from 'sockjs-client';
import StompJS from 'stompjs';

import { getReactEnvVar } from 'lib/common';

const useScreeningSocket = ({ id }: { id?: string }) => {
  const [screeningStatus, setScreeningStatus] = useState<string>();
  useEffect(() => {
    if (!id || id === 'undefined') return;
    let client: StompJS.Client | null = null;

    const initSocket = () => {
      const socket = new SockJS(`${getReactEnvVar('SERVER_URL')}/api/v1/socket/connection`);
      client = StompJS.over(socket);

      if (process.env.NODE_ENV !== 'development') {
        client.debug = () => undefined;
      }

      client.connect({}, () => {
        if (!client) return;
        client.subscribe(`/api/v1/socket/subscribe/${id}/complete`, ({ body }) => {
          const { status } = JSON.parse(body);
          setScreeningStatus(status);
        });
      });
    };

    initSocket();

    return () => {
      if (client) {
        client.disconnect(() => {});
        client = null;
      }
      setScreeningStatus(undefined);
    };
  }, [id]);

  return screeningStatus;
};
export default useScreeningSocket;
