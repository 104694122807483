import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import Tags from 'components/ui/Tags';
import path from 'lib/path';
import { useCountryCodeToNameMap } from 'service/country';

import PageTemplate from 'templates/PageTemplate';
import { PerformaceMeasurementItem } from 'types/brand/artworkScreening/artworkScreening';
import {
  usePerformaceMeasurementList,
  usePerformanceMeasurement,
} from 'service/brand/artworkScreening/artworkScreening';
import { useModal } from 'hook/useModal';

const PerformanceMeasurementList = ({
  token,
  handleGoStep,
}: {
  token?: string | null;
  handleGoStep: (step: number) => void;
}) => {
  const navigate = useNavigate();
  const { performaceMeasurementList, performaceMeasurementListLoading, downloadFile } =
    usePerformaceMeasurementList(token);
  const countryCodeToNameMap = useCountryCodeToNameMap();
  const { openAlertModal } = useModal();
  const { startPerformanceMeasurement } = usePerformanceMeasurement();

  const columns = useMemo<ColumnsType<PerformaceMeasurementItem>>(
    () => [
      {
        title: 'No.',
        align: 'center',
        width: '5.3%',
        render: (_, __, index) => (
          <div style={{ wordWrap: 'break-word', wordBreak: 'break-word' }}>{index + 1}</div>
        ),
      },
      {
        title: '파일명',
        align: 'center',
        width: '53.8%',
        render: (_, { fileName }) => (
          <Typography.Text type="BODY_2" color="GRAY70" medium>
            {fileName}
          </Typography.Text>
        ),
      },
      {
        title: '파일 다운로드',
        width: '10%',
        align: 'center',
        render: (_, { formulaScreeningId }) => (
          <Flex justify="center">
            <Icon
              name="download"
              size={16}
              color="GRAY50"
              onClick={() => downloadFile(formulaScreeningId)}
            />
          </Flex>
        ),
      },
      {
        title: '선택 국가',
        width: '19.2%',
        align: 'center',
        render: (_, { countryCodes }) => (
          <Typography.Text type="BODY_2" color="GRAY70">
            {countryCodes
              .map((code) => countryCodeToNameMap[code])
              .sort((a, b) => (a as string).localeCompare(b))
              .join(', ') || '-'}
          </Typography.Text>
        ),
      },

      {
        title: '상태',
        width: '11.5%',
        align: 'center',
        onCell: () => ({
          onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
            e.stopPropagation();
          },
          style: {
            cursor: 'default',
          },
        }),
        render: (_, { status, formulaScreeningId }) => (
          <Tags.Mark
            type={status === 'DONE' ? 'state' : 'arrow'}
            style={{ cursor: status === 'DONE' ? 'default' : 'pointer' }}
            color={status === 'DONE' ? 'lightGray' : 'blue'}
            onClick={() => {
              if (status === 'READY') {
                openAlertModal({
                  content: `1차 측정한 파일을 다운로드 받아 준비해 주세요.\n[2차 측정하기] 버튼을 누르면 측정 시간이 카운팅됩니다.\n2차 측정을 하시겠습니까?`,
                  onOk: () => {
                    startPerformanceMeasurement(
                      {
                        formulaScreeningId,
                        measurementMethod: 'A',
                      },
                      {
                        onSuccess: () => {
                          handleGoStep(5);

                          navigate(
                            `${path.artworkScreening}/${formulaScreeningId}${
                              token
                                ? `?source=admin&isPerformanceMeasurement=true&measurementType=A&adminToken=${token}`
                                : ''
                            }`,
                          );
                        },
                      },
                    );
                  },
                  okText: '2차 측정하기',
                });
              }
              return;
            }}
          >
            {status === 'DONE' ? '측정 완료' : '2차 측정'}
          </Tags.Mark>
        ),
      },
    ],
    [performaceMeasurementList],
  );

  return (
    <Container>
      <PageTemplate
        title="전성분 성과 측정"
        description="논문용 성과 측정을 위한 페이지로 아트웍 스크리닝을 통해 대조군, 시험군을 비교해 보세요."
        back={false}
      >
        <Table
          rowKey="formulaScreeningId"
          columns={columns}
          pagination={false}
          dataSource={performaceMeasurementList}
          loading={performaceMeasurementListLoading}
        />
      </PageTemplate>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  flex-direction: column;
  max-width: 1040px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 80px;
`;

export default PerformanceMeasurementList;
