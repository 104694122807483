import { LanguageCode } from 'service/brand/formulaScreening/formulaScreening';

export enum ScreeningStatus {
  INPUT_INGREDIENT = 'INPUT_INGREDIENT', // 성분표 입력 완료
  INPUT_ARTWORK = 'INPUT_ARTWORK', // 입력 완료
  ANALYZE = 'ANALYZE', // 전성분 스크리닝 분석 완료
  COMPLETE = 'COMPLETE', // 전성분 스크리닝 완료
}

export enum InquiryErrorType {
  OCR = 'OCR', // OCR 문자 인식 오류
  MERGER = 'MERGER', // 전성분 병합 오류
  CLASSIFICATION = 'CLASSIFICATION', // 텍스트 분류 오류
  OTHER = 'OTHER', // 기타
  MANDATORY = 'MANDATORY', //필수 기재
}

export enum RuleType {
  TYPO = 'TYPO',
  MISS = 'MISS',
  NOT_MATCH = 'NOT_MATCH',
  ORDER = 'ORDER',
  CHECK = 'CHECK',
}

export enum RuleCode {
  FCAR01 = 'FCAR01', // 중복 표기 불가
  FCAR02 = 'FCAR02', // 올바른 성분명으로 표기
  FCAR03 = 'FCAR03', // ARTWORK 전성분에 존재하지 않음
  FCAR04 = 'FCAR04', // 전성분표에 존재하지 않음
  FCAR05 = 'FCAR05', // 색소 성분 표기법 확인
  FCAR06 = 'FCAR06', // 국가 표기법으로 사용
  FCAR07 = 'FCAR07', // 함량 순으로 표기
  FCAR08 = 'FCAR08', //괄호 안의 표기 맞는지 확인 필요함
  FCAR09 = 'FCAR09', //ARTWORK 전성분에 미기입됨
  FCAR10 = 'FCAR10', //전성분표에 없는 성분으로 삭제되어야 함
  FCAR11 = 'FCA011', //알레르기 유발성분은 아트웍 전성분 마지막에 기재해야함
  FCAR12 = 'FCR012', //색소 성분 표기법 확인
}

export enum RuleSubCode {
  FCAR01_01 = 'FCAR01_01', // 중복 표기 불가
  FCAR02_01 = 'FCAR02_01', // 올바른 성분명으로 표기
  FCAR03_01 = 'FCAR03_01', // ARTWORK 전성분에 존재하지 않음
  FCAR04_01 = 'FCAR04_01', // 전성분표에 존재하지 않음
  FCAR05_01 = 'FCAR05_01', // 일반 명칭이 아닌 CI 표기법으로 사용
  FCAR05_02 = 'FCAR05_02', // CI 표기법이 아닌 일반 명칭으로 사용
  FCAR06_01 = 'FCAR06_01', // 국가 표기법으로 사용
  FCAR06_02 = 'FCAR06_02', // 국가 표기법으로 사용
  FCAR07_01 = 'FCAR07_01', // 함량이 많은 순으로 기재, 1% 미만 부터는 순서에 관계 없음
  FCAR07_02 = 'FCAR07_02', // 함량이 많은 순으로 기재, 1% 이하 부터는 순서에 관계 없음
  FCAR08_01 = 'FCAR08_01',
  FCAR09_01 = 'FCAR09_01',
  FCAR10_01 = 'FCAR10_01',
  FCAR11_01 = 'FCAR11_01',
  FCAR12_01 = 'FCAR12_01',
}

export interface ArtworkScreeningAllergen {
  ingredientAllergenId: number;
  allergenInciName: string;
  allergenNameKo: string;
}

export type ArtworkScreeningAllergenWithWt = ArtworkScreeningAllergen & {
  allergenWt: string;
};

export interface FormulaArtworkScreeningJson {
  formulaScreeningId?: number;
  ingredients: {
    name: string;
    ingredientWt: string;
    allergenList: {
      ingredientAllergenId: number;
      name: string;
      allergenWt: string;
    }[];
  }[];
}

export interface FormulaArtworkScreeningItem {
  formulaScreeningId: number;
  isWtMax: boolean;
  list: FormulaArtworkScreeningItemList[];
  allergenList: {
    ingredientAllergenId: number;
    ingredientInciName: string;
    allergenProductTotalWt: string;
  }[];
}

export interface FormulaArtworkScreeningItemList {
  ingredientId: number;
  ingredientName: string;
  ingredientWt: string;
  isAllergen: boolean;
  isNameValid: boolean;
  allergenRawList: {
    ingredientAllergenId: number;
    ingredientInciName: string;
    allergenWt: string;
  }[];
}

export interface ArtworkScreeningResult {
  availableCountryCodes: string[];
  artworkImage: ArtworkImage;
  rules: Rule[];
  analyzeResult: AnalyzeResultListItem[];
}

export interface ArtworkImage {
  formulaScreeningArtworkImageId: number;
  url: string;
  filename: string;
  pointX1: number;
  pointY1: number;
  pointX2: number;
  pointY2: number;
}

export interface Rule {
  ruleType: RuleType;
  ruleCode: RuleCode;
  countryRules: CountryRule[] | null;
}

export interface CountryRule {
  countryCode: string;
  ruleSubCode: RuleSubCode;
}

export interface AnalysisAllergenItem {
  allergenProductTotalWt: string;
  ingredientAllergenId: number;
  ingredientInciName: string;
}

export interface AnalyzeResultListItem {
  formulaScreeningAnalyzeItemId: number;
  ingredient: Ingredient | null; // 전성분표 성분 정보
  imageIngredient: ImageIngredient | null; // 전성분 이미지의 성분 리스트
  analysisItems: Record<string, AnalysisItem[]> | null;
  allergen: AnalysisAllergenItem | null;
}

export interface Ingredient {
  formulaScreeningIngredientId: number;
  formulaScreeningId: number;
  ingredientId: number | null;
  ingredientName: string;
  formulaWt: string;
  casNo: string | null;
}

export interface PreIngredient {
  formulaScreeningArtworkImageIngredientId: number | null;
  ingredientId: number | null;
  ingredientName: string;
  additionalIngredientName: string | null;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  coordinates?: Coordinate[];
  isTitle: boolean;
}

export interface ImageIngredient {
  formulaScreeningArtworkImageIngredientId: number;
  ingredientId: number | null;
  ingredientName: string;
  additionalIngredientName: string | null;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  coordinates?: Coordinate[];
  isTitle: boolean;
}

export interface Coordinate {
  formulaScreeningArtworkImageIngredientCoordinateId: number;
  points: Point[];
}

export interface Point {
  formulaScreeningArtworkImageIngredientCoordinatePointId: number;
  pointX: number;
  pointY: number;
}

export interface AnalysisItem {
  ruleCode: RuleCode;
  countryRules: CountryRules;
  preIngredient:
    | (PreIngredient & { subCodes?: RuleSubCode[] })[]
    | (PreIngredient & { subCodes?: RuleSubCode[] })
    | null;
  pre?: ImageIngredient | null;
  post?: {
    formulaScreeningArtworkImageIngredientId: number;
    ingredientId: number | null;
    ingredientName: string;
    additionalIngredientName: string | null;
    additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  } | null;
  postIngredient: PostIngredient[] | PostIngredient | null;
}

export interface CountryRules {
  [key: string]: {
    formulaScreeningAnalyzeRuleSubId: number;
    description: string;
    subCode: RuleSubCode;
  }[];
}

export interface PostIngredientListItem {
  ruleSubCode: RuleSubCode[];
  updateIngredient: UpdateIngredient[];
  description: string;
}

export interface UpdateIngredient {
  formulaScreeningArtworkImageIngredientId: number | null;
  formulaScreeningArtworkIngredientId: number | null;
  ingredientName: string;
  additionalIngredientName?: string | null;
  additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
}

export interface PostIngredient {
  ruleSubCode: RuleSubCode[];
  description: string;
  pre: ImageIngredient | null;
  post: {
    formulaScreeningArtworkImageIngredientId: number;
    ingredientId: number | null;
    ingredientName: string;
    additionalIngredientName: string | null;
    additionalIngredientNameSeparator: 'SLASH' | 'PARENTHESIS' | null;
  } | null;
  updateIngredient: UpdateIngredient[] | null;
}

export interface IngredientCoordinate {
  formulaScreeningArtworkImageIngredientCoordinateId: number;
  points: {
    formulaScreeningArtworkImageIngredientCoordinatePointId: number;
    pointX: number;
    pointY: number;
  }[];
}

export interface ArtworkIngredient {
  id: number;
  name: string;
  initialOrder: number;
  coordinates: Coordinate[];
  imageIngredient: ImageIngredient | null;
  errors: ArtworkIngredientError[];
  updatedName: string;
  newDirectName: Record<RuleType, string>;
  nameExceptAdditional: string;
  additionalIngredientName: string;
  order: number;
  preChildren: ArtworkIngredient[];
  postChildren: ArtworkIngredient[];
  allergen: AnalysisAllergenItem | null;
  currUpdatedRuleType?: RuleType;
}

export interface ArtworkIngredientError {
  ruleType: RuleType;
  ruleCode: RuleCode;
  preIngredient: ArtworkPreIngredient;
  postIngredient: ArtworkPostIngredient | null;
  descriptions: string[];
  countryRules: {
    countryCodes: string[];
    descriptions: string[];
  }[];
  isDuplicate: boolean;
  isUpdate: boolean;
}

export interface ArtworkPreIngredient {
  pre: { id: number; name: string };
  post: { id: number; name: string };
  center: {
    id: number;
    name: string;
  }[];
}
export interface ArtworkPostIngredient extends ArtworkPreIngredient {}

export enum PackingType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  BOTH = 'BOTH',
}

export interface MandatoryCoordinate {
  pointX1: number;
  pointY1: number;
  pointX2: number;
  pointY2: number;
}

export enum GuideStatus {
  ENTRY_GUIDE = 'ENTRY_GUIDE', //확인 불가
  REVIEW_GUIDE = 'REVIEW_GUIDE', //기재 완료_BLUE
}

export interface CategoryItem {
  field: {
    mandatoryFieldCodeId: number;
    fieldCode: string;
    name: string;
  };
  contents: {
    isFunctional: boolean;
    packingType: PackingType;
    aiAnalyses: {
      formulaScreeningMandatoryFieldId: number;
      fieldText: string;
      languageCode: LanguageCode;
      coordinates: MandatoryCoordinate[];
    }[];
    message: {
      mandatoryFieldCodeMessageId: number;
      fieldCodeSub: string;
      countryCodes: string[];
      guideStatus: GuideStatus;
      message: string;
    };
  }[];
}

export interface MandatoryFieldsResult {
  mandatoryFieldAnalysisResultId: number;
  analysisResult: {
    images: {
      packingType: PackingType;
      imageUrl: string;
    }[];
    fields: {
      field: {
        mandatoryFieldCodeId: number;
        fieldCode: string;
        name: string;
      };
      categories: CategoryItem[];
    }[];
  };
}

export interface PerformaceMeasurementItem {
  formulaScreeningId: number;
  fileName: string;
  countryCodes: string[];
  status: 'READY' | 'DONE';
}
