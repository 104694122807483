import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import MandatoryArtworkImage from './MandatoryArtworkImage';
import Icon from 'components/ui/Icon/Icon';
import { Flex } from 'components/ui';
import MandatoryList from './MandatoryList';
import {
  GuideStatus,
  MandatoryCoordinate,
  MandatoryFieldsResult,
  PackingType,
} from 'types/brand/artworkScreening/artworkScreening';
import { useModal } from 'hook/useModal';

export interface FilteredMandatoryResult {
  images?:
    | {
        packingType: PackingType;
        imageUrl: string;
      }[];
  fields?: {
    fieldName: string;
    fieldCode: string;
    mandatoryFieldCodeId: number;
    categories: Record<
      string,
      {
        isFunctional: boolean;
        packingType: PackingType;
        coordinates: MandatoryCoordinate[];
        messages: string[];
        guideStatus: GuideStatus;
        countryCodes: string[];
      }[]
    >;
  }[];
}

const ResultContainer = ({
  src,
  result,
  clickedFieldId,
  setClickedFieldId,
  fieldCoordinates,
  setfieldCoordinates,
  selectedCountryCodes,
}: {
  src?: string;
  result: FilteredMandatoryResult;
  clickedFieldId?: number;
  setClickedFieldId: React.Dispatch<React.SetStateAction<number | undefined>>;
  fieldCoordinates: MandatoryCoordinate[];
  setfieldCoordinates: React.Dispatch<React.SetStateAction<MandatoryCoordinate[]>>;
  selectedCountryCodes: string[];
}) => {
  return (
    <>
      <Flex gap={16}>
        <MandatoryArtworkImage url={src} fieldCoordinates={fieldCoordinates} />
        <MandatoryList
          result={result}
          clickedFieldId={clickedFieldId}
          setClickedFieldId={setClickedFieldId}
          setfieldCoordinates={setfieldCoordinates}
          selectedCountryCodes={selectedCountryCodes}
        />
      </Flex>
    </>
  );
};

const FormulaScreeningMandatory = ({
  artworkMandatoryResult,
  setStep,
  selectedCountryCodes,
}: {
  artworkMandatoryResult: MandatoryFieldsResult | null;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  selectedCountryCodes: string[];
}) => {
  const [fieldCoordinates, setfieldCoordinates] = useState<MandatoryCoordinate[]>([]);
  const [clickedFieldId, setClickedFieldId] = useState<number>();
  const [tabKey, setTabKey] = useState<PackingType>();
  const { openAlertModal } = useModal();

  const filteredResult: FilteredMandatoryResult = useMemo(() => {
    if (!artworkMandatoryResult) return {};

    const { analysisResult } = artworkMandatoryResult;

    return {
      images: analysisResult.images,
      fields: analysisResult.fields.map((item) => {
        const groupedCategories = item.categories
          .flatMap((category) => category.contents)
          .reduce(
            (acc, content) => {
              const guideStatus = content?.message?.guideStatus || GuideStatus.ENTRY_GUIDE;
              const { packingType, aiAnalyses, message, isFunctional } = content;

              if (packingType !== tabKey) return acc;

              if (!acc[guideStatus]) {
                acc[guideStatus] = [];
              }
              const countryCodesKey = (message?.countryCodes || []).sort().join(',');
              let packingGroup = acc[guideStatus].find(
                (group) =>
                  group?.packingType === packingType &&
                  group?.countryCodes.sort().join(',') === countryCodesKey,
              );

              if (!packingGroup) {
                packingGroup = {
                  packingType,
                  coordinates: [],
                  messages: [],
                  guideStatus,
                  countryCodes: [...(message?.countryCodes || [])],
                  isFunctional,
                };
                acc[guideStatus].push(packingGroup);
              }

              const newCoordinates = aiAnalyses.flatMap((analysis) => analysis.coordinates);
              packingGroup.coordinates.push(...newCoordinates);
              if (message?.message) packingGroup.messages.push(message?.message);

              return acc;
            },
            {} as Record<
              GuideStatus,
              {
                packingType: PackingType;
                coordinates: MandatoryCoordinate[];
                messages: string[];
                guideStatus: GuideStatus;
                countryCodes: string[];
                isFunctional: boolean;
              }[]
            >,
          );
        return {
          fieldName: item.field.name,
          fieldCode: item.field.fieldCode,
          mandatoryFieldCodeId: item.field.mandatoryFieldCodeId,
          categories: groupedCategories,
        };
      }),
    };
  }, [artworkMandatoryResult, tabKey]);

  useEffect(() => {
    setfieldCoordinates([]);
  }, [tabKey]);

  useEffect(() => {
    setTabKey(
      filteredResult.images?.find((item) => item.packingType)?.packingType || PackingType.SECONDARY,
    );
  }, []);

  const handleSelectTabKey = (key: PackingType) => {
    if (!filteredResult.images?.find(({ packingType }) => packingType === key)) {
      openAlertModal({
        width: 400,
        content: `${
          key === PackingType.PRIMARY ? '용기' : '단상자'
        } 포장 이미지가 업로드 되지 않았습니다.`,
        okText: '확인',
        noCancelButton: true,
      });
      return;
    }
    setTabKey(key);
  };

  return (
    <Container>
      <StyledTabs>
        <Tab
          selected={tabKey === PackingType.SECONDARY}
          onClick={() => handleSelectTabKey(PackingType.SECONDARY)}
        >
          <Typography.Text
            type="BODY_1"
            color={tabKey === PackingType.SECONDARY ? 'GRAY90' : 'GRAY40'}
          >
            단상자 포장
          </Typography.Text>
        </Tab>
        <Tab
          selected={tabKey === PackingType.PRIMARY}
          onClick={() => handleSelectTabKey(PackingType.PRIMARY)}
        >
          <Typography.Text
            type="BODY_1"
            color={tabKey === PackingType.PRIMARY ? 'GRAY90' : 'GRAY40'}
          >
            용기 포장
          </Typography.Text>
        </Tab>
      </StyledTabs>
      <ResultContainer
        src={filteredResult.images?.find(({ packingType }) => packingType === tabKey)?.imageUrl}
        result={filteredResult}
        fieldCoordinates={fieldCoordinates}
        setfieldCoordinates={setfieldCoordinates}
        clickedFieldId={clickedFieldId}
        setClickedFieldId={setClickedFieldId}
        selectedCountryCodes={selectedCountryCodes}
      />
    </Container>
  );
};

const Container = styled.section`
  border-top: 1px solid ${palette.GRAY30};
  padding-top: 32px;
  margin-top: 1px solid ${palette.GRAY30};
`;

const StyledTabs = styled(Flex)`
  align-items: center;
`;
const Tab = styled.div<{ selected: boolean }>`
  cursor: pointer;
  border-bottom: ${({ selected }) =>
    selected ? `2px solid ${palette.GRAY90}` : `2px solid ${palette.ETC_WHITE}`};

  margin: 0 12px;
  transition: all 200ms ease;
  span {
    font-weight: ${({ selected }) => (selected ? 500 : 400)};
    line-height: 24px;
    margin-bottom: 8px;
  }
`;

const Divider = styled(Flex)`
  flex: 0 0 40px;
  align-self: stretch;
  margin-top: 28px;
`;

const VerticalLine = styled.div`
  flex: 1;
  width: 1px;
  background-color: ${palette.GRAY30};
`;

const FrontIconWrapper = styled(Flex)`
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${palette.GRAY40};
  align-items: center;
`;

export default FormulaScreeningMandatory;
