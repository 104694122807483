//

import { AxiosRequestConfig, AxiosResponse } from 'axios';
import QueryString from 'qs';
import { useState } from 'react';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from 'react-query';

import client from 'lib/api/client';
import ingredientClient from 'lib/api/ingredientClient';
import { createFormData } from 'lib/file';
import { APIPageableResponse, APIPageableResult, APIResponse } from 'types/common';

export const apiUrls = {
  serviceUseGuide: `/pub/use-guides`,
  serviceUseGuideDetail: `/pub/use-guides/:useGuideId`,
  productDetail: `/v2/products/:productId`,
  productArtworks: `/v1/products/:productId/product-artworks`,
  productArtworkCountries: `/v1/products/:productId/product-artwork-countries`,
  productInformation: '/v1/products/:productId/product-informations',
  productEfficacyChina: '/v1/products/:productId/product-efficacy-chinas',
  productMaterialData: '/v1/product-material-datas',
  productQuarantineSamples: '/v1/products/:productId/product-quarantine-samples',
  updateProductQuarantineSamples:
    '/v1/products/:productId/product-quarantine-samples/:productQuarantineSampleId',
  productConsignmentProcessingAgreements:
    '/v1/products/:productId/product-consignment-processing-agreements',
  productEfficacyChinaEvaluations: '/v1/products/:productId/product-efficacy-chinas/evaluations',
  productEtcDatas: '/v1/products/:productId/etc-datas',
  productArtworkScreeningPages: '/v1/products/:productId/artwork-screening-pages',
  productArtworkScreeningCorrections: `/v1/products/:productId/artwork-screening-corrections`,
  productArtworkScreeningStatus: `/v1/products/:productId/screening-completion-status`,
  productAnnex14Data: `/v1/products/:productId/annex-14s`,
  productManufacturerInfo: `/v1/products/:productId/manufacturer-infos`,
  fdaAccount: `/v1/fda-accounts`,
  updateFdaAccount: `/v1/fda-accounts/:fdaAccountId`,
  productFDAAccount: `/products/:productId/countries/:countryId/fda-accounts`,
  myRP: `/v1/responsible-persons`,
  myRPDetail: `/v1/responsible-persons/:responsiblePersonId`,
  usingProductRP: `/v1/responsible-persons/using-products`,
  productRPs: `/products/:productId/responsible-person-infos`,
  updateProductAnnex14Data: `/v1/products/:productId/annex-14s/update`,
  formulaScreeningDistributors: `/v1/distributors`,
  formulaScreeningSpecialRegions: `/v1/special-regions`,
  estimateServiceTypes: '/pub/estimate-service-types',
  addEstimateInquiry: '/pub/estimate-inquiries',
  selfEstimateTargets: '/v1/self-estimate-targets',
  simpleProducts: '/v2/simple-products',
  canSelfEstimateCountries: '/v2/products/:productId/can-self-estimate-countries',
  qcqaProductList: '/v2/qcqa/products',
  deleteQcqaProduct: '/v2/qcqa/products',
  qcqaYearlyLotCount: '/v2/qcqa/products/:qcqaProductId/year-lotno-count',
  qcqaLotList: '/v2/qcqa/products/:qcqaProductId/lotnos',
  qcqaLotBasic: '/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId',
  qcqaProductLots: '/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId',
  createQcqaProduct: '/v2/qcqa/products',
  createQCQALot: '/v2/qcqa/products/:qcqaProductId/lotnos',
  qcqaCompanyDocsHistory: `/v2/qcqa/common-documents/:qcqaUserDocumentId/document-records`,
  qcqaProductsWithDocsHistory: `/v2/qcqa/common-documents/:qcqaUserDocumentId/document-records/:qcqaRelationDocumentRecordId/products`,
  qcqaProductsWithDocs: `/v2/qcqa/common-documents/:qcqaUserDocumentId/products`,
  qcqaLotDocsHistory: `/v2/qcqa/products/:qcqaProductId/common-documents/:qcqaUserDocumentId/document-records`,
  updateQCQALotDocsHistory: `/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId/document-records`,
  deleteQCQALotDocsHistory:
    '/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId/document-records/:qcqaProductLotnoRecordId',
  updateQcqaProduct: '/v2/qcqa/products/:qcqaProductId/update',
  getQcqaProductDocs: '/v2/qcqa/products/:qcqaProductId/common-documents',
  getQcqaLotDocs: '/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId',
  getQcqaLatestLotDocs: '/v2/qcqa/products/:qcqaProductId/lotnos/latest',
  qcqaLotDocumentList: '/v2/qcqa/products/:qcqaProductId/lotnos/common-documents',
  qcqaLotAddDocumentAttach:
    '/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId/document-attaches',
  qcqaLotDeleteDocumentAttach:
    '/v2/qcqa/products/:qcqaProductId/lotnos/:qcqaProductLotnoId/document-attaches/:qcqaProductLotnoAttachId',
  uploadBrandFiles: '/v1/brand/attaches',
  uploadFiles: '/v1/files',
  deleteFiles: '/v1/files',
  getQCQAProduct: '/v2/qcqa/products/:qcqaProductId',
  qcSimpleDetail: '/v2/qcqa/products/:qcqaProductId/simple-details',
  qcqaFormulaBreakdownsValid: '/v2/qcqa/products/formula-breakdowns/valid',
  qcqaFormulaBreakdowns:
    '/v2/qcqa/products/:qcqaProductDetailId/document-records/:qcqaRelationDocumentRecordId/formula-breakdowns',
  serverDateTimeNow: '/v1/now',
  qcqaDuplicateCheck: '/v2/qcqa/products/duplication',
  qcqaProductOutputs: '/v2/qcqa/products/outputs',
  qcqaProductOutputExcel: '/v2/qcqa/products/outputs/excel',
  getNMPADocStatusId:
    '/v1/material/nmpa/basic-infos/:nmpaBasicInfoId/:documentCode/get-doc-stuats-id',
  nmpaChatMessages: '/v1/material/nmpa/doc/threads/ongoing-thread',
  nmpaCorrectRequestChat: '/v1/material/nmpa/doc/threads/:materialNmpaDocThreadId/messages',
  nmpaCorrectRequestWithNoUpdate:
    '/v1/material/nmpa/basic-infos/:materialNmpaBasicInfoId/:documentCode/correct',
  nmpaOverwriteHistory:
    '/v1/material/nmpa/basic-infos/:nmpaBasicInfoId/:documentCode/overwrite-history',
  myCompanyDocsList: '/v2/qcqa/common-documents',
  productChatMessages: '/v1/product/doc/threads/ongoing-thread',
  productCorrectRequestChat: '/v1/product/doc/threads/:productDocThreadId/messages',
  productCorrectRequestWithoutChange: '/v1/products/:productId/correct-without-change',
  productETCCertifications: '/v1/products/:productId/etc-certifications',
  productProviderForms: '/v1/products/:productId/countries/:countryId/provider-forms',
  productCFSFile: '/files/cfs/products/:productId/pdf',
  manufacturers: '/v1/companies/manufacturers',
  manufacturerDetail: '/v1/companies/manufacturers/:companyManufacturerId',
  getQCQAProductsByManufacturers: '/v1/companies/manufacturers/qcqa-products',
  ingredientMaterialCount: '/v1/ingredient-dictionary/ingredients/material-count',
  formulaArtworkScreening: '/v2/formula-screenings/ingredients',
  formulaArtworkScreeningStatus: '/v1/formula-screenings/:formulaScreeningId',
  formulaScreeningArtworkFile: '/v2/formula-screenings/:formulaScreeningId/artworks',
  originformulaScreeningArtworkFile: '/v1/formula-screenings/:formulaScreeningId/artworks',
  formulaArtworkScreeningDetail: '/v2/formula-screenings/:formulaScreeningId/ingredients',
  performanceArtworkScreeningRequest: '/v1/formula-screenings/:formulaScreeningId/analyses/request',
  formulaArtworkScreeningRequest: '/v2/formula-screenings/:formulaScreeningId/analyses/request',
  formulaArtworkScreeningAnalyses: '/v1/formula-screenings/:formulaScreeningId/analyses',
  formulaArtworkScreeningInquiry: '/v1/formula-screenings/:formulaScreeningId/inquiries',
  formulaArtworkScreeningReport:
    '/v1/formula-screenings/:formulaScreeningId/analyses/update-ingredients',
  artworkScreeningMandatory:
    '/api/v2/formula-screenings/:formulaScreeningId/mandatory-fields/analyses',
  artworkScreeningExcelDownload: `/v2/formula-screenings/excel`,
  artworkPerformanceMeasurementStart: `/v1/admin/formula-screening/:formulaScreeningId/performance-measurement/start`,
  artworkPerformanceMeasurementEnd: `/v1/admin/formula-screening/:formulaScreeningId/performance-measurement/complete`,
  artworkPerformanceMeasurementList: `/v1/admin/formula-screening/performance-measurement/progress`,
};

export const inciApiUrls = {
  formulaScreening: `/v2/regulation-screening/ingredients`,
  formulaJsonExcelDownload: `/v1/regulation-screening/excel`,
  formulaScreeningSearchInciNames: `/v1/regulation-screening/names/search`,
  ingredientInfos: `/v1/ingredients/names`,
  ingredientDetail: `/v2/ingredients/:ingredientId`,
  compareIngredients: `/v2/ingredients`,
  translateIngredients: `/v1/ingredients/translate`,
  regulationScreening: `/v1/regulation-screening/laws`,
  adminRegulationScreening: `/v2/admin/regulation-screening/laws`,
  artworkScreeningAllergens: `/v1/ingredients/allergens`,
};

/**
 * Core Functions
 */

const generateApiUrl = (url: string, params?: Record<string, string | number>) => {
  if (!params) return url;

  let newUrl = url;

  Object.entries(params).forEach(([key, value]) => {
    newUrl = newUrl.replace(`:${key}`, String(value));
  });

  return newUrl;
};

const splitParams = <T extends Record<string, unknown>>(
  params?: T,
  paramNamesForUrl?: (keyof T)[],
): [paramsExceptUrlParams: Partial<T>, urlParams: Partial<T>] => {
  if (!paramNamesForUrl) return [params ?? {}, {}];
  else if (!params) return [{}, {}];

  const paramsExceptUrlParams: Partial<T> = {};
  const urlParams: Partial<T> = {};

  for (const key in params) {
    const value = params[key];

    if (paramNamesForUrl.includes(key)) {
      urlParams[key] = value;
    } else {
      paramsExceptUrlParams[key] = value;
    }
  }

  return [paramsExceptUrlParams, urlParams];
};

export const useBasicQuery = <T>({
  apiUrl = '',
  urlParams = {},
  method = 'get',
  qs = {},
  option,
  isInciApi = false,
}: {
  apiUrl: string;
  urlParams?: { [key: string]: string | number };
  method?: AxiosRequestConfig['method'];
  qs?: { [key: string]: any };
  option?: UseQueryOptions<AxiosResponse<APIResponse<T>>, unknown, T, string[]>;
  isInciApi?: boolean;
}) => {
  const api = isInciApi ? ingredientClient : client;

  return useQuery(
    [apiUrl, ...Object.values(urlParams), ...Object.values(qs)],
    () =>
      api.request<APIResponse<T>>({
        url: generateApiUrl(apiUrl, urlParams),
        method,
        ...(qs && {
          params: qs,
          paramsSerializer: (params) => {
            return QueryString.stringify(params, { arrayFormat: 'repeat' });
          },
        }),
      }),
    {
      select: (res) => res.data.result,
      ...option,
    },
  );
};

export const usePageQuery = <T>({
  apiUrl = '',
  urlParams = {},
  method = 'get',
  qs = {},
  option,
  isInciApi = false,
}: {
  apiUrl?: string;
  urlParams?: Record<string, string | any>;
  method?: AxiosRequestConfig['method'];
  qs?: { [key: string]: any };
  option?: UseQueryOptions<
    AxiosResponse<APIPageableResponse<T[]>>,
    unknown,
    APIPageableResult<T[]>,
    string[]
  >;
  isInciApi?: boolean;
}) => {
  const api = isInciApi ? ingredientClient : client;
  const [emptyArray] = useState<T[]>([]);
  const {
    data: { content, totalElements } = {
      content: emptyArray,
      totalElements: 0,
    },
    ...rest
  } = useQuery(
    [apiUrl, ...Object.values(urlParams), ...Object.values(qs)],
    () =>
      api.request<APIPageableResponse<T[]>>({
        url: generateApiUrl(apiUrl, urlParams),
        method,
        ...(qs && { params: qs }),
      }),
    {
      select: (res) => res.data.result,
      ...option,
    },
  );

  return {
    data: content,
    totalElements,
    ...rest,
  };
};

export const useBasicMutation = <P extends Record<string, any>, T = null>({
  apiUrl = '',
  urlParams = {},
  paramNamesForUrl,
  method = 'post',
  option,
  useFormData,
  refetchUrl,
  formOptions,
  customBodyData,
  isInciApi,
}: {
  apiUrl: string;
  urlParams?: Record<string, string | number>;
  paramNamesForUrl?: string[];
  method?: AxiosRequestConfig['method'];
  option?: UseMutationOptions<AxiosResponse<APIResponse<T>>, unknown, unknown, unknown>;
  useFormData?: boolean;
  refetchUrl?: string | string[];
  formOptions?: {
    withoutArrayWrapper?: boolean;
  };
  customBodyData?: (params: P) => any; // 반환 되는 값을 데이터로 사용합니다.
  isInciApi?: boolean;
}) => {
  const api = isInciApi ? ingredientClient : client;
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (params: P) => {
      const [data, paramsForUrl] = splitParams(params, paramNamesForUrl);

      let body: any;
      const lcMethod = method.toLocaleLowerCase();

      if (lcMethod === 'get') {
        body = undefined;
      } else {
        if (customBodyData) {
          body = customBodyData(params);
        } else if (useFormData) {
          body = createFormData(data, formOptions?.withoutArrayWrapper);
        } else {
          body = data;
        }
      }
      return api.request<APIResponse<T>>({
        url: generateApiUrl(apiUrl, { ...urlParams, ...paramsForUrl }),
        method,
        data: body,
        params: lcMethod === 'get' ? data : undefined,
        paramsSerializer: (params) => QueryString.stringify(params, { arrayFormat: 'repeat' }),
      });
    },
    {
      ...option,
      onSuccess: (data, variables, context) => {
        option?.onSuccess?.(data, variables, context);
        if (Array.isArray(refetchUrl) && refetchUrl.length > 0) {
          refetchUrl.forEach((url) => {
            queryClient.invalidateQueries(url);
          });
        } else if (refetchUrl) {
          queryClient.invalidateQueries(refetchUrl);
        }
      },
    },
  );

  return mutation;
};

export const useBasicQueries = <T>({
  queries,
  isInciApi,
}: {
  queries: {
    apiUrl: string;
    urlParams?: { [key: string]: string | number };
    qs?: { [key: string]: any };
  }[];
  isInciApi?: boolean;
}) => {
  const api = isInciApi ? ingredientClient : client;
  const dataArray = useQueries(
    queries.map(({ apiUrl, urlParams = {}, qs = {} }) => ({
      queryKey: [apiUrl, ...Object.values(urlParams), ...Object.values(qs)],
      queryFn: () =>
        api.request<APIResponse<T>>({
          url: generateApiUrl(apiUrl, urlParams),
          method: 'GET',
          ...(qs && { params: qs }),
        }),
    })),
  );

  const result = dataArray.map((item) => item.data?.data.result);
  const isLoading = dataArray.some((item) => item.isLoading);

  return { dataArray: result, isLoading };
};
