import styled from 'styled-components';
import React from 'react';

import palette from 'lib/styles/palette';
import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import { FilteredMandatoryResult } from './FormulaScreeningMandatory';
import { GuideStatus, MandatoryCoordinate } from 'types/brand/artworkScreening/artworkScreening';
import { useCountryCodeToNameMap } from 'service/country';

const MandatoryList = ({
  result,
  clickedFieldId,
  setfieldCoordinates,
  setClickedFieldId,
  selectedCountryCodes,
}: {
  result: FilteredMandatoryResult;
  clickedFieldId?: number;
  setClickedFieldId: React.Dispatch<React.SetStateAction<number | undefined>>;
  setfieldCoordinates: React.Dispatch<React.SetStateAction<MandatoryCoordinate[]>>;
  selectedCountryCodes: string[];
}) => {
  const countryCodeToNameMap = useCountryCodeToNameMap();

  const statusEntryGuideCount = result.fields
    ?.flatMap((item) => Object.entries(item.categories))
    .filter(
      ([status, category]) =>
        category.some(
          ({ messages }) => messages.length !== 0 && messages.filter((msg) => !!msg).length > 0,
        ) && status === GuideStatus.ENTRY_GUIDE,
    ).length;

  const handleSelectFieldCoordinates = (coordinates: MandatoryCoordinate[]) => {
    setfieldCoordinates(coordinates);
  };

  return (
    <Container>
      <Header>
        <Typography.Text type="BODY_1" color="SLATE_GRAY70" style={{ fontWeight: 500 }}>
          진단 항목: 누락 항목{' '}
          <Typography.Text inline color="MESSAGE_ERROR" style={{ fontWeight: 500 }}>
            {statusEntryGuideCount}
          </Typography.Text>
          건
        </Typography.Text>
      </Header>
      <MessagesContainer dir="column" style={{ alignItems: 'start' }}>
        {result.fields?.map((item, fieldIdx) => {
          const groupedCategories = Object.entries(item.categories);
          const mandatoryFieldCodeId = item.mandatoryFieldCodeId;
          const isFunctional = groupedCategories.map(([_, item]) => item[0].isFunctional);

          return groupedCategories.map(([status, category], idx) => {
            const noMsgContain = category.every(
              ({ messages }) =>
                messages.length === 0 && messages.filter((msg) => !!msg).length === 0,
            );
            const hasMsgCategories = category.filter(
              (item) => item.messages.length > 0 && item.messages.every((msg) => !!msg),
            );

            const allCategoryFieldCoordinates = category.flatMap(({ coordinates }) => coordinates);

            return (
              <FieldItem
                key={`${fieldIdx}_${item.mandatoryFieldCodeId}_${idx}`}
                selected={mandatoryFieldCodeId === clickedFieldId}
                dir="column"
                onClick={() => {
                  handleSelectFieldCoordinates(allCategoryFieldCoordinates);
                  setClickedFieldId(mandatoryFieldCodeId);
                }}
              >
                <Flex style={{ width: '100%' }} justify="space-between">
                  <Flex align="center" gap={8}>
                    <Typography.Text
                      className="filed_title"
                      type="BODY_2"
                      color="SLATE_GRAY70"
                      style={{ fontWeight: 600 }}
                    >
                      {item.fieldName}
                    </Typography.Text>
                    {isFunctional?.[0] && <FunctionalChip>기능성화장품</FunctionalChip>}
                  </Flex>
                  <GuideStautsChip>
                    <Typography.Text
                      type="SMALL"
                      color={
                        noMsgContain
                          ? 'GRAY70'
                          : status === GuideStatus.ENTRY_GUIDE
                          ? 'MESSAGE_ERROR'
                          : 'YELLOW_70'
                      }
                      style={{ fontWeight: 500, maxWidth: '45px' }}
                    >
                      {noMsgContain
                        ? '기재 완료'
                        : status === GuideStatus.ENTRY_GUIDE
                        ? '확인 불가'
                        : '확인 필요'}
                    </Typography.Text>
                  </GuideStautsChip>
                </Flex>
                {!noMsgContain && Object.values(hasMsgCategories).length > 0 && (
                  <CategoriesContainer
                    dir="column"
                    gutter={{ top: 12 }}
                    style={{
                      alignItems: 'start',
                      background: noMsgContain ? palette.PRIMARY10 : palette.SLATE_GRAY10,
                    }}
                  >
                    {Object.values(hasMsgCategories).map((item, idx) => {
                      return (
                        <Flex
                          key={idx}
                          dir="column"
                          style={{
                            borderTop: idx !== 0 ? `1px solid ${palette.GRAY30}` : 'none',
                            width: '100%',
                            paddingTop: idx !== 0 ? '16px' : 0,
                            marginTop: idx !== 0 ? '16px' : 0,
                            alignItems: 'start',
                          }}
                        >
                          {!selectedCountryCodes?.every((code) =>
                            item.countryCodes.includes(code),
                          ) && (
                            <Typography.Text
                              type="BODY_2"
                              color="SLATE_GRAY70"
                              style={{ fontWeight: 500 }}
                            >
                              {item.countryCodes.map((code) => (
                                <React.Fragment
                                  key={code}
                                >{`[${countryCodeToNameMap[code]}] `}</React.Fragment>
                              ))}
                            </Typography.Text>
                          )}
                          <Flex
                            gutter={{
                              top: !selectedCountryCodes?.every((code) =>
                                item.countryCodes.includes(code),
                              )
                                ? 8
                                : 0,
                            }}
                            dir="column"
                            align="start"
                            gap={8}
                          >
                            {item.messages.map((msg) => (
                              <Flex style={{ alignItems: 'start' }} gap={8}>
                                <DotDiv style={{ marginTop: 8 }} />
                                <Typography.Text type="BODY_2" color="SLATE_GRAY70">
                                  {msg}
                                </Typography.Text>
                              </Flex>
                            ))}
                          </Flex>
                        </Flex>
                      );
                    })}
                  </CategoriesContainer>
                )}
              </FieldItem>
            );
          });
        })}
      </MessagesContainer>
    </Container>
  );
};

const Container = styled.section`
  width: 376px;
  height: 792px;
  margin-top: 16px;
`;
const Header = styled.div`
  width: 100%;
  padding: 17px 24px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px 8px 0px 0px;
  border: 1px solid ${palette.GRAY30};
`;

const MessagesContainer = styled(Flex)`
  padding: 4px 8px;
  border: 1px solid ${palette.GRAY30};
  border-top: none;
  height: 736px;
  overflow: scroll;
  border-radius: 0px 0px 8px 8px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const FieldItem = styled(Flex)<{ selected: boolean }>`
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid ${palette.GRAY30};

  cursor: pointer;
  &:hover {
    .filed_title {
      text-decoration: underline;
    }
  }
  .filed_title {
    text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  }
`;

const GuideStautsChip = styled(Flex)`
  justify-content: center;
  align-items: center;
  border: 1px solid ${palette.GRAY30};
  border-radius: 4px;
  color: ${palette.GRAY70};
  width: 61px;
  height: 24px;
`;

const CategoriesContainer = styled(Flex)`
  border-radius: 4px;
  padding: 16px;
  width: 100%;
`;

const DotDiv = styled.div`
  width: 4px;
  min-width: 4px;
  height: 4px;
  min-height: 4px;
  background-color: ${palette.SLATE_GRAY50};
`;

const FunctionalChip = styled(Flex)`
  justify-content: center;
  align-items: center;
  background-color: #e3fbe7;
  border-radius: 3px;
  color: ${palette.SLATE_GRAY70};
  width: 79px;
  height: 20px;
  font-size: 12px;
  font-weight: 600;
`;

export default MandatoryList;
