import { useQuery, useMutation } from 'react-query';

import client from 'lib/api/client';
import { APIResponse } from 'types/common';
import {
  AddStabilityTestParams,
  StabilityTest,
  UpdateStabilityTestParams,
} from 'types/brand/product/stabilityTest';

export const useStabilityTest = (productId: number, countryId?: number) => {
  const { data: stabilityTest, isLoading } = useQuery(
    ['products/stability-tests', productId, countryId],
    () =>
      client.get<APIResponse<StabilityTest>>(`/products/${productId}/stability-tests`, {
        params: {
          countryId,
        },
      }),
    {
      select: (res) => res.data.result,
    },
  );

  return { stabilityTest, isLoading };
};

export const useAddStabilityTest = () => {
  const { mutate: addStabilityTest, isLoading } = useMutation((params: AddStabilityTestParams) => {
    const { productId, countryId, ...rest } = params;
    return client.post<APIResponse<null>>(
      `/products/${productId}/stability-tests${countryId ? `?countryId=${countryId}` : ''}`,
      rest,
    );
  });

  return {
    addStabilityTest,
    isLoading,
  };
};

export const useUpdateStabilityTest = () => {
  const { mutate: updateStabilityTest, isLoading } = useMutation(
    (params: UpdateStabilityTestParams) => {
      const { productId, countryId, ...rest } = params;
      return client.patch<APIResponse<null>>(
        `/products/${productId}/stability-tests${countryId ? `?countryId=${countryId}` : ''}`,
        {
          ...rest,
        },
      );
    },
  );

  return {
    updateStabilityTest,
    isLoading,
  };
};
