import styled from 'styled-components';
import { Form, Input, Select, message } from 'antd';
import { useEffect, useState } from 'react';

import { generateFormRules } from 'lib/validate';
import { InquiryErrorType } from 'types/brand/artworkScreening/artworkScreening';
import { useFormulaScreeningArtworkInquiry } from 'service/brand/artworkScreening/artworkScreening';
import { Flex } from 'components/ui';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import Icon from 'components/ui/Icon/Icon';
import { Typography } from 'components/system';

export interface InquiryModalForm {
  content: string;
}

const InquiryButton = ({
  formulaScreeningId,
  isMandatoryScreening = false,
}: {
  formulaScreeningId: number;
  isMandatoryScreening: boolean;
}) => {
  const [form] = Form.useForm<InquiryModalForm>();
  const [inquiryStatus, setInquiryStatus] = useState<InquiryErrorType>(InquiryErrorType.OCR);

  const { openConfirmModal, closeConfirmModal } = useModal();
  const { registerFormulaScreeningArtworkInquiry, registerFormulaScreeningArtworkInquiryLoading } =
    useFormulaScreeningArtworkInquiry();

  const handleSubmit = ({ content }: InquiryModalForm) => {
    registerFormulaScreeningArtworkInquiry(
      {
        formulaScreeningId,
        errorType: inquiryStatus,
        content,
      },
      {
        onSuccess: () => {
          message.success('전송되었습니다. 담당자가 확인 후 메일로 전달드립니다.');
          form.resetFields();
          closeConfirmModal();
        },
      },
    );
  };

  const handleClickButton = () => {
    openConfirmModal({
      title: '문의 보내기',
      content: (
        <FormContainer>
          <Form
            form={form}
            onFinish={handleSubmit}
            layout="vertical"
            initialValues={{
              errorType: InquiryErrorType.OCR,
            }}
          >
            <Form.Item label="분석 항목" name="errorType" required>
              <Select
                value={inquiryStatus}
                onSelect={(val) => {
                  setInquiryStatus(val);
                }}
                placeholder="분석 항목 선택"
                options={[
                  {
                    label: '전성분 표기 오류',
                    value: InquiryErrorType.OCR,
                  },
                  {
                    label: '필수 기재 사항',
                    value: InquiryErrorType.MANDATORY,
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              name="content"
              rules={generateFormRules({
                required: true,
                maxLength: 1000,
              })}
            >
              <Input.TextArea placeholder="문의 내용 입력" style={{ resize: 'none' }} />
            </Form.Item>
          </Form>
        </FormContainer>
      ),
      okText: '전송',
      onOk: form.submit,
      okLoading: registerFormulaScreeningArtworkInquiryLoading,
      preventOkClose: true,
      footerStyle: {
        margin: 0,
        paddingTop: 8,
      },
    });
  };

  useEffect(() => {
    return () => {
      form.resetFields();
    };
  }, []);

  return (
    <Flex
      gap={4}
      onClick={handleClickButton}
      style={{
        borderRadius: 4,
        padding: '6px 8px',
        cursor: 'pointer',
        border: `1px solid ${palette.PRIMARY50}`,
      }}
    >
      <Icon name="send" size={18} color="PRIMARY50" />
      <Typography.Text type="BODY_2" color="SLATE_GRAY70" medium>
        문의 보내기
      </Typography.Text>
    </Flex>
  );
};

const FormContainer = styled.div`
  text-align: left;

  textarea.ant-input {
    padding: 11px 15px;
    height: 80px;
  }
`;

export default InquiryButton;
