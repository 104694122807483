import styled from 'styled-components';
import { Button, Checkbox, Form, Input, message, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { useForceUpdate } from 'lib/hook';
import palette from 'lib/styles/palette';
import { exceptKoreanRule } from 'lib/validate';
import { useCertificateMode } from 'hook/certificate';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import { Tip, Typography } from 'components/system';
import { Flex } from 'components/ui';
import UpdateLog from './UpdateLog';
import { DocumentCode } from 'types/product';
import {
  useAddStabilityTest,
  useStabilityTest,
  useUpdateStabilityTest,
} from 'service/brand/product/stabilityTest';
import { useCountryId, useCurrentProduct } from 'service/brand/product/product';
import { getUpdatingObject } from 'lib/form';
import { UpdateStabilityTestParams } from 'types/brand/product/stabilityTest';
import useGA, { EGAActionType } from 'hook/useGA';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';
import Icon from 'components/ui/Icon/Icon';
import { useMovePathname } from 'hook/useMovePathname';

const Container = styled.div`
  max-width: 520px;
  margin: 0 auto;
`;

const ProductStabilityTest = () => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const navigate = useNavigate();
  const { movePathname } = useMovePathname();
  const { sendEventToGA } = useGA();
  const { productId } = useCurrentProduct();
  const countryId = useCountryId();
  const [form] = Form.useForm();

  const { readOnlyMode, skipMode } = useSelector(({ certificate, product }: any) => ({
    readOnlyMode: certificate.readOnlyMode,
    skipMode: product.skipMode,
  }));

  const { stabilityTest, isLoading: getLoading } = useStabilityTest(productId, countryId);
  const { addStabilityTest, isLoading: addLoading } = useAddStabilityTest();
  const { updateStabilityTest, isLoading: updateLoading } = useUpdateStabilityTest();
  const updateMode = stabilityTest !== null;
  const onSubmit = (formValues: any) => {
    if (!stabilityTest) {
      addStabilityTest(
        {
          productId,
          ...(countryId && { countryId }),
          ...formValues,
        },
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Stability Test',
              actionType: EGAActionType.REGISTER,
            });
            if (skipMode) {
              movePathname({ productId });
              return;
            }
            message.success('등록되었습니다.');
            navigate(-1);
          },
        },
      );
    } else {
      const updateValues = getUpdatingObject(formValues, stabilityTest);
      if (!updateValues || Object.keys(updateValues).length === 0) {
        return message.warning(messages.NO_NEED_TO_UPDATE);
      }

      updateStabilityTest(
        {
          productId,
          countryId,
          ...updateValues,
        } as UpdateStabilityTestParams,
        {
          onSuccess: () => {
            sendEventToGA({
              documentName: 'Stability Test',
              actionType: certificateMode ? EGAActionType.MODIFY : EGAActionType.UPDATE,
            });
            message.success(certificateMode ? '보완 완료되었습니다.' : '수정되었습니다.');
            navigate(-1);
          },
        },
      );
    }
  };

  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  const [disabled, setDisabled] = useState({
    temperatureRange1: false,
    temperatureRange2: false,
    temperatureRange3: false,
    cycleTemperatureRange: false,
  });

  useEffect(() => {
    if (stabilityTest) {
      form.setFieldsValue(stabilityTest);
      setDisabled({
        temperatureRange1: stabilityTest.isNotApplicableTemperatureRange1,
        temperatureRange2: stabilityTest.isNotApplicableTemperatureRange2,
        temperatureRange3: stabilityTest.isNotApplicableTemperatureRange3,
        cycleTemperatureRange: stabilityTest.isNotApplicableCycleTemperatureRange,
      });
    }
  }, [stabilityTest]);

  const normalize = (checked?: boolean) => {
    if (Object.values(disabled).filter((value) => value).length >= 3 && checked) {
      message.warning('최소 1개 이상의 온도 범위가 필요합니다.');
      return false;
    }

    return checked;
  };

  const handleChangeCheckbox = (checked: boolean, key: keyof typeof disabled) => {
    if (Object.values(disabled).filter((value) => value).length >= 3 && checked) {
      return;
    }

    if (checked) {
      setDisabled({
        ...disabled,
        [key]: true,
      });
      form.setFieldsValue({
        [key]: undefined,
      });
    } else {
      setDisabled({
        ...disabled,
        [key]: false,
      });
    }
  };

  return (
    <>
      <Flex justify="flex-end" gap={8} style={{ marginBottom: updateMode ? 28 : 0 }}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.STAB}
        />
        {updateMode && (
          <UpdateLog productId={productId} countryId={countryId} documentCode={DocumentCode.STAB} />
        )}
      </Flex>
      <Container>
        <Spin spinning={getLoading}>
          <Form
            form={form}
            colon={false}
            layout="vertical"
            scrollToFirstError={scrollToFirstErrorOption}
            onFinish={onSubmit}
            onValuesChange={() => {
              forceUpdate();
            }}
          >
            <Form.Item
              label={
                <Flex align="center" justify="space-between">
                  <Typography.Text type="TITLE_2" gutter={{ right: 260 }}>
                    온도 범위1 (고온)
                  </Typography.Text>
                  <Form.Item
                    name="isNotApplicableTemperatureRange1"
                    noStyle
                    valuePropName="checked"
                    normalize={normalize}
                    initialValue={false}
                  >
                    <Checkbox
                      checked={disabled.temperatureRange1}
                      disabled={readOnlyMode}
                      onChange={(e) => handleChangeCheckbox(e.target.checked, 'temperatureRange1')}
                    >
                      해당 없음
                    </Checkbox>
                  </Form.Item>
                </Flex>
              }
              name="temperatureRange1"
              normalize={(value) => value.replace(/[^0-9~]/g, '').replace(/~~/g, '~')}
              required
              rules={[
                {
                  pattern: /(^0$)|(^[1-9][0-9]?$)|(^[1-9][0-9]?~[1-9][0-9]?$)/,
                  message: '올바르지 않은 형식입니다. 예시: 40~50',
                },
              ]}
            >
              <Input
                placeholder="예시: 40~50"
                addonAfter="℃"
                disabled={readOnlyMode || disabled.temperatureRange1}
              />
            </Form.Item>
            <Form.Item
              label={
                <Flex align="center" justify="space-between">
                  <Typography.Text type="TITLE_2" gutter={{ right: 260 }}>
                    온도 범위2 (상온)
                  </Typography.Text>
                  <Form.Item
                    name="isNotApplicableTemperatureRange2"
                    noStyle
                    valuePropName="checked"
                    normalize={normalize}
                    initialValue={false}
                  >
                    <Checkbox
                      checked={disabled.temperatureRange2}
                      disabled={readOnlyMode}
                      onChange={(e) => handleChangeCheckbox(e.target.checked, 'temperatureRange2')}
                    >
                      해당 없음
                    </Checkbox>
                  </Form.Item>
                </Flex>
              }
              name="temperatureRange2"
              required
              rules={[
                {
                  pattern:
                    /(^0$)|(^[1-9][0-9]?$)|(^[1-9][0-9]?~[1-9][0-9]?$)|(^ROOM TEMPERATURE$)|(^RT$)/,
                  message: '올바르지 않은 형식입니다. 예시: 20~30 or ROOM TEMPERATURE or RT',
                },
              ]}
              normalize={(value) => value.toUpperCase()}
            >
              <Input
                placeholder="예시: 20~30 or ROOM TEMPERATURE or RT"
                addonAfter={
                  form.getFieldValue('temperatureRange2') !== 'ROOM TEMPERATURE' &&
                  form.getFieldValue('temperatureRange2') !== 'RT'
                    ? '℃'
                    : ' '
                }
                disabled={readOnlyMode || disabled.temperatureRange2}
              />
            </Form.Item>
            <Form.Item
              label={
                <Flex align="center" justify="space-between">
                  <Typography.Text type="TITLE_2" gutter={{ right: 260 }}>
                    온도 범위3 (저온)
                  </Typography.Text>
                  <Form.Item
                    name="isNotApplicableTemperatureRange3"
                    noStyle
                    valuePropName="checked"
                    normalize={normalize}
                    initialValue={false}
                  >
                    <Checkbox
                      checked={disabled.temperatureRange3}
                      disabled={readOnlyMode}
                      onChange={(e) => handleChangeCheckbox(e.target.checked, 'temperatureRange3')}
                    >
                      해당 없음
                    </Checkbox>
                  </Form.Item>
                </Flex>
              }
              name="temperatureRange3"
              normalize={(value) =>
                value
                  .replace(/[^0-9~-]/g, '')
                  .replace(/~~/, '~')
                  .replace(/--/, '-')
              }
              required
              rules={[
                {
                  pattern:
                    /(^0$)|(^[-]?[1-9][0-9]?$)|(^(0|([-]?[1-9][0-9]?))~(0|([-]?[1-9][0-9]?))$)/,
                  message: '올바르지 않은 형식입니다. 예시: -10~10',
                },
              ]}
            >
              <Input
                placeholder="예시: -10~10"
                addonAfter="℃"
                disabled={readOnlyMode || disabled.temperatureRange3}
              />
            </Form.Item>
            <Form.Item
              label={
                <Flex align="center" justify="space-between">
                  <Typography.Text type="TITLE_2" gutter={{ right: 4 }}>
                    사이클 온도 범위
                  </Typography.Text>
                  <Tip
                    trigger="click"
                    icon={
                      <Button
                        style={{
                          height: 20,
                          fontSize: 12,
                          padding: '0 4px 0 8px',
                          border: `1px solid ${palette.GRAY40}`,
                          lineHeight: '18px',
                          letterSpacing: -0.4,
                          color: palette.GRAY70,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        예시 참고
                        <Icon name="search" size={10} color="GRAY70" style={{ marginLeft: 4 }} />
                      </Button>
                    }
                    style={{ marginRight: 238 }}
                  >
                    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                      ① 45℃ /25℃(RT)/ -10℃
                    </Typography.Text>
                    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                      ② CYCLE(5~40℃)
                    </Typography.Text>
                    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                      ③ 50℃, 45℃, 25℃, 4℃
                    </Typography.Text>
                    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                      ④ 1 cycle from -5℃ to 40℃ cycling in 24 hrs
                    </Typography.Text>
                    <Typography.Text type="BODY_2" style={{ fontSize: 12 }}>
                      ⑤ 기타
                    </Typography.Text>
                  </Tip>
                  <Form.Item
                    name="isNotApplicableCycleTemperatureRange"
                    noStyle
                    valuePropName="checked"
                    normalize={normalize}
                    initialValue={false}
                  >
                    <Checkbox
                      checked={disabled.cycleTemperatureRange}
                      disabled={readOnlyMode}
                      onChange={(e) =>
                        handleChangeCheckbox(e.target.checked, 'cycleTemperatureRange')
                      }
                    >
                      해당 없음
                    </Checkbox>
                  </Form.Item>
                </Flex>
              }
              name="cycleTemperatureRange"
              required
              rules={[exceptKoreanRule]}
            >
              <Input
                placeholder="예시 참고하여 자유롭게 입력"
                disabled={readOnlyMode || disabled.cycleTemperatureRange}
              />
            </Form.Item>
            <FooterBox>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                {updateMode && certificateMode && (
                  <CorrectRequestWithoutChangeButton documentCode={DocumentCode.STAB} />
                )}
                <Button
                  type="primary"
                  loading={addLoading || updateLoading}
                  htmlType="submit"
                  disabled={
                    !form.getFieldValue('temperatureRange1') &&
                    !form.getFieldValue('temperatureRange2') &&
                    !form.getFieldValue('temperatureRange3') &&
                    !form.getFieldValue('cycleTemperatureRange')
                  }
                >
                  {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </Form>
        </Spin>
      </Container>
    </>
  );
};

export default ProductStabilityTest;
