import { Button, Form, Input, Radio, Spin } from 'antd';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useCertificateMode, useReadOnlyMode } from 'hook/certificate';
import { useManuMode } from 'hook/company';
import { PackingAttestationAttache } from 'types/brand/product/packingAttestation';
import { DocumentCode } from 'types/product';
import { messages, scrollToFirstErrorOption } from 'lib/consts';
import { useForceUpdate } from 'lib/hook';
import palette from 'lib/styles/palette';
import { exceptKoreanRule, requireRule } from 'lib/validate';
import { useProductPackingAttestation } from 'service/brand/product/packingAttestation';
import FooterBox from 'components/FooterBox';
import ReadOnlyBackButton from 'components/ReadOnlyBackButton';
import Typography from 'components/system/general/Typography';
import { BackgroundRadio, Flex } from 'components/ui';
import UploadDragger from 'components/UploadDragger';
import UpdateLog from 'components/product/UpdateLog';
import ProductCorrectButton from 'components/certificate/ProductCorrectButton';
import CorrectRequestWithoutChangeButton from 'components/certificate/CorrectRequestWithoutChangeButton';

const Container = styled.div``;

const Title = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
`;

const BrandContainer = styled.div`
  width: 520px;
  margin: 0 auto;
  counter-reset: no;

  ${Title} {
    &::before {
      display: inline-block;
      counter-increment: no;
      content: counter(no);
      background-color: ${palette.PRIMARY50};
      color: #fff;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      text-align: center;
      font-size: 12px;
    }
  }
`;

const useOptions = [
  { title: '사용함', value: true },
  { title: '사용 안함', value: false },
];

const RadioFormItemWrapper = styled.div`
  width: 520px;
  margin-bottom: 24px;
  margin-left: auto;
  margin-right: auto;

  > .ant-radio-group {
    margin-top: 8px;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 28px;
    background-color: ${palette.SLATE_GRAY10};
    padding: 20px 44px;
    border-radius: 4px;
  }

  .ant-radio {
    transform: translateY(-1px);
  }
`;

const ProductPackingAttestation = ({
  productId,
  countryId,
}: {
  productId: number;
  countryId?: number;
}) => {
  const [isFirstOpenChatModal, setIsFirstOpenChatModal] = useState(false);
  const forceUpdate = useForceUpdate();
  const certificateMode = useCertificateMode();
  const readOnlyMode = useReadOnlyMode();
  const manuMode = useManuMode();
  const {
    updateMode,
    form,
    getLoading,
    fetchLoading,
    updateLoading,
    packingAttestationType,
    firstPackingFiles,
    setFirstPackingFiles,
    currentFirstFile,
    setCurrentFirstFile,
    currentSecondFile,
    setCurrentSecondFile,
    secondPackingFiles,
    setSecondPackingFiles,
    submit,
    changePackingAttestationType,
    patchPackingAttestationType,
    isFile,
    setDeletedFiles,
  } = useProductPackingAttestation(productId, countryId);

  const getImageUrl = useCallback(
    (file?: PackingAttestationAttache | File) =>
      file ? (isFile(file) ? URL.createObjectURL(file) : file.attachUrl) : undefined,
    [isFile],
  );

  return (
    <Container>
      <Flex justify="end" gap={8}>
        <ProductCorrectButton
          isFirstOpenChatModal={isFirstOpenChatModal}
          onChangeIsFirstOpenChatModal={setIsFirstOpenChatModal}
          documentCode={DocumentCode.PACK}
        />
        {updateMode && <UpdateLog productId={productId!} documentCode={DocumentCode.PACK} />}
      </Flex>

      <Spin spinning={getLoading === true}>
        {manuMode && (
          <RadioFormItemWrapper>
            <Typography.Text type="TITLE_2" asterisk>
              부자재 구매 유형
            </Typography.Text>
            <Radio.Group
              value={packingAttestationType}
              onChange={(e) => changePackingAttestationType(e.target.value)}
            >
              <Radio value="TRK">턴키 (Turn-Key)</Radio>
              <Radio value="ATK">턴키 구매대행</Radio>
              <Radio value="BND">브랜드사 사급</Radio>
            </Radio.Group>
          </RadioFormItemWrapper>
        )}
        {(!manuMode || (packingAttestationType && packingAttestationType !== 'BND')) && (
          <>
            <BrandContainer>
              <Form
                form={form}
                onFinish={submit}
                colon={false}
                layout="vertical"
                scrollToFirstError={scrollToFirstErrorOption}
                onValuesChange={(fieldMap) => {
                  if (
                    ['isUseRecyclingContainer', 'isUsePackingContainer2'].includes(
                      Object.keys(fieldMap)[0],
                    )
                  ) {
                    forceUpdate();
                  }
                }}
              >
                <Title>
                  <Typography.Text type="TITLE_1" inline>
                    1차 포장 용기 정보를 입력해 주세요.
                  </Typography.Text>
                </Title>
                <Form.Item
                  label="1차 포장 용기 재질"
                  name="packingContainer1"
                  normalize={(value) => value.toUpperCase()}
                  rules={[requireRule, exceptKoreanRule]}
                >
                  <Input disabled={readOnlyMode} />
                </Form.Item>
                <Form.Item
                  required
                  name="packingContainer1Image"
                  label="1차 포장 용기 사진 첨부"
                  valuePropName="fileList"
                  getValueFromEvent={(e: any) => {
                    if (Array.isArray(e)) {
                      return e;
                    }
                    return e?.fileList;
                  }}
                  rules={[
                    {
                      validator: async () => {
                        if (firstPackingFiles.length === 0) {
                          throw Error(messages.REQUIRED_FIELD);
                        }
                      },
                    },
                  ]}
                >
                  <UploadDragger
                    className="picture"
                    width={520}
                    height={260}
                    form={form}
                    initialImageUrl={getImageUrl(currentFirstFile)}
                    disabled={readOnlyMode}
                    onDragger={(file) => {
                      setCurrentFirstFile(file);
                      setFirstPackingFiles((draft) => [file, ...draft]);
                    }}
                    imageDescription="400 x 200 / 용량 300KB 이하 권장"
                    packingFiles={firstPackingFiles}
                    setPackingFiles={setFirstPackingFiles}
                    currentFile={currentFirstFile}
                    setCurrentFile={setCurrentFirstFile}
                    isFile={isFile}
                    setDeletedFiles={setDeletedFiles}
                  />
                </Form.Item>
                <Title>
                  <Typography.Text type="TITLE_1" inline>
                    2차 포장 용기 정보를 입력해 주세요.
                  </Typography.Text>
                </Title>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.isUsePackingContainer2 !== next.isUsePackingContainer2
                  }
                >
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        label="2차 포장 용기 사용 여부"
                        name="isUsePackingContainer2"
                        rules={[requireRule, exceptKoreanRule]}
                      >
                        <BackgroundRadio gap={140} options={useOptions} disabled={readOnlyMode} />
                      </Form.Item>
                      {getFieldValue('isUsePackingContainer2') && (
                        <>
                          <Form.Item
                            label="2차 포장 용기 재질"
                            name="packingContainer2"
                            normalize={(value) => value.toUpperCase()}
                            rules={[requireRule, exceptKoreanRule]}
                          >
                            <Input disabled={readOnlyMode} />
                          </Form.Item>
                          <Form.Item
                            required
                            name="packingContainer2Image"
                            label="2차 포장 용기 사진 첨부"
                            rules={[
                              {
                                validator: async () => {
                                  if (secondPackingFiles.length === 0) {
                                    throw Error(messages.REQUIRED_FIELD);
                                  }
                                },
                              },
                            ]}
                          >
                            <>
                              <UploadDragger
                                className="picture2"
                                width={520}
                                height={260}
                                form={form}
                                initialImageUrl={getImageUrl(currentSecondFile)}
                                disabled={readOnlyMode}
                                onDragger={(file) => {
                                  setCurrentSecondFile(file);
                                  setSecondPackingFiles((draft) => [file, ...draft]);
                                }}
                                imageDescription="400 x 200 / 용량 300KB 이하 권장"
                                packingFiles={secondPackingFiles}
                                setPackingFiles={setSecondPackingFiles}
                                currentFile={currentSecondFile}
                                setCurrentFile={setCurrentSecondFile}
                                isFile={isFile}
                                setDeletedFiles={setDeletedFiles}
                              />
                            </>
                          </Form.Item>
                        </>
                      )}
                    </>
                  )}
                </Form.Item>
                <Title>
                  <Typography.Text type="TITLE_1" inline>
                    재활용 용기 정보를 입력해 주세요.
                  </Typography.Text>
                </Title>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.isUseRecyclingContainer !== next.isUseRecyclingContainer
                  }
                >
                  {({ getFieldValue }) => (
                    <>
                      <Form.Item
                        rules={[requireRule]}
                        label={
                          <>
                            재활용 용기 사용 여부{' '}
                            <Typography.Text
                              type="BODY_2"
                              color="GRAY70"
                              gutter={{ left: 8 }}
                              style={{ fontSize: 12 }}
                            >
                              <span style={{ color: palette.PRIMARY50 }}>*PCR 용기</span>
                              (Post Consumer Recycled)를 사용했을 경우
                            </Typography.Text>
                          </>
                        }
                        name="isUseRecyclingContainer"
                      >
                        <BackgroundRadio gap={140} options={useOptions} disabled={readOnlyMode} />
                      </Form.Item>
                      {getFieldValue('isUseRecyclingContainer') && (
                        <Form.Item
                          rules={[requireRule]}
                          label="재활용 용기 색깔 사용 여부"
                          name="isUseColorContainer"
                        >
                          <BackgroundRadio gap={140} options={useOptions} disabled={readOnlyMode} />
                        </Form.Item>
                      )}
                    </>
                  )}
                </Form.Item>
              </Form>
            </BrandContainer>
            <FooterBox>
              <ReadOnlyBackButton readOnly={readOnlyMode}>
                {updateMode && certificateMode && (
                  <CorrectRequestWithoutChangeButton documentCode={DocumentCode.PACK} />
                )}
                <Button
                  type="primary"
                  loading={fetchLoading || updateLoading}
                  onClick={form.submit}
                >
                  {!updateMode ? '등록' : !certificateMode ? '수정' : '보완 완료'}
                </Button>
              </ReadOnlyBackButton>
            </FooterBox>
          </>
        )}
        {manuMode && packingAttestationType === 'BND' && (
          <FooterBox>
            <Button loading={fetchLoading} type="primary" onClick={patchPackingAttestationType}>
              {!updateMode ? '확인' : '수정'}
            </Button>
          </FooterBox>
        )}
      </Spin>
    </Container>
  );
};

export default ProductPackingAttestation;
