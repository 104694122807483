// ArtworkScreening Step4 로직을 모아놓은 파일
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { cloneDeep, isArray } from 'lodash';
import { useLocation } from 'react-router-dom';
import { message } from 'antd';

import { ArtworkItem, OriginArtworkItem } from 'types/brand/product/artworkScreening';
import { TranslateLanguageCode } from 'components/brand/artworkScreening/step4/ReportModalContent';
import client, { setToken } from 'lib/api/client';
import { apiUrls, inciApiUrls, useBasicMutation, useBasicQuery } from 'service/core';

import {
  FormulaArtworkScreeningItem,
  FormulaArtworkScreeningJson,
  ArtworkScreeningResult,
  InquiryErrorType,
  ScreeningStatus,
  ArtworkScreeningAllergen,
  RuleSubCode,
  CountryRules,
  ArtworkIngredient,
  RuleType,
  ArtworkPreIngredient,
  ArtworkPostIngredient,
  PostIngredient,
  MandatoryFieldsResult,
  PackingType,
  PerformaceMeasurementItem,
} from 'types/brand/artworkScreening/artworkScreening';
import { APIResponse } from 'types/common';
import {
  countryOrder,
  findLastMatchingItem,
  getIngerdientNameWithAdditional,
} from 'lib/artworkScreening';

export const useArtworkScreeningAllergens = () => {
  // 알레르기 유발성분 inciName 한글명 조회 JSON(SDC009)
  const { data: artworkScreeningAllergens = [], isLoading: getArtworkScreeningAllergensLoading } =
    useBasicQuery<ArtworkScreeningAllergen[]>({
      apiUrl: inciApiUrls.artworkScreeningAllergens,
      isInciApi: true,
      option: {
        staleTime: Infinity,
      },
    });

  return {
    artworkScreeningAllergens,
    getArtworkScreeningAllergensLoading,
  };
};

export const useFormulaArtworkScreening = ({
  formulaScreeningId,
  isAdmin,
}: {
  formulaScreeningId?: number;
  isAdmin?: boolean;
}) => {
  const [formulaScreeningResult, setFormulaScreeningResult] =
    useState<FormulaArtworkScreeningItem | null>(null);

  // V2 전성분 스크리닝 (로그인 필요) (FSC002)
  const {
    mutate: uploadFormulaArtworkScreeningJson,
    isLoading: uploadFormulaArtworkScreeningJsonLoading,
  } = useMutation(
    (params: FormulaArtworkScreeningJson) =>
      client.post<APIResponse<FormulaArtworkScreeningItem>>(
        `${apiUrls.formulaArtworkScreening}/${
          formulaScreeningId ? `?formulaScreeningId=${formulaScreeningId}` : ''
        }`,
        params,
      ),
    {
      onSuccess: (res) => {
        setFormulaScreeningResult(res.data.result);
      },
      onError: (err) => {
        setFormulaScreeningResult(null);
      },
    },
  );

  // 전성분 스크리닝 분석 결과 스크리닝 ID를 통한 조회 (FSC004)
  const { data: formulaScreeningDetail, isLoading: formulaScreeningDetailLoading } =
    useBasicQuery<FormulaArtworkScreeningItem>({
      apiUrl: apiUrls.formulaArtworkScreeningDetail,
      ...(formulaScreeningId && { urlParams: { formulaScreeningId } }),
      option: {
        enabled: !!formulaScreeningId,
        onSuccess: (res) => {
          setFormulaScreeningResult(res);
        },
      },
    });

  // 전성분 스크리닝 정보 조회 (로그인 필요) (FSC003)
  const { data: formulaScreeningStatus, isLoading: getFormulaScreeningStatusLoading } =
    useBasicQuery<{
      formulaScreeningId: number;
      status: ScreeningStatus;
    }>({
      apiUrl: apiUrls.formulaArtworkScreeningStatus,
      ...(formulaScreeningId && { urlParams: { formulaScreeningId } }),
      option: {
        enabled: !!formulaScreeningId,
      },
    });
  const { mutate: originformulaScreeningArtworkFile } = useBasicMutation<
    { formulaScreeningId: number },
    OriginArtworkItem
  >({
    method: 'get',
    apiUrl: apiUrls.originformulaScreeningArtworkFile,
    paramNamesForUrl: ['formulaScreeningId'],
  });
  // 전성분 스크리닝 아트웍 정보 조회 (로그인 필요) (FSC006)
  const { mutate: formulaScreeningArtworkFile } = useBasicMutation<
    { formulaScreeningId: number },
    ArtworkItem
  >({
    method: 'get',
    apiUrl: apiUrls.formulaScreeningArtworkFile,
    paramNamesForUrl: ['formulaScreeningId'],
  });

  return {
    uploadFormulaArtworkScreeningJson,
    formulaScreeningResult,
    setFormulaScreeningResult,
    formulaArtworkScreeningLoading: uploadFormulaArtworkScreeningJsonLoading,
    formulaScreeningDetail,
    formulaScreeningDetailLoading,
    formulaScreeningStatus,
    getFormulaScreeningStatusLoading,
    formulaScreeningArtworkFile,
    originformulaScreeningArtworkFile,
  };
};

// 전성분 스크리닝 요청 (로그인 필요) (FSC004)
export const useFormulaArtworkScreeningRequest = () => {
  const {
    mutate: requestPerformanceFormulaScreening,
    isLoading: requestPerformanceFormulaScreeningLoading,
  } = useBasicMutation<{
    artwork?: File;
    formulaScreeningId: number;
  }>({
    apiUrl: apiUrls.performanceArtworkScreeningRequest,
    paramNamesForUrl: ['formulaScreeningId'],
    useFormData: true,
  });

  const { mutate: requestFormulaScreening, isLoading: requestFormulaScreeningLoading } =
    useBasicMutation<{
      params?: { artwork?: File | null; packingType: PackingType }[];
      formulaScreeningId: number;
    }>({
      apiUrl: apiUrls.formulaArtworkScreeningRequest,
      paramNamesForUrl: ['formulaScreeningId'],
      useFormData: true,
    });

  const { mutate: cancelFormulaScreening, isLoading: cancelFormulaScreeningLoading } =
    useBasicMutation<{ formulaScreeningId: number }>({
      apiUrl: apiUrls.performanceArtworkScreeningRequest,
      paramNamesForUrl: ['formulaScreeningId'],
      method: 'DELETE',
    });
  const {
    mutate: cancelMandatoryFormulaScreening,
    isLoading: cancelMandatoryFormulaScreeningLoading,
  } = useBasicMutation<{ formulaScreeningId: number }>({
    apiUrl: apiUrls.formulaArtworkScreeningRequest,
    paramNamesForUrl: ['formulaScreeningId'],
    method: 'DELETE',
  });

  // 전성분_스크리닝_분석_요청_fsc013
  const { mutate: requestFormulaScreeningAgain, isLoading: requestFormulaScreeningAgainLoading } =
    useMutation(
      ({
        formulaScreeningArtworkImageId,
        formulaScreeningId,
      }: {
        formulaScreeningArtworkImageId: number;
        formulaScreeningId: number;
      }) =>
        client.put(
          `/v1/formula-screenings/${formulaScreeningId}/analyses?formulaScreeningArtworkImageId=${formulaScreeningArtworkImageId}`,
        ),
    );

  return {
    requestPerformanceFormulaScreening,
    requestPerformanceFormulaScreeningLoading,
    requestFormulaScreening,
    requestFormulaScreeningLoading,
    cancelFormulaScreening,
    cancelFormulaScreeningLoading,
    requestFormulaScreeningAgain,
    requestFormulaScreeningAgainLoading,
    cancelMandatoryFormulaScreening,
    cancelMandatoryFormulaScreeningLoading,
  };
};

export type ArtworkStep4TabType = 'formula' | 'requiredMsg';

export const useFormulaArtworkScreeningResult = ({
  formulaScreeningId,
}: {
  formulaScreeningId: number;
}) => {
  const location = useLocation();
  const { search } = location;
  const isPerformanceMeasurement =
    new URLSearchParams(search).get('isPerformanceMeasurement') === 'true';
  const isMandatoryScreening = new URLSearchParams(search).get('isMandatoryScreening') === 'true';
  const [selectedCountryCodes, setSelectedCountryCodes] = useState<string[]>([]);
  const [artworkIngredients, setArtworkIngredients] = useState<ArtworkIngredient[]>([]);
  const [tab, setTab] = useState<ArtworkStep4TabType>('formula');
  const [isShowResult, setIsShowResult] = useState<boolean>();

  // 검토 결과 수정된 성분 저장 (FSC010)
  const { mutate: reportIngredients } = useMutation(
    (
      params: {
        formulaScreeningAnalyzeItemId: number;
        ingredientName: string;
        additionalIngredientName?: string;
        isUpdate: boolean;
      }[],
    ) =>
      client.put(
        `/v1/formula-screenings/${formulaScreeningId}/analyses/update-ingredients`,
        params,
      ),
  );

  // 전성분 스크리닝 분석 결과 조회 (FSC009)
  const { data: artworkScreeningResult, isLoading: getArtworkScreeningResultLoading } =
    useBasicQuery<ArtworkScreeningResult>({
      apiUrl: apiUrls.formulaArtworkScreeningAnalyses,
      urlParams: {
        formulaScreeningId,
      },
      option: {
        cacheTime: 0,
        select: (res) => {
          const artworkScreeningResult = res.data.result;
          artworkScreeningResult.availableCountryCodes =
            artworkScreeningResult.availableCountryCodes.sort(
              (a, b) => countryOrder[a] - countryOrder[b],
            );
          return artworkScreeningResult;
        },
      },
    });

  const {
    analyzeResult = [],
    availableCountryCodes = [],
    rules = [],
  } = artworkScreeningResult || {};

  const uniqueSubCodes = Array.from(
    new Set(
      Object.values(
        rules.map((item) => ({
          ...item,
          countryRules:
            item.countryRules?.filter(({ countryCode }) =>
              selectedCountryCodes.includes(countryCode),
            ) || null,
        })),
      ).flatMap((rule) => rule.countryRules?.map((item) => item.ruleSubCode) || []),
    ),
  );

  const findAnalyzeItemId = ({
    imageIngredientId,
    ingredientId,
    formulaScreeningIngredientId,
    ingredientName,
  }: {
    imageIngredientId?: number | null;
    ingredientId?: number | null;
    formulaScreeningIngredientId?: number | null;
    ingredientName?: string | null;
  }) => {
    return (
      analyzeResult.find(
        (item) =>
          (imageIngredientId &&
            item.imageIngredient?.formulaScreeningArtworkImageIngredientId === imageIngredientId) ||
          (formulaScreeningIngredientId &&
            item.ingredient?.formulaScreeningIngredientId === formulaScreeningIngredientId) ||
          (ingredientId && item.ingredient?.ingredientId === ingredientId) ||
          (ingredientName && item.ingredient?.ingredientName === ingredientName),
      )?.formulaScreeningAnalyzeItemId || -1
    );
  };

  // 최종적으로 오류 수정사항이 적용된 성분 리스트
  const updatedArtworkIngredients: ArtworkIngredient[] = useMemo(() => {
    const existedNames = new Set<string>();

    return (
      cloneDeep(artworkIngredients)
        .filter((item) =>
          // 1. 표기 오류거나 함량 순서 오류인 경우
          // 2. 미기입 + 수정된 경우
          // 3. 매칭 결과 없음 + 수정 안 된 경우
          {
            return item.errors.every((error) => {
              const containCheckAndMiss =
                item.errors.some((err) => err.ruleType === RuleType.CHECK) &&
                item.errors.some((err) => err.ruleType === RuleType.MISS);
              const containCheckAndTypo =
                item.errors.some((err) => err.ruleType === RuleType.CHECK) &&
                item.errors.some((err) => err.ruleType === RuleType.TYPO);
              const containUpdated = item.errors.some((err) => err.isUpdate);

              const typeCondition = containCheckAndTypo
                ? error.ruleType === RuleType.TYPO || error.ruleType === RuleType.CHECK
                : error.ruleType === RuleType.TYPO;

              const result =
                typeCondition ||
                error.ruleType === RuleType.ORDER ||
                (error.ruleType === RuleType.NOT_MATCH && !error.isUpdate) ||
                (containUpdated
                  ? containCheckAndMiss
                    ? (error.ruleType === RuleType.CHECK && error.isUpdate) ||
                      error.ruleType === RuleType.MISS
                    : (error.ruleType === RuleType.MISS && error.isUpdate) ||
                      error.ruleType === RuleType.CHECK
                  : (error.ruleType === RuleType.MISS && error.isUpdate) ||
                    error.ruleType === RuleType.CHECK);

              return result;
            });
          },
        )
        // 중복 오류 제거 필터링
        .filter((item) => {
          const updateName = item.updatedName;
          if (
            existedNames.has(updateName) &&
            updateName !== '' &&
            item.errors.every((error) => error.ruleType === RuleType.TYPO)
          ) {
            return false;
          } else {
            existedNames.add(updateName);
            return true;
          }
        })
        // 함량 순서 우선순위에 따라 정렬
        .sort((a, b) => a.order - b.order)
        .flatMap((item) => [...(item.preChildren || []), item, ...(item.postChildren || [])])
        // 중복 id 있을 경우 제거
        .reduce((acc: ArtworkIngredient[], curr) => {
          const findCheckMissIdx = acc.findIndex((existing) => {
            return (
              existing.errors.some((err) => err.ruleType === RuleType.CHECK) &&
              existing.errors.some((err) => err.ruleType === RuleType.MISS) &&
              existing.id === curr.id &&
              (existing.currUpdatedRuleType === RuleType.MISS
                ? existing.errors.some(
                    (err) =>
                      (err.ruleType === RuleType.MISS && err.isUpdate) ||
                      err.ruleType === RuleType.CHECK,
                  )
                : existing.errors.some(
                    (err) =>
                      (err.ruleType === RuleType.CHECK && err.isUpdate) ||
                      err.ruleType === RuleType.MISS,
                  ))
            );
          });

          if (findCheckMissIdx !== -1) {
            acc[findCheckMissIdx] = curr;
            return acc;
          }

          const isDuplicate = acc.some((existing: ArtworkIngredient) => existing?.id === curr?.id);

          if (!isDuplicate) {
            acc.push(curr);
          } else {
            return acc;
          }
          return acc;
        }, [])
    );
  }, [artworkIngredients]);

  const handleReport = useCallback(
    (finalData: ArtworkIngredient[]) => {
      const finalArtworkIngredients = finalData
        .filter((item) =>
          item.errors.every(
            (error) =>
              error.ruleType === RuleType.TYPO ||
              error.ruleType === RuleType.ORDER ||
              (error.ruleType === RuleType.NOT_MATCH && !error.isUpdate) ||
              (error.ruleType === RuleType.MISS && error.isUpdate) ||
              error.ruleType === RuleType.CHECK,
          ),
        )
        // 함량 순서 우선순위에 따라 정렬
        .sort((a, b) => a.order - b.order)
        .flatMap((item) => [...(item.preChildren || []), item, ...(item.postChildren || [])])
        .reduce((acc: ArtworkIngredient[], curr) => {
          const isDuplicate = acc.some((existing: ArtworkIngredient) => existing?.id === curr?.id);

          if (!isDuplicate) {
            acc.push(curr);
          } else {
            return acc;
          }
          return acc;
        }, []);

      const params = finalArtworkIngredients.map((item) => {
        const isUpdate = item.errors.length > 0 && item.errors.every(({ isUpdate }) => isUpdate);
        const matchDirectName = Object.values(item.newDirectName).find(
          (name) => name && name !== '',
        );

        return {
          formulaScreeningAnalyzeItemId: item.id,
          ingredientName: matchDirectName || item.updatedName || item.name,
          additionalIngredientName: item.additionalIngredientName,
          isUpdate,
        };
      });
      reportIngredients(params);
    },
    [reportIngredients],
  );

  const filteredCountries = availableCountryCodes.filter(
    (code) => code !== 'ETC' && code !== 'CN' && code !== 'JP',
  );

  useEffect(() => {
    if (!isMandatoryScreening && !isPerformanceMeasurement) {
      setSelectedCountryCodes(availableCountryCodes);
      return;
    }
  }, [isMandatoryScreening, isPerformanceMeasurement]);

  useEffect(() => {
    setIsShowResult(false);
    setSelectedCountryCodes([]);
  }, [tab]);

  useEffect(() => {
    const initialArtworkIngredients: ArtworkIngredient[] = analyzeResult.map(
      (
        { formulaScreeningAnalyzeItemId, ingredient, imageIngredient, analysisItems, allergen },
        idx,
      ) => {
        const typoErrors =
          analysisItems?.[RuleType.TYPO]?.map((item) => ({ ...item, ruleType: RuleType.TYPO })) ||
          [];
        const orderErrors =
          analysisItems?.[RuleType.ORDER]?.map((item) => ({ ...item, ruleType: RuleType.ORDER })) ||
          [];
        const missErrors =
          analysisItems?.[RuleType.MISS]?.map((item) => ({ ...item, ruleType: RuleType.MISS })) ||
          [];
        const notMatchErrors =
          analysisItems?.[RuleType.NOT_MATCH]?.map((item) => ({
            ...item,
            ruleType: RuleType.NOT_MATCH,
          })) || [];
        const checkErrors =
          analysisItems?.[RuleType.CHECK]?.map((item) => ({
            ...item,
            ruleType: RuleType.CHECK,
          })) || [];

        //중복표기 오류가 포함된 성분일 경우 합쳐서 에러를 보여줌
        const containDuplicateTypoErrors = () => {
          const dupicateErrIdx = typoErrors.findIndex(
            ({ postIngredient }) =>
              !isArray(postIngredient) &&
              postIngredient?.ruleSubCode.includes(RuleSubCode.FCAR01_01),
          );
          const filtered =
            dupicateErrIdx !== -1
              ? typoErrors.filter((_, index) => index !== dupicateErrIdx)
              : typoErrors;
          return typoErrors.length > 1 && typoErrors[dupicateErrIdx]
            ? filtered.map((item) => {
                const postArr = [...(isArray(item?.postIngredient) ? item?.postIngredient : [])];
                const duplicatePostIngredient = typoErrors[dupicateErrIdx]
                  ?.postIngredient as PostIngredient;
                if (duplicatePostIngredient) {
                  postArr.unshift(duplicatePostIngredient);
                }
                return {
                  ...item,
                  preIngredient: typoErrors[dupicateErrIdx]?.preIngredient,
                  postIngredient: postArr.map((item) => {
                    const description = `${
                      duplicatePostIngredient ? `${duplicatePostIngredient.description}\n` : ''
                    }${item.description}`;
                    return {
                      ...item,
                      description,
                    };
                  }),
                };
              })
            : typoErrors;
        };

        const errors = [
          ...containDuplicateTypoErrors(),
          ...orderErrors,
          ...missErrors,
          ...notMatchErrors,
          ...checkErrors,
        ];

        const newErrors = errors.map((error) => {
          const preIngredient: ArtworkPreIngredient = {
            pre: {
              id: findAnalyzeItemId({
                imageIngredientId: error.pre?.formulaScreeningArtworkImageIngredientId,
                ingredientId: error.pre?.ingredientId,
              }),
              name: getIngerdientNameWithAdditional({
                ingredientName: error.pre?.ingredientName,
                additionalIngredientName: error.pre?.additionalIngredientName,
                additionalIngredientNameSeparator: error.pre?.additionalIngredientNameSeparator,
              }),
            },
            center:
              error.preIngredient === null
                ? []
                : isArray(error.preIngredient)
                ? allergen
                  ? error.preIngredient.map((preIngredientItem) => ({
                      id:
                        analyzeResult.find(
                          (item) =>
                            item.allergen?.ingredientInciName === preIngredientItem.ingredientName,
                        )?.formulaScreeningAnalyzeItemId || -1,
                      name:
                        analyzeResult.find(
                          (item) =>
                            item.allergen?.ingredientInciName === preIngredientItem.ingredientName,
                        )?.allergen?.ingredientInciName || '',
                    }))
                  : error.preIngredient.map((item) => ({
                      id: findAnalyzeItemId({
                        imageIngredientId: item.formulaScreeningArtworkImageIngredientId,
                        ingredientId: item.ingredientId,
                        ingredientName: item.ingredientName,
                      }),
                      name: getIngerdientNameWithAdditional({
                        ingredientName: item.ingredientName,
                        additionalIngredientName: item.additionalIngredientName,
                        additionalIngredientNameSeparator: item.additionalIngredientNameSeparator,
                      }),
                    }))
                : [
                    {
                      id: findAnalyzeItemId({
                        imageIngredientId:
                          error.preIngredient.formulaScreeningArtworkImageIngredientId,
                        ingredientId: error.preIngredient.ingredientId,
                      }),
                      name: getIngerdientNameWithAdditional({
                        ingredientName: error.preIngredient.ingredientName,
                        additionalIngredientName: error.preIngredient.additionalIngredientName,
                        additionalIngredientNameSeparator:
                          error.preIngredient.additionalIngredientNameSeparator,
                      }),
                    },
                  ],
            post: {
              id: findAnalyzeItemId({
                imageIngredientId: error.post?.formulaScreeningArtworkImageIngredientId,
                ingredientId: error.post?.ingredientId,
              }),
              name: getIngerdientNameWithAdditional({
                ingredientName: error.post?.ingredientName,
                additionalIngredientName: error.post?.additionalIngredientName,
                additionalIngredientNameSeparator: error.post?.additionalIngredientNameSeparator,
              }),
            },
          };

          const postIngredients =
            error.postIngredient === null
              ? []
              : isArray(error.postIngredient)
              ? error.postIngredient.filter((item) =>
                  error.ruleType === RuleType.CHECK
                    ? (item as PostIngredient)?.updateIngredient
                    : (item as PostIngredient)?.updateIngredient?.every(
                        ({ ingredientName }: { ingredientName: string }) =>
                          ingredientName !== null && ingredientName !== '',
                      ),
                )
              : [error.postIngredient];

          const lastMatchedPostIngredient = findLastMatchingItem(
            postIngredients,
            uniqueSubCodes,
            error.ruleType,
          );

          const postIngredient: ArtworkPostIngredient | null =
            lastMatchedPostIngredient === null
              ? null
              : {
                  pre: {
                    id: findAnalyzeItemId({
                      imageIngredientId:
                        lastMatchedPostIngredient.pre?.formulaScreeningArtworkImageIngredientId,
                      ingredientId: lastMatchedPostIngredient.pre?.ingredientId,
                    }),
                    name: getIngerdientNameWithAdditional({
                      ingredientName: lastMatchedPostIngredient.pre?.ingredientName,
                      additionalIngredientName:
                        lastMatchedPostIngredient.pre?.additionalIngredientName,
                      additionalIngredientNameSeparator:
                        lastMatchedPostIngredient.pre?.additionalIngredientNameSeparator,
                    }),
                  },
                  center: allergen
                    ? postIngredients
                        .flatMap(({ updateIngredient }) => updateIngredient)
                        .map((updateIngredientItem) => ({
                          id:
                            analyzeResult.find(
                              (item) =>
                                item.allergen?.ingredientInciName ===
                                updateIngredientItem?.ingredientName,
                            )?.formulaScreeningAnalyzeItemId || -1,
                          name:
                            analyzeResult.find(
                              (item) =>
                                item.allergen?.ingredientInciName ===
                                updateIngredientItem?.ingredientName,
                            )?.allergen?.ingredientInciName || '',
                        }))
                    : lastMatchedPostIngredient?.updateIngredient?.map((item) => ({
                        id: findAnalyzeItemId({
                          imageIngredientId: item.formulaScreeningArtworkImageIngredientId,
                          formulaScreeningIngredientId: item.formulaScreeningArtworkIngredientId,
                        }),
                        name: getIngerdientNameWithAdditional({
                          ingredientName: item.ingredientName,
                          additionalIngredientName: item.additionalIngredientName,
                          additionalIngredientNameSeparator: item.additionalIngredientNameSeparator,
                        }),
                      })) || [],
                  post: {
                    id: findAnalyzeItemId({
                      imageIngredientId:
                        lastMatchedPostIngredient.post?.formulaScreeningArtworkImageIngredientId,
                      ingredientId: lastMatchedPostIngredient.post?.ingredientId,
                    }),
                    name: getIngerdientNameWithAdditional({
                      ingredientName: lastMatchedPostIngredient.post?.ingredientName,
                      additionalIngredientName:
                        lastMatchedPostIngredient.post?.additionalIngredientName,
                      additionalIngredientNameSeparator:
                        lastMatchedPostIngredient.post?.additionalIngredientNameSeparator,
                    }),
                  },
                };

          const countryRules = error.countryRules;

          const sortedSelectedCountryCodes = selectedCountryCodes.sort((a: string, b: string) => {
            const indexA = availableCountryCodes?.indexOf(a);
            const indexB = availableCountryCodes?.indexOf(b);

            return indexA - indexB;
          });

          const countryModalRules = sortedSelectedCountryCodes.reduce((acc, countryCode) => {
            if (countryRules?.[countryCode]) {
              acc[countryCode] = countryRules[countryCode];
            }
            return acc;
          }, {} as CountryRules);

          const groupedByRules: {
            [rulesKey: string]: {
              countryCodes: string[];
              descriptions: string[];
            };
          } = {};

          for (const countryCode in countryModalRules) {
            const rules = countryModalRules[countryCode];
            const rulesKey = JSON.stringify(rules.map((rule) => rule.subCode).sort());

            if (!groupedByRules[rulesKey]) {
              groupedByRules[rulesKey] = {
                descriptions: rules.map((item) => item.description),
                countryCodes: [],
              };
            }
            groupedByRules[rulesKey].countryCodes.push(countryCode);
          }

          const groupedCountryRules: {
            countryCodes: string[];
            descriptions: string[];
          }[] = Object.values(groupedByRules);

          return {
            ruleType: error.ruleType,
            ruleCode: error.ruleCode,
            preIngredient,
            postIngredient,
            //색소 일경우 마지막 postIngredient에 대한 description을 고정으로 노출
            descriptions:
              error.ruleType === RuleType.CHECK
                ? postIngredients[postIngredients.length - 1]?.description.split('\n') || []
                : lastMatchedPostIngredient?.description.split('\n') || [],
            countryRules: groupedCountryRules,
            isDuplicate:
              lastMatchedPostIngredient?.ruleSubCode.includes(RuleSubCode.FCAR01_01) || false,
            isUpdate: false,
          };
        });

        return {
          id: formulaScreeningAnalyzeItemId,
          imageIngredient,
          name: allergen
            ? allergen.ingredientInciName
            : imageIngredient
            ? getIngerdientNameWithAdditional({
                ingredientName: imageIngredient?.ingredientName,
                additionalIngredientName: imageIngredient?.additionalIngredientName,
                additionalIngredientNameSeparator:
                  imageIngredient?.additionalIngredientNameSeparator,
              }) || ''
            : ingredient?.ingredientName || '',
          nameExceptAdditional: imageIngredient?.ingredientName || ingredient?.ingredientName || '',
          additionalIngredientName: imageIngredient?.additionalIngredientName || '',
          updatedName: '',
          newDirectName: errors.reduce((acc, { ruleType }) => {
            acc[ruleType] = '';
            return acc;
          }, {} as Record<RuleType, string>),
          coordinates: imageIngredient?.coordinates || [],
          errors: newErrors.filter((error) => error.postIngredient !== null),
          initialOrder: idx,
          order: idx,
          preChildren: [],
          postChildren: [],
          allergen,
        };
      },
    );

    setArtworkIngredients(initialArtworkIngredients);
  }, [artworkScreeningResult, selectedCountryCodes, isShowResult]);

  return {
    analyzeResult,
    artworkScreeningResult,
    getArtworkScreeningResultLoading,
    availableCountryCodes:
      tab === 'requiredMsg' || isPerformanceMeasurement ? filteredCountries : availableCountryCodes,
    selectedCountryCodes,
    setSelectedCountryCodes,
    artworkIngredients,
    setArtworkIngredients,
    updatedArtworkIngredients,
    isPerformanceMeasurement,
    handleReport,
    tab,
    setTab,
    isShowResult,
    setIsShowResult,
  };
};

//전성분 스크리닝 분석 결과 조회 (FSC008)
export const useFormulaArtworkScreeningAnalyses = () => {
  const {
    mutate: formulaArtworkScreeningAnalyses,
    isLoading: formulaArtworkScreeningAnalysesLoading,
  } = useBasicMutation<{
    formulaScreeningId: number;
  }>({
    method: 'get',
    apiUrl: apiUrls.formulaArtworkScreeningAnalyses,
    paramNamesForUrl: ['formulaScreeningId'],
  });

  return {
    formulaArtworkScreeningAnalyses,
    formulaArtworkScreeningAnalysesLoading,
  };
};

export const useFormulaScreeningArtworkInquiry = () => {
  // 전성분 스크리닝 문의 등록 (로그인 필요) (FSC010)
  const {
    mutate: registerFormulaScreeningArtworkInquiry,
    isLoading: registerFormulaScreeningArtworkInquiryLoading,
  } = useBasicMutation<{
    formulaScreeningId: number;
    formulaScreeningAnalyzeItemId?: number;
    errorType?: InquiryErrorType;
    countryCodes?: string[];
    content: string;
  }>({
    apiUrl: apiUrls.formulaArtworkScreeningInquiry,
    paramNamesForUrl: ['formulaScreeningId'],
  });

  return {
    registerFormulaScreeningArtworkInquiry,
    registerFormulaScreeningArtworkInquiryLoading,
  };
};

export const useFormulaScreeningArtworkReport = ({
  languageCode,
}: {
  languageCode: TranslateLanguageCode;
}) => {
  // 성분 리스트 번역 (SDC08)
  const { mutate: translateIngredients, isLoading: translateIngredientsLoading } = useBasicMutation<
    {
      name: string;
    }[],
    {
      ingredientId: number | null;
      name: string;
      translateName: string | null;
    }[]
  >({
    isInciApi: true,
    apiUrl: `${inciApiUrls.translateIngredients}?languageCode=${languageCode}`,
  });

  return {
    translateIngredients,
    translateIngredientsLoading,
  };
};

export const usePerformanceMeasurement = () => {
  // 1. 성과 측정 시작 (AFSPM001)
  const { mutate: startPerformanceMeasurement, isLoading: startPerformanceMeasurementLoading } =
    useBasicMutation<{
      formulaScreeningId: number;
      measurementMethod: string;
    }>({
      apiUrl: apiUrls.artworkPerformanceMeasurementStart,
      paramNamesForUrl: ['formulaScreeningId'],
    });
  // 성과 측정 종료 (AFSPM002)

  const { mutate: endPerformanceMeasurement, isLoading: endPerformanceMeasurementLoading } =
    useBasicMutation<{
      formulaScreeningId: number;
      measurementMethod: string;
      countryCodes: string[];
      finalIngredientList: string;
    }>({
      apiUrl: apiUrls.artworkPerformanceMeasurementEnd,
      paramNamesForUrl: ['formulaScreeningId'],
      option: {
        onMutate() {
          message.success('테스트가 완료되었습니다.');
        },
      },
    });
  return {
    startPerformanceMeasurement,
    startPerformanceMeasurementLoading,
    endPerformanceMeasurement,
    endPerformanceMeasurementLoading,
  };
};

export const usePerformaceMeasurementList = (adminToken?: string | null) => {
  const [performaceMeasurementList, setPerformaceMeasurementList] =
    useState<PerformaceMeasurementItem[]>();
  //유저가 직접 측정한 리스트 출력 api (AFSPM007)
  const { mutate: getPerformaceMeasurementList, isLoading: performaceMeasurementListLoading } =
    useMutation(
      () =>
        client.get<APIResponse<PerformaceMeasurementItem[]>>(
          apiUrls.artworkPerformanceMeasurementList,
        ),
      {
        onSuccess: (res) => setPerformaceMeasurementList(res.data.result),
      },
    );

  const { mutate: downloadFile, isLoading: downloadFileLoading } = useMutation(
    (formulaScreeningId: number) =>
      client.post(
        `/admin/formula-screening/${formulaScreeningId}/document`,
        {},
        { responseType: 'blob' },
      ),

    {
      onSuccess: (res: any) => {
        const blobFile = res.data;

        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blobFile);
        a.download = decodeURIComponent(`전성분 성과 측정.zip`);
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    },
  );

  useEffect(() => {
    if (adminToken) {
      getPerformaceMeasurementList();
    }
  }, [adminToken]);

  return {
    performaceMeasurementList,
    performaceMeasurementListLoading,
    downloadFile,
    downloadFileLoading,
  };
};

export const useArtworkMandatory = ({ formulaScreeningId }: { formulaScreeningId: number }) => {
  const [artworkMandatoryResult, setArtworkMandatoryResult] =
    useState<MandatoryFieldsResult | null>(null);

  // 전성분 스크리닝 아트웍 정보 조회 (로그인 필요) (FSC015)
  const { mutate: artworkScreeningMandatory } = useMutation(
    ({ countryCodes }: { countryCodes: string[] }) =>
      client.put<APIResponse<MandatoryFieldsResult>>(
        `/v2/formula-screenings/${formulaScreeningId}/mandatory-fields/analyses`,
        { countryCodes },
      ),
    {
      onSuccess: (res) => {
        setArtworkMandatoryResult(res.data.result);
      },
    },
  );

  return useMemo(
    () => ({ artworkMandatoryResult, artworkScreeningMandatory }),
    [artworkMandatoryResult, artworkScreeningMandatory],
  );
};

export const useLoginByRefreshToken = () => {
  const { mutate: loginByRefreshToken, isLoading: isLoginLoading } = useMutation(
    (refreshToken: string) => client.post(`/admin/auth/login/auto`, { refreshToken }),
    {
      onSuccess: ({ data: { result: user } }) => {
        const { token, refreshToken } = user;

        setToken(token, refreshToken);
        client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      },
      onError: () => {
        message.error('올바르지 않은 접근입니다.');
        window.open(
          `${
            process.env.REACT_APP_SERVER === 'DEV'
              ? `https://devadmin.certicos.pro/`
              : 'https://admin.certicos.pro/'
          }?fromServiceArtwork=true`,
          '_blank',
        );
      },
    },
  );

  return { loginByRefreshToken, isLoginLoading };
};
