import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Spin } from 'antd';

import { Typography } from 'components/system';
import palette from 'lib/styles/palette';
import { MandatoryCoordinate } from 'types/brand/artworkScreening/artworkScreening';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';

const MandatoryArtworkImage = ({
  url,
  fieldCoordinates,
}: {
  url?: string;
  fieldCoordinates?: MandatoryCoordinate[];
}) => {
  const [isImgLoading, setIsImgLoading] = useState(true);
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const [imgOriginalSize, setImgOriginalSize] = useState({
    width: 1,
    height: 1,
  });
  const [imageWrapperSize, setImageWrapperSize] = useState({
    width: 0,
    height: 0,
  });
  const [imageSize, setImageSize] = useState({ width: 1, height: 1 });

  const [imageScale, setImageScale] = useState(1);
  const [isDragging, setIsDragging] = useState(false);
  const [, setScrollPosition] = useState({ x: 0, y: 0 });
  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });

  const containerRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  const aspectRatio =
    imgOriginalSize.height === 0 ? 1 : imgOriginalSize.width / imgOriginalSize.height;
  const containerRatio = imageSize.height ? imageSize.width / imageSize.height : 1;
  const scale =
    aspectRatio > containerRatio
      ? imageSize.width / imgOriginalSize.width
      : imageSize.height / imgOriginalSize.height;
  const newWidth = imgOriginalSize.width * scale;
  const newHeight = imgOriginalSize.height * scale;
  const imgOffsetX = (imageSize.width - newWidth) / 2;
  const imgOffsetY = (imageSize.height - newHeight) / 2;
  const maxScrollWidth = imageSize.width - imageWrapperSize.width + 32;
  const maxScrollHeight = imageSize.height - imageWrapperSize.height + 32;

  useEffect(() => {
    setImageScale(1);
  }, [url]);

  const handleScroll = () => {
    setScrollPosition({
      x: imageWrapperRef.current?.scrollLeft || 0,
      y: imageWrapperRef.current?.scrollTop || 0,
    });
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    setIsDragging(true);
    setDragPosition({ x: e.clientX, y: e.clientY });
    e.preventDefault();
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    if (isDragging) {
      const dx = e.clientX - dragPosition.x;
      const dy = e.clientY - dragPosition.y;
      setDragPosition({ x: e.clientX, y: e.clientY });
      changeScrollPosition(dx, dy);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const changeScrollPosition = (dx: number, dy: number) => {
    setScrollPosition((prev) => {
      let newX = prev.x - dx;
      let newY = prev.y - dy;

      if (imageWrapperRef.current) {
        imageWrapperRef.current.scrollLeft = newX;
        imageWrapperRef.current.scrollTop = newY;
      }

      return {
        ...prev,
        x: newX < 0 ? 0 : newX > maxScrollWidth ? maxScrollWidth : newX,
        y: newY < 0 ? 0 : newY > maxScrollHeight ? maxScrollHeight : newY,
      };
    });
  };

  useEffect(() => {
    if (isImgLoaded) {
      const width = imageRef.current?.naturalWidth || 0;
      const height = imageRef.current?.naturalHeight || 0;
      setImgOriginalSize({
        width,
        height,
      });
    }
  }, [isImgLoaded, fieldCoordinates]);

  useEffect(() => {
    const updateSizes = () => {
      setImageWrapperSize({
        width: imageWrapperRef.current?.clientWidth || 0,
        height: imageWrapperRef.current?.clientHeight || 0,
      });
      setImageSize({
        width: imageRef.current?.clientWidth || 0,
        height: imageRef.current?.clientHeight || 0,
      });
    };

    const containerObserver = new ResizeObserver(updateSizes);
    const imageWrapperObserver = new ResizeObserver(updateSizes);
    const imageObserver = new ResizeObserver(updateSizes);

    if (containerRef.current) {
      containerObserver.observe(containerRef.current);
    }
    if (imageWrapperRef.current) {
      imageWrapperObserver.observe(imageWrapperRef.current);
    }
    if (imageRef.current) {
      imageObserver.observe(imageRef.current);
    }

    updateSizes();

    return () => {
      containerObserver.disconnect();
      imageWrapperObserver.disconnect();
      imageObserver.disconnect();
    };
  }, []);

  return (
    <Container
      onMouseLeave={() => {
        setIsDragging(false);
      }}
    >
      <Header>
        <Typography.Text type="BODY_1" color="SLATE_GRAY70" style={{ fontWeight: 500 }}>
          Artwork 이미지
        </Typography.Text>
      </Header>
      <Spin spinning={isImgLoading}>
        <ImageWrapper
          ref={imageWrapperRef}
          style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
          onScroll={handleScroll}
        >
          <Image
            ref={imageRef}
            imageScale={imageScale}
            src={url}
            onLoad={() => {
              setIsImgLoaded(true);
              setIsImgLoading(false);
            }}
            alt="ingredients"
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            style={{
              cursor: isDragging ? 'grabbing' : 'grab',
              visibility: isImgLoading ? 'hidden' : 'visible',
            }}
          />
          {fieldCoordinates?.map((coordinate, idx) => {
            const x1 = Math.min(coordinate.pointX1);
            const y1 = Math.min(coordinate.pointY1);
            const x2 = Math.max(coordinate.pointX2);
            const y2 = Math.max(coordinate.pointY2);

            const [imgX1, imgX2, imgY1, imgY2] = [
              x1 * scale + imgOffsetX,
              x2 * scale + imgOffsetX,
              y1 * scale + imgOffsetY,
              y2 * scale + imgOffsetY,
            ];

            return (
              <HighlightedArea
                key={idx}
                style={{
                  top: imgY1 + 8,
                  left: imgX1 + 8,
                  width: imgX2 - imgX1,
                  height: imgY2 - imgY1,
                }}
              />
            );
          })}
        </ImageWrapper>
      </Spin>

      <ZoomController onMouseUp={handleMouseUp} justify="center" gap={14}>
        <Icon
          size={18}
          color="GRAY70"
          name="zoomOut"
          onClick={() => setImageScale(imageScale > 0.5 ? imageScale - 0.5 : 0.5)}
        />
        <Typography.Text color="GRAY70" type="SMALL" align="center" style={{ width: 30 }}>
          {imageScale * 50}%
        </Typography.Text>
        <Icon
          size={18}
          color="GRAY70"
          name="zoomIn"
          onClick={() => setImageScale(imageScale < 4 ? imageScale + 0.5 : 4)}
        />
      </ZoomController>
    </Container>
  );
};

const Container = styled.div`
  width: 648px;
  height: 792px;
  margin-top: 16px;
  position: relative;
`;

const Header = styled.div`
  width: 100%;
  padding: 17px 24px;
  background-color: ${palette.SLATE_GRAY10};
  border-radius: 8px 8px 0px 0px;
  border: 1px solid ${palette.GRAY30};
`;

const ImageWrapper = styled.div`
  padding: 8px;
  height: 736px;
  overflow: scroll;
  position: relative;
  border: 1px solid ${palette.GRAY30};
  border-top: none;
  border-radius: 0px 0px 8px 8px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Image = styled.img<{ imageScale: number }>`
  height: ${({ imageScale }) => `${100 * imageScale}%`};
  width: ${({ imageScale }) => `${100 * imageScale}%`};
  object-fit: contain;
  object-position: center center;
  cursor: grab;
`;

const HighlightedArea = styled.div`
  position: absolute;
  background: rgba(44, 244, 240, 0.3);
  border: 1px dashed ${palette.ETC_HIGHLIGHTING};
  pointer-events: none;
`;

const ZoomController = styled(Flex)`
  position: absolute;
  bottom: 24px;
  transform: translateX(-50%);
  left: 50%;
  border-radius: 4px;
  border: 1px solid ${palette.GRAY30};
  background: #fff;
  user-select: none;
  width: 126px;
  height: 40px;
`;

export default MandatoryArtworkImage;
