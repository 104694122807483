import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { Typography } from 'components/system';
import { Flex } from 'components/ui';
import Icon from 'components/ui/Icon/Icon';
import { useModal } from 'hook/useModal';
import palette from 'lib/styles/palette';
import {
  ArtworkIngredient,
  ArtworkScreeningResult,
  IngredientCoordinate,
} from 'types/brand/artworkScreening/artworkScreening';
import FullLoading from 'components/FullLoading';
import ArtworkIngredientList from './ArtworkIngredientList';
import IngredientImageModal from './IngredientImageModal';
import UpdatedIngredientList from './UpdatedIngredientList';
import ReportModalContent from './ReportModalContent';
import { scrollToText } from 'lib/artworkScreening';

const FormulaScreeningResult = ({
  formulaScreeningId,
  isAdmin,
  artworkIngredients,
  artworkScreeningResult,
  selectedCountryCodes,
  updatedArtworkIngredients,
  setArtworkIngredients,
  handleReport,
}: {
  formulaScreeningId: number;
  isAdmin: boolean;
  artworkIngredients: ArtworkIngredient[];
  artworkScreeningResult?: ArtworkScreeningResult;
  selectedCountryCodes: string[];
  updatedArtworkIngredients: ArtworkIngredient[];
  setArtworkIngredients: React.Dispatch<React.SetStateAction<ArtworkIngredient[]>>;
  handleReport: (finalData: ArtworkIngredient[]) => void;
}) => {
  /**
   * ingredientCoordinate: 이미지 모달의 성분 좌표
   * targetRect: overflow로 인한 툴팁 잘림을 막기 위해 툴팁을 fixed로 표시. 마우스 올렸을 때의 툴팁위치를 저장하기 위한 상태
   * isMinimize: 이미지 모달 최소화 여부
   * clickedIngredientArea: 성분 클릭시 스크롤하기 위한 아이디 저장용 상태
   */
  const [ingredientCoordinate, setIngredientCoordinate] = useState<IngredientCoordinate[]>([]);
  const [targetRect, setTargetRect] = useState<DOMRect>();
  const [isMinimize, setIsMinimize] = useState(false);
  const [clickedIngredientArea, setClickedIngredientArea] = useState<{
    type?: 'formulaScreeningAnalyzeItemId' | 'formulaScreeningArtworkImageIngredientId';
    id?: number;
  }>({});

  const itemsRef = useRef<Map<number, HTMLDivElement>>(new Map());
  const { openConfirmModal } = useModal();
  const errorCount = artworkIngredients.filter((item) => item.errors.length > 0).length;

  /**
   * (성분 클릭시)
   * 1. 해당 성분으로 스크롤
   * 2. 해당 성분 이미지 모달에 표시
   */
  useEffect(() => {
    if (!clickedIngredientArea.type || !clickedIngredientArea.id) {
      return;
    }

    if (clickedIngredientArea.type === 'formulaScreeningAnalyzeItemId') {
      const targetIngredient = artworkIngredients.find(
        (item) => item.id === clickedIngredientArea.id,
      );
      const target = itemsRef.current.get(clickedIngredientArea.id);
      scrollToText({ target, targetIngredient });
    } else {
      const targetIngredient = artworkIngredients.find(
        (item) =>
          item.imageIngredient?.formulaScreeningArtworkImageIngredientId ===
          clickedIngredientArea.id,
      );
      const formulaScreeningAnalyzeItemId = targetIngredient?.id;
      if (formulaScreeningAnalyzeItemId) {
        const target = itemsRef.current.get(formulaScreeningAnalyzeItemId);
        scrollToText({ target, targetIngredient });
        setIngredientCoordinate(targetIngredient.coordinates);
        setIsMinimize(false);
      }
    }
  }, [clickedIngredientArea]);

  if (!artworkScreeningResult) {
    return <FullLoading />;
  }

  if (!artworkScreeningResult) {
    return <FullLoading />;
  }

  return (
    <>
      <Line />
      <ArtworkScreeningResultContainer gap={8} gutter={{ top: 32 }}>
        {/* Artwork 전성분 리스트 */}
        <ArtworkIngredientList
          formulaScreeningId={formulaScreeningId}
          targetRect={targetRect}
          setTargetRect={setTargetRect}
          artworkIngredients={artworkIngredients}
          setClickedIngredientArea={setClickedIngredientArea}
          errorCount={errorCount}
          setArtworkIngredients={setArtworkIngredients}
          selectedCountryCodes={selectedCountryCodes}
          handleReport={handleReport}
        />
        {/* 구분선 */}
        <Divider dir="column" gap={24}>
          <VerticalLine />
          <FrontIconWrapper>
            <Icon name="front" size={18} color="GRAY40" />
          </FrontIconWrapper>
          <VerticalLine />
        </Divider>
        {/* 적용 리스트 */}
        <UpdatedIngredientList
          artworkIngredients={artworkIngredients}
          updatedArtworkIngredients={updatedArtworkIngredients}
          setArtworkIngredients={setArtworkIngredients}
          targetRect={targetRect}
          setTargetRect={setTargetRect}
          errorCount={errorCount}
          clickedIngredientArea={clickedIngredientArea}
          setIngredientCoordinate={setIngredientCoordinate}
          setClickedIngredientArea={setClickedIngredientArea}
          setIsMinimize={setIsMinimize}
          itemsRef={itemsRef}
        />
      </ArtworkScreeningResultContainer>
      {/* 검토 결과 확인하기 */}
      <ReportModalDescription justify="center" gap={16}>
        <Typography.Text color="SLATE_GRAY70">
          검토 내용을 비교하고 언어별로 확인해 보세요.
        </Typography.Text>
        <Typography.Text
          color="PRIMARY50"
          semiBold
          underline
          onClick={() => {
            openConfirmModal({
              title: '',
              width: 832,
              content: (
                <ReportModalContent
                  artworkIngredients={artworkIngredients}
                  updatedArtworkIngredients={updatedArtworkIngredients}
                />
              ),
              footer: null,
            });
          }}
          style={{ padding: '2px 4px', textUnderlineOffset: 2 }}
        >
          확인하기
        </Typography.Text>
      </ReportModalDescription>
      {/* 전성분 이미지 모달 */}
      <IngredientImageModal
        ingredientCoordinate={ingredientCoordinate}
        url={artworkScreeningResult.artworkImage.url}
        isMinimize={isMinimize}
        setIsMinimize={setIsMinimize}
      />
    </>
  );
};

const ArtworkScreeningResultContainer = styled(Flex)`
  .ant-empty-normal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 736px;
    margin: 0;
  }
`;

const Divider = styled(Flex)`
  flex: 0 0 40px;
  align-self: stretch;
  margin-top: 28px;
`;

const VerticalLine = styled.div`
  flex: 1;
  width: 1px;
  background-color: ${palette.GRAY30};
`;

const FrontIconWrapper = styled(Flex)`
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid ${palette.GRAY40};
`;

const ReportModalDescription = styled(Flex)`
  margin-top: 16px;
  height: 80px;
  background-color: ${palette.PRIMARY10};
  border-radius: 8px;
`;

const Line = styled.div`
  background-color: ${palette.GRAY30};
  height: 1px;
  width: 100%;
`;

export default FormulaScreeningResult;
